import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Slide, toast } from 'react-toastify';
import { addCartItem } from '../../actions/cartActions';
import NumberInput from '../Layouts/NumberInput';
import { useLocation } from 'react-router-dom';
import WeightInput from '../Layouts/WeightInput';

const Product = ({ products, category }) => {
    const [weight, setWeight] = useState({});
    const [quantity, setQuantity] = useState(1);
    const dispatch = useDispatch();
    const location = useLocation();
    const [weightvalue, setweightvalue] = useState(false);
    const [weighttoast, setWeightToast] = useState(false);
    const [correctWeight, setcorrectWeight] = useState(false)

    useEffect(() => {
        const initialWeights = products && products.reduce((acc, product) => {
            acc[product._id] = ''; // Set each product's weight as an empty string
            return acc;
        }, {});
        setWeight(initialWeights);
    }, [products]);

   

    const handleWeightChange = (
        productId,
        value,
        productCategory,
        productMeasurement,
        maximumQuantity
    ) => {
        let validValue;
        if (productMeasurement === "Kg") {
            // Allow leading zero, optional decimal, and up to two decimal places
            validValue = value.match(/^(0|[1-9]\d*)?(\.\d{0,2})?$/) ? value : weight[productId];
        } else {
            // Only whole numbers allowed
            validValue = value.match(/^\d*$/) ? value : weight[productId];
        }

        // Allow empty value for resetting
        if (value === "") {
            setWeight((prevWeights) => ({ ...prevWeights, [productId]: "" }));
            return;
        }

        const weightValue = parseFloat(validValue);

        // Prevent invalid values
        if (isNaN(weightValue) || weightValue < 0) {
            return;
        }

        if (productMeasurement) {
            if (weightValue > maximumQuantity) {
                setweightvalue(true);
                toast.dismiss();
                if (!weightvalue) {
                    setTimeout(() => {
                        toast.error(`Cannot exceed ${maximumQuantity} ${productMeasurement}`, {
                            position: 'top-right',
                            type: 'error',
                            autoClose: 700,
                            transition: Slide,
                            hideProgressBar: true,
                            className: 'small-toast-input',
                        });
                        setweightvalue(false);
                    }, 300);
                }

                setWeight((prevWeights) => ({ ...prevWeights, [productId]: "" }));
                return;
            }
        }

        setWeight((prevWeights) => ({ ...prevWeights, [productId]: validValue }));
    };

    
    const calculateRate = (price, weight) => {
        return (price * weight).toFixed(2);
    };

    

    const handleAddToCart = (product, productId) => {
        const productWeight = weight[product._id];

        if (product && product.measurement) {
            // For Keerai, bundle validation
            if (!isNaN(productWeight) && productWeight <= 0) {
                setWeightToast(true);
                setWeight(prevWeights => ({ ...prevWeights, [productId]: '' }));
                if (!weighttoast) {
                    toast.dismiss();
                    setTimeout(() => {
                        if (product.measurement) {
                            toast.error(product.measurement === 'Kg' ? `Please select atleast 0.25 ${product.measurement}` : `Please select atleast 1 ${product.measurement}`, {
                                position: 'bottom-center',
                                type: 'error',
                                autoClose: 700,
                                transition: Slide,
                                hideProgressBar: true,
                                className: 'small-toast',
                            });
                        }
                        setWeightToast(false);
                    }, 300);
                }
                return;
            }

            // Adding items to cart except kg
            if (product.measurement !== 'kg' && productWeight >= 1 && productWeight <= product.maximumQuantity) {
                dispatch(addCartItem({ productId: product._id, quantity, productWeight }));
                toast.dismiss();
                setTimeout(() => {
                    toast.success('Item added to cart successfully!', {
                        position: 'bottom-center',
                        type: 'success',
                        autoClose: 700,
                        transition: Slide,
                        hideProgressBar: true,
                        className: 'small-toast',
                    });
                }, 300);
                setQuantity(1);
                setWeight(prevWeights => ({ ...prevWeights, [product._id]: '' }));
                return;
            }
           
            
        }

        // For non-Keerai products
        

        if (product.measurement === 'Kg' && productWeight >= 0.25) {
            dispatch(addCartItem({ productId: product._id, quantity, productWeight }));
            toast.dismiss();
            setTimeout(() => {
                toast.success('Item added to cart successfully!', {
                    position: 'bottom-center',
                    type: 'success',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                });
            }, 300);
            setQuantity(1);
            setWeight(prevWeights => ({ ...prevWeights, [product._id]: '' }));
            return;
        } else {
            setcorrectWeight(true);
            if (!correctWeight) {
                toast.dismiss();
                setTimeout(() => {
                    toast.error('Weight should be atleast 0.25kg!', {
                        position: 'bottom-center',
                        type: 'error',
                        autoClose: 700,
                        transition: Slide,
                        hideProgressBar: true,
                        className: 'small-toast',
                    });
                    setcorrectWeight(false);
                }, 300);
            }
            return;
        }
    };

    const capitalizeFirstLetter = (str) => {
        return str
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    return (
        <div className="container mt-2  table-product">
            <div className="table-responsive">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Products Image</th>
                            <th>Products Name</th>
                            <th>Price</th>
                            <th>Quantity</th>
                            <th>Rate (As Per Weight)</th>
                            <th>Stock</th>
                            <th>Add to Cart</th>
                        </tr>
                    </thead>
                    <tbody>
                        {products && products.map((product, index) => (
                            <tr key={product._id}>
                                <td className="serial-number">{index + 1}</td>
                                <td className="products-image" >
                                    {product.images[0] && product.images[0].image && (
                                        <img
                                            className="img-size"
                                            src={product.images[0].image}
                                            alt={capitalizeFirstLetter(product.englishName)}
                                        />
                                    )}
                                </td>
                               
                                <td className="Products Name" style={{ fontSize: '17px' }}>
                                    {product && product.range
                                        ? `${capitalizeFirstLetter(product.englishName)}/${capitalizeFirstLetter(product.tamilName)} (${product.range})`
                                        : `${capitalizeFirstLetter(product.englishName)}/${capitalizeFirstLetter(product.tamilName)}`
                                    }
                                </td>
                                <td className="Price" style={{ whiteSpace: 'nowrap' }}>
                                    Rs.{product.price}(per{" "} {product && product.measurement}
                                   
                                    )
                                </td>
                               
                                <WeightInput
                                    product={product}
                                    weight={weight}
                                    handleWeightChange={handleWeightChange}
                                />
                                <td className="Rate (As Per Weight)">
                                    {weight[product._id] ? `Rs.${calculateRate(product.price, weight[product._id])}` : 'Rs.0.00'}
                                </td>
                                <td className="Stock"
                                 style={{
                                    color: product.stocks === 'Stock' ? 'green' : 'red'}}
                                >{product.stocks}</td>
                                <td className="Add to Cart">
                                  
                                    <button
                                        className={product.stocks === 'Stock' ? "btn-add" : "btn-add-no"}
                                        onClick={() => handleAddToCart(product)}
                                        style={{
                                            backgroundColor: product.stocks === 'Stock' ? "#02441E" : "",
                                            color: "white",
                                            borderRadius: "40px",
                                            cursor: product.stocks === 'Stock' ? 'pointer' : 'not-allowed',
                                        }}
                                        disabled={product.stocks === 'No Stock'}
                                    >
                                        Add
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Product;
