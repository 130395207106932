import React, { useEffect, useState, Fragment } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import Sidebar from "../admin/Sidebar";
import MetaData from "../Layouts/MetaData";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch, useSelector } from "react-redux";
import { getProductsByDate } from "../../actions/marginAction";
import Loader from "../Layouts/Loader";
import { clearProductReport } from "../../slices/marginReportSlice";
import { Slide, toast } from 'react-toastify';

const ProductDetailPage = ({ isActive, setIsActive }) => {
    const { date: initialDate } = useParams(); // id used to get the selected date
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { reportByDate, productloading, producterror } = useSelector((state) => state.marginReportState);
    const [selectedDate, setSelectedDate] = useState(initialDate || "");
    const [selectedCategory, setSelectedCategory] = useState("All");
    const [categories, setCategories] = useState([]);
    const location = useLocation();
    const { report = null } = location.state || {};


    useEffect(() => {
        if (!report) {
            dispatch(getProductsByDate({ date: selectedDate }));

        }
    }, [dispatch, selectedDate]);

    useEffect(() => {
        if (selectedDate) {
            dispatch(getProductsByDate({ date: selectedDate }));  // Send selectedDate as param
        }
    }, [selectedDate, dispatch]);

    // Update categories dynamically based on reportByDate
    useEffect(() => {
        if (reportByDate?.length) {
            const uniqueCategories = Array.from(
                new Set(reportByDate.map((item) => item.category))
            );
            setCategories(uniqueCategories);
        }
    }, [reportByDate]);

    const handleDateChange = (e) => {
        const newDate = e.target.value;
        setSelectedDate(newDate); // Update selectedDate state when user changes date
        navigate(`/admin/productDetail/${newDate}`, { replace: true }); // Update the URL without navigating to a different route
    };

    useEffect(() => {
        if (producterror) {
            toast.dismiss();
            setTimeout(() => {
                toast.error(producterror, {
                    position: 'bottom-center',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onOpen: () => { dispatch(clearProductReport()) }
                });
            }, 300);
            return
        }


    }, [dispatch, producterror])

    const setOrders = () => {
        const data = {
            columns: [
                { label: "S.No", field: "s_no", sort: "disabled" },
                { label: "Category", field: "category", sort: "disabled" },
                { label: "Product", field: "product", sort: "disabled" },
                { label: "Original Price", field: "originalPrice", sort: "disabled" },
                { label: "Selling Price", field: "sellingPrice", sort: "disabled" },
                { label: "Offer", field: "offer", sort: "disabled" },
                { label: "Total Order", field: "totalOrder", sort: "disabled" },
                { label: "Dispatched Quantity", field: "totalDispatched", sort: "disabled" },
                { label: "Purchase Cost", field: "spentAmount", sort: "disabled" },
                { label: "Sales Revenue", field: "amountGot", sort: "disabled" },
                { label: "Total Profit", field: "totalProfit", sort: "disabled" },
                { label: "Total Loss", field: "totalLoss", sort: "disabled" },
                { label: "Total Profit/Loss", field: "totalProfitLoss", sort: "disabled" },

            ],
            rows: [],
        };

        // Filter by category if selected
        // const filteredData =
        //     selectedCategory === "All"
        //         ? reportByDate
        //         : reportByDate.filter((item) => item.category === selectedCategory);

        const filteredData = reportByDate?.filter((item) => {
            // Filter based on category and only those with totalOrderDocCount > 0
            const categoryMatch = selectedCategory === "All" || item.category === selectedCategory;
            return categoryMatch && item.totalOrderDocCount > 0;
        });

        // Sort by category first and then by product name
        filteredData?.sort((a, b) => {
            // Compare categories
            const categoryComparison = a.category.localeCompare(b.category);
            if (categoryComparison !== 0) {
                return categoryComparison; // If categories are different, sort by category
            }
            // If categories are the same, sort by product name
            return a.product.localeCompare(b.product);
        });

        filteredData?.forEach((item, index) => {
            const totalProfitStyle = item.totalProfit > 0 ? { color: "green" } : item.totalProfit < 0 ? { color: "red" } : { color: "black" };
            const totalLossStyle = item.totalLoss < 0 ? { color: "red" } : item.totalLoss === 0 ? { color: "black" } : { color: "black" };
            const totalProfitLossStyle = item.totalProfitAndLoss > 0 ? { color: "green" } : item.totalProfitAndLoss < 0 ? { color: "red" } : { color: "black" };
            data.rows.push({
                s_no: index + 1,
                category: item.category,
                product: item.product,
                originalPrice: item.originalPrice,
                sellingPrice: item.sellingPrice,
                offer: item.offer,
                totalOrder: item.totalOrderDocCount,
                totalDispatched: item.totalKgDispatched,
                spentAmount: item.totalAmountSpent.toFixed(2),
                amountGot: item.totalAmountgot.toFixed(2),
                // totalProfit: item.totalProfit.toFixed(2),
                // totalLoss: item.totalLoss.toFixed(2),
                // totalProfitLoss: item.totalProfitAndLoss.toFixed(2),
                totalProfit: (
                    <span style={totalProfitStyle}>{item.totalProfit.toFixed(2)}</span>
                ),
                totalLoss: (
                    <span style={totalLossStyle}>{item.totalLoss.toFixed(2)}</span>
                ),
                totalProfitLoss: (
                    <span style={totalProfitLossStyle}>{item.totalProfitAndLoss.toFixed(2)}</span>
                ),
            });
        });

        return data;
    };

    return (
        <div>
            <MetaData title="Order Summary" />
            <div className="row loader-parent">
                <div className="col-12 col-md-2">
                    <Sidebar isActive={isActive} setIsActive={setIsActive} />
                </div>

                <div className="col-12 col-md-10 smalldevice-space-summary loader-parent mt-0">
                    <h1 className="mb-4 admin-dashboard-x">Margin Report</h1>
                    <div className="button-container mb-2">
                        <span
                            className="order-summary-back-button"
                            onClick={() => navigate(-1)}
                        >
                            <ArrowBackIcon fontSize="small" />
                            <span>Back</span>
                        </span>
                    </div>

                    <div className="paymentlist-container">
                        <div className="paymentlist-date-filter">
                            <div className="start-date">
                                <label>
                                    Selected Date:
                                </label>
                                <input
                                    type="date"
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    className="paymentlist-form-control"
                                />

                            </div>
                            <div className="filter-status" style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                <label htmlFor="category">
                                    Category:
                                </label>
                                <select
                                    id="category"
                                    value={selectedCategory}
                                    onChange={(e) => setSelectedCategory(e.target.value)}
                                    className="paymentlist-form-control"
                                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                >
                                    <option value="All">All</option>
                                    {categories.map((category, index) => (
                                        <option key={index} value={category}>
                                            {category}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>

                    <Fragment>
                        {productloading ? (<div className="container loader-loading-center">
                            <Loader />
                        </div>) :
                            <div className='mdb-table' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <MDBDataTable
                                    data={setOrders()}
                                    bordered
                                    hover
                                    className="px-3 product-table"
                                    noBottomColumns
                                />
                            </div>
                        }
                    </Fragment>
                </div>
            </div>
        </div>
    );
};

export default ProductDetailPage;