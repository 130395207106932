import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import Loader from '../Layouts/Loader';
import { useEffect } from 'react';
import { Slide, toast } from 'react-toastify';
import { clearUser, clearlogout } from '../../slices/authSlice';

export default function ProtectedRoute({ children, isAdmin,isSubAdmin }) {
    const { user, isAuthenticated,loggedoutmessage,isloggedout,loading } = useSelector(state => state.authState);
  
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (isloggedout && !isAuthenticated && !user && isAdmin && isSubAdmin) {
            sessionStorage.clear();
            toast.dismiss();
    
            setTimeout(() => {
                toast.success(loggedoutmessage, {
                    position: 'bottom-center',
                    type: 'success',
                    autoClose: 300,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onClose: () => {   dispatch(clearlogout());   
                        dispatch(clearUser());},
            
                });
                setTimeout(() => {
                    window.location.replace('/'); // Fallback to replace current page
                }, 200);
    
            }, 10);
        }
    }, [isloggedout, dispatch, isAuthenticated, user, loggedoutmessage]);
    

    if (loading) {
        return <Loader />;
    }
    if((isAdmin || isSubAdmin)  && !isAuthenticated || (isAdmin || isSubAdmin) && !user ){
        return <Navigate to="/unauthorized" replace />;
    }


    if (isAdmin && user && user.role === 'admin' && isAuthenticated) {
        if (user && user.role === 'admin') {
            return children;
            
        } 
        else if (user && user.role !== 'admin'){
            return <Navigate to="/unauthorized" replace />;
        }
        else{
             return <Navigate to="/unauthorized" replace />;
        }
    }

    else if (isSubAdmin && user && user.role === 'subadmin' && isAuthenticated) {
        
        if (user && user.role === 'subadmin') {
            return children;
        } 
        else if (user && user.role !== 'subadmin'){
            return <Navigate to="/unauthorized" replace />;
        }
        else{
             return <Navigate to="/unauthorized" replace />;
        }
    }


    else if ( !isAdmin && !isSubAdmin && isAuthenticated) {

        if (user && user.role === 'user' || user && user.role === 'admin' || user && user.role === 'subadmin' ) {
            return children;
        } 
        else if (user && user.role !== 'user' || 'admin' || 'subadmin'){
            return <Navigate to="/unauthorized" replace />;
        }
        else{
            return <Navigate to="/unauthorized" replace />;
        }
    }
    
    else if(!isAuthenticated || !user ){
        return <Navigate to="/unauthorized" replace />;
    }

    else{
        return <Navigate to="/login" />
    }

}