
import React, { useEffect, Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { adminOrders as adminOrdersAction } from "../../actions/orderActions";
import Loader from '../Layouts/Loader';
import { MDBDataTable } from 'mdbreact';
import { Slide, toast } from 'react-toastify';
import Sidebar from "../admin/Sidebar";
import { clearError } from '../../slices/productsSlice';
import { clearOrderDeleted, orderDetailClear } from "../../slices/orderSlice";
import MetaData from '../Layouts/MetaData';
import { porterClearData, porterClearResponse } from '../../slices/porterSlice';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const OrderList = ({ isActive, setIsActive }) => {
    const location = useLocation();
    const { adminOrders: orders, loading = true, error, isOrderDeleted } = useSelector(state => state.orderState);
    const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
    const [iserror, setIserror] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();


    useEffect(() => {
        dispatch(orderDetailClear());
        dispatch(porterClearData());
        dispatch(porterClearResponse());
    }, [])


    const setOrders = () => {
        const data = {
            columns: [
                {
                    label: 'S.No',
                    field: 's_no',
                    sort: 'disabled'
                },
                {
                    label: 'Order ID',
                    field: 'id',
                    sort: 'disabled'
                },
                {
                    label: 'Name',
                    field: 'name',
                    sort: 'disabled'
                },
                {
                    label: 'Phone.No',
                    field: 'phone_no',
                    sort: 'disabled'
                },
                // {
                //     label: 'Email',
                //     field: 'email',
                //     sort: 'disabled'
                // },
                {
                    label: 'Amount',
                    field: 'amount',
                    sort: 'disabled'
                },
                {
                    label: 'OrderStatus',
                    field: 'orderstatus',
                    sort: 'disabled'
                },
                {
                    label: 'PaymentStatus',
                    field: 'paymentstatus',
                    sort: 'disabled'
                },
                {
                    label: 'Actions',
                    field: 'actions',
                    sort: 'disabled'
                }
            ],
            rows: []
        };

        // Filter orders by selected date and specific conditions
        const filteredOrders = orders && orders.filter(order => {
            if (!order.orderDate) return false;

            // Parse order date and match it with the selected date
            const orderDate = new Date(order.orderDate).toISOString().split('T')[0];
            const matchesDate = orderDate === date;
            const matchesStatus = order.paymentStatus === 'CHARGED' && (order.orderStatus === 'Processing' || order.orderStatus === 'Packed');

            return matchesDate && matchesStatus;
        });


        // Map the filtered orders to table rows
        filteredOrders && filteredOrders.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).forEach((order, index) => {
            data.rows.push({
                s_no: index + 1,
                id: order.order_id,
                name: order.shippingInfo.name,
                phone_no: order.shippingInfo.phoneNo,
                // email: order.user.email,
                amount: `Rs.${order.totalPrice}`,
                orderstatus: (
                    <div className={order.orderStatus.includes('Delivered') ? 'greenColor' : 'redColor'}>
                        {order.orderStatus}
                    </div>
                ),
                paymentstatus: (
                    <div style={{ color: order && order.paymentStatus === 'CHARGED' ? 'green' : 'red' }}>
                        {order && order.paymentStatus === 'CHARGED' ? 'Success' : order ? order.paymentStatus : 'Pending'}
                    </div>
                ),
                actions: (
                    <Fragment>
                        <Link to={`/admin/order/${order.order_id}`} className="btn btn-success py-1 px-2 ml-2">
                            {/* <i className="fa fa-pencil"></i> */}
                            Going to Pack
                        </Link>
                    </Fragment>
                )
            });
        });
        // }

        return data;
    };

    useEffect(() => {
        if (error) {
            toast.dismiss();
            setTimeout(() => {
                toast.error(error, {
                    position: 'bottom-center',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onOpen: () => { dispatch(clearError()) }
                });
            }, 300);
            setIserror(true)
        }

        if (isOrderDeleted) {
            toast.dismiss();
            setTimeout(() => {
                toast.success('Order Deleted Successfully!', {
                    position: 'bottom-center',
                    type: 'success',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onOpen: () => dispatch(clearOrderDeleted())
                });
            }, 300);
        }
    }, [dispatch, error, isOrderDeleted]);


    useEffect(() => {
        if (!orders && !iserror) {
            dispatch(adminOrdersAction());
        }
    }, [orders, iserror])

    return (
        <div>
            <MetaData
                title="Order List"
                description="Access a comprehensive list of all customer orders. View, filter, and manage orders to ensure smooth order fulfillment."
            />


            <div className="row loader-parent">
                <div className="col-12 col-md-2">
                    <div style={{ display: 'flex', flexDirection: 'row', position: 'fixed', top: '0px', zIndex: 99999, backgroundColor: '#fff', minWidth: '100%' }}>
                        <Sidebar isActive={isActive} setIsActive={setIsActive} />
                    </div>
                </div>
                <div className="col-12 col-md-10 smalldevice-space loader-parent">
                    <h1 className=" mb-4 admin-dashboard-x">Order List</h1>
                    <div className="">
                            <span className="back-button" onClick={() => navigate(-1)}>
                                <ArrowBackIcon fontSize="small" />
                                <span>Back</span>
                            </span>

                        </div>
                    <input
                        type="date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        className="form-control mb-3 date-input"
                    />

                    <Fragment>
                        {loading ? (<div className="container loader-loading-center">
                            <Loader />
                        </div>) :
                            <div className='mdb-table' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <MDBDataTable
                                    data={setOrders()}
                                    bordered
                                    hover
                                    className="px-3 product-table"
                                    noBottomColumns
                                />
                            </div>
                        }
                    </Fragment>
                </div>
            </div>
        </div>

    );
};

export default OrderList;

