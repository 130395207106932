import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { orderCompleted } from "../../slices/cartSlice";
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import MetaData from '../Layouts/MetaData';
import CryptoJS from 'crypto-js';
import { createOrder, orderDetail as orderDetailAction, userOrders as userOrdersAction } from '../../actions/orderActions';
import { Slide, toast } from 'react-toastify';
import { clearError, orderDetailClear } from '../../slices/orderSlice';
import { removeAllFromCart } from '../../actions/cartActions';
import store from '../../store';
import { loadUser } from '../../actions/userActions';


const PaymentConfirm = () => {

  const { id } = useParams();
  const location = useLocation();
  const [paymentStatus, setPaymentStatus] = useState('LOADING');
  const [paymentDetails, setPaymentDetails] = useState({});
  const [paymentResponses, setPaymentResponses] = useState({});
  const [orderDateString, setOrderDateString] = useState(null);
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const dispatch = useDispatch();
  const { isAuthenticated, loading, user } = useSelector(state => state.authState);
  const { userOrders, orderDetail, error } = useSelector(state => state.orderState);
  const [Amount,setAmount]=useState(0);

  const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;

  // Function to decrypt the data
  const decryptData = (id, encryptionKey) => {
    const bytes = CryptoJS.AES.decrypt(id, encryptionKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  useEffect(() => {
    if (id && encryptionKey) {
      const decryptedOrderId = decryptData(id, encryptionKey);
      setOrderId(decryptedOrderId); // Consider using a state for `orderId`.
    }
  }, [id, encryptionKey]);

  useEffect(() => {
    if (error) {
      toast.dismiss();
      setTimeout(() => {
        toast.error(error, {
          position: 'bottom-center',
          type: 'error',
          autoClose: 700,
          transition: Slide,
          hideProgressBar: true,
          className: 'small-toast',
          onOpen: () => { dispatch(clearError()) }
        });
      }, 300);
      return;
    }
  }, [error])

  useEffect(() => {
    if (orderId) {
      dispatch(userOrdersAction());
      dispatch(orderDetailAction(orderId));
    }

  }, [orderId, id]);

  useEffect(() => {
    async function fetchData() {
      try {
        const url = `/api/v1/handleJuspayResponse/${encodeURIComponent(id)}`;
        const { data } = await axios.get(url, { withCredentials: true });
        if (data && data?.statusResponse?.statusResponse) {
          setAmount(data?.statusResponse?.totalPrice);
          setPaymentStatus(data.statusResponse?.statusResponse?.status);
          setPaymentDetails(data.statusResponse?.statusResponse);
        
        } 
        else if (data && data.statusResponse) {
          setAmount(data?.statusResponse?.totalPrice);
          setPaymentStatus(data?.statusResponse?.paymentStatus);
          setPaymentResponses(data?.statusResponse);
        } else {
          setPaymentStatus('UNKNOWN');
        }
      } catch (error) {
        setPaymentStatus('ERROR');
      }
    }
    fetchData();
    // dispatch(orderCompleted())
  }, [id]);


  useEffect(() => {
    if (orderDetail) {
      setDeliveryDate(orderDetail?.orderDate)
    }
  }, [orderDetail, paymentResponses])

  useEffect(() => {
    if (deliveryDate) {
      const orderDate = new Date(deliveryDate).toISOString().split('T')[0];
      setOrderDateString(orderDate);
    }

  }, [deliveryDate])


  const renderPaymentDetails = () => {
    // const { amount, payment_method, order_id, txn_id, date_created } = paymentResponses ? paymentResponses : paymentDetails;
    const {
      // amount = paymentResponses?.totalPrice || Amount && Amount,
      payment_method = paymentResponses?.payment_method || paymentDetails?.payment_method,
      order_id = paymentResponses?.order_id || paymentDetails?.order_id,
      txn_id = paymentResponses?.wallet_txn_id || paymentDetails?.txn_id,
      date_created = paymentResponses?.createdAt || paymentDetails?.date_created,
    } = paymentResponses || paymentDetails;

   
    return (
      <div style={{ marginTop: '80px' }}>
        <MetaData
          title="Payment Confirmation"
          description="Your payment has been successfully processed. Review your payment confirmation details and proceed with your order tracking."
        />

        <div className="payment-details">
          <h1 >{getTitle(paymentStatus)}</h1>
          <img src={getIcon(paymentStatus)} alt={paymentStatus} />
          <table>
            <tbody>
              <tr>
                <td>Amount</td>
                <td>₹ {parseFloat(Amount).toFixed(2)}</td>
              </tr>
              <tr>
                <td>Payment method</td>
                <td>{(payment_method ? payment_method : 'N/A')}</td>
              </tr>
              <tr>
                <td>Status</td>
                <td>{getStatusText(paymentStatus)}</td>
              </tr>
              <tr>
                <td>order Id</td>
                <td>{order_id}</td>
              </tr>
              <tr>
                <td>Transaction Id</td>
                <td>{(txn_id ? txn_id : 'N/A')}</td>
              </tr>
              <tr>
                <td>Order Date</td>
                {/* <td>
                  {(() => {
                    const date = new Date(date_created);
                    if (isNaN(date)) return 'Invalid date';

                    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
                    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

                    // Extract UTC values
                    const dayName = days[date.getUTCDay()];
                    const day = String(date.getUTCDate()).padStart(2, '0');
                    const month = months[date.getUTCMonth()];
                    const year = date.getUTCFullYear();

                    return `${dayName}, ${day}/${month}/${year}`;
                  })()}
                </td> */}
                <td>
                  {(() => {
                    const date = new Date(date_created);

                    if (isNaN(date)) return 'Invalid date';

                    // Convert to Indian Standard Time
                    const options = {
                      weekday: 'short', // e.g., "Mon"
                      day: '2-digit',  // e.g., "06"
                      month: 'short',  // e.g., "Jan"
                      year: 'numeric', // e.g., "2025"
                      timeZone: 'Asia/Kolkata',
                    };

                    return new Intl.DateTimeFormat('en-IN', options).format(date);
                  })()}
                </td>


              </tr>
              <tr>
                <td>Delivery Date</td>
                <tr>
                  {/* <td>Delivery Date</td> */}
                  {/* <td>
                    {(() => {
                      const date = new Date(deliveryDate);
                      if (isNaN(date)) return 'Invalid date';

                      const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
                      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

                      // Extract UTC values
                      const dayName = days[date.getUTCDay()];
                      const day = String(date.getUTCDate()).padStart(2, '0');
                      const month = months[date.getUTCMonth()];
                      const year = date.getUTCFullYear();

                      return `${dayName}, ${day}/${month}/${year}`;
                    })()}
                  </td> */}
                  <td>
                    {orderDateString ?
                      (() => {
                        const date = new Date(orderDateString);
                        if (isNaN(date)) return 'Invalid date';

                        // Convert to Indian Standard Time
                        const options = {
                          weekday: 'short', // e.g., "Mon"
                          day: '2-digit',  // e.g., "06"
                          month: 'short',  // e.g., "Jan"
                          year: 'numeric', // e.g., "2025"
                          timeZone: 'Asia/Kolkata',
                        };

                        return new Intl.DateTimeFormat('en-IN', options).format(date);
                      })() : (<>-</>)
                    }
                  </td>


                </tr>


              </tr>
            </tbody>
          </table>
          <div className="link-container">
            <Link to="/orders" className="orders-link">Go to Orders</Link>
          </div>
        </div>
      </div>
    );
  };

  const getTitle = (status) => {
    switch (status) {
      case 'CHARGED':
        return 'Transaction Successful!';
      case 'PENDING':
        return 'Transaction Pending';
      case 'PENDING_VBV':
        return 'Transaction Pending';
      case 'AUTHORIZATION_FAILED':
        return 'Transaction Failed';
      case 'AUTHENTICATION_FAILED':
        return 'Transaction Failed';
        case 'FAILED':
        return 'Transaction Failed';
      case 'NEW':
        return 'Transaction Cancelled';
      case 'AUTHORIZING':
        return 'Transaction Pending';
      default:
        return 'Transaction Status';
    }
  };

  const getIcon = (status) => {
    switch (status) {
      case 'CHARGED':
        return 'https://img.icons8.com/color/48/000000/checkmark.png';
      case 'PENDING':
        return 'https://img.icons8.com/color/48/000000/hourglass-sand-bottom.png';
      case 'PENDING_VBV':
        return 'https://img.icons8.com/color/48/000000/hourglass-sand-bottom.png';
      case 'AUTHORIZATION_FAILED':
        return 'https://img.icons8.com/color/48/000000/cancel.png';
      case 'AUTHENTICATION_FAILED':
        return 'https://img.icons8.com/color/48/000000/cancel.png';
        case 'FAILED':
          return 'https://img.icons8.com/color/48/000000/cancel.png';
      case 'NEW':
        return 'https://img.icons8.com/color/48/000000/cancel.png';
      case 'AUTHORIZING':
        return 'https://img.icons8.com/color/48/000000/hourglass-sand-bottom.png';
      default:
        return '❌';
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case 'CHARGED':
        return '✔️ Successful';
      case 'PENDING':
        return '⌛ Pending';
      case 'PENDING_VBV':
        return '⌛ Pending';
      case 'AUTHORIZATION_FAILED':
        return '❌ Failed';
      case 'AUTHENTICATION_FAILED':
        return '❌ Failed';
      case 'FAILED':
        return '❌ Failed';
      case 'NEW':
        return '❌ Cancelled';
      case 'AUTHORIZING':
        return '⌛ AUTHORIZING';
      default:
        return '❓ Unknown';
    }
  };

  return (
    <div className="payment-confirm">

      {paymentStatus === 'LOADING' && <p style={{ marginTop: '120px' }}>Loading...</p>}
      {paymentStatus !== 'LOADING' && renderPaymentDetails()}



    </div>
  );
};

export default PaymentConfirm;

