import React, { useEffect, Fragment, useState } from 'react';
import { Button, Container, Navbar } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loader from '../Layouts/Loader';
import { MDBDataTable } from 'mdbreact';
import { Slide, toast } from 'react-toastify';
import Sidebar from "../admin/Sidebar";
import MetaData from '../Layouts/MetaData';
import LoaderButton from '../Layouts/LoaderButton';
import { clearDeleteAnnouncement } from '../../slices/announcementSlice';
import { deleteAnnouncements, getAnnouncements } from '../../actions/announcementAction';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { deleteDiscounts, getDiscounts } from '../../actions/discountAction';
import { clearDiscountsDelete, clearGetdiscounts } from '../../slices/discountSlices';

const DiscountList = ({ isActive, setIsActive }) => {
    const { getloading, discounts, discountsError, deleteloading, discountsDeleteSuccess, discountsDeleteError } = useSelector(state => state.discountState);
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [productToDelete, setProductToDelete] = useState(null);
    const [modalOffer, setModalOffer] = useState(false);
    const [listViewing, setListViewing] = useState(null);
    const { products } = useSelector((state) => state.productsState);

    const navigate = useNavigate();

    const setProducts = () => {
        const data = {
            columns: [
                {
                    label: 'S.No',
                    field: 's_no',
                    sort: 'disabled',
                    attributes: {
                        'className': 'sno-column'
                    }
                },
                {
                    label: 'Date',
                    field: 'date',
                    sort: 'disabled',
                    attributes: {
                        'className': 'date-column'
                    }
                },
                {
                    label: 'Title',
                    field: 'title',
                    sort: 'disabled',
                    attributes: {
                        'className': 'date-column'
                    }
                },
                // {
                //     label: 'Products',
                //     field: 'products',
                //     sort: 'disabled',
                //     attributes: {
                //         'className': 'date-column'
                //     }
                // },
                {
                    label: 'Actions',
                    field: 'actions',
                    sort: 'disabled',
                    attributes: {
                        'className': 'actions-column'
                    }
                }
            ],
            rows: []
        }
        discounts && discounts.forEach((product, index) => {
            data.rows.push({
                s_no: index + 1,
                date : new Date(product.createdAt).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' }),
                title: product.title,
                // products: (
                //     <Fragment>
                //         <Link onClick={() => handleViewProducts(product)} className="">
                //             View
                //         </Link>

                //     </Fragment>
                // ),
                actions: (
                    <Fragment>
                        <Link to={`/admin/update-discount/${product._id}`} state={{ product }} className="btn btn-primary py-1 px-2 ml-2">
                            <i className="fa fa-pencil"></i>
                        </Link>
                        <Button onClick={() => handleDeleteClick(product._id)} className="btn btn-danger py-1 px-2 ml-2">
                            <i className="fa fa-trash"></i>
                        </Button>
                    </Fragment>
                )
            })
        })

        return data;
    }

    useEffect(() => {
        if (discountsDeleteError) {
            toast.dismiss();
            setTimeout(() => {
                toast.error(discountsDeleteError, {
                    position: 'bottom-center',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onOpen: () => { dispatch(clearDiscountsDelete()) }
                });
            }, 300);
            return
        }
        if (discountsDeleteSuccess) {
            toast.dismiss();
            setTimeout(() => {
                toast.success(discountsDeleteSuccess?.message, {
                    position: 'bottom-center',
                    type: 'success',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onOpen: () => {
                        dispatch(clearDiscountsDelete());
                        dispatch(clearGetdiscounts());
                        dispatch(getDiscounts());
                    }
                });

            }, 300);
            return;
        }
    }, [dispatch, discountsDeleteSuccess, discountsDeleteError])

    useEffect(() => {
        if (discountsError) {
            toast.dismiss();
            setTimeout(() => {
                toast.error(discountsError, {
                    position: 'bottom-center',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onOpen: () => { dispatch(clearGetdiscounts()) }
                });
            }, 300);
            return
        }

    }, [discountsError])

    useEffect(() => {
        if (!discounts) {
            dispatch(getDiscounts());
        }
    }, [discounts])

    const handleDeleteClick = (id) => {
        setProductToDelete(id);
        setShowModal(true);
    };

    const handleConfirmDelete = () => {
        dispatch(deleteDiscounts({ id: productToDelete }));
        setShowModal(false);
    };

    const handleCancelDelete = () => {
        setShowModal(false);
    };

    const handleViewProducts = (product) => {
        setListViewing(product);
        setModalOffer(true);
    }

    const handleCancelOfferModal = () =>{
        setListViewing(null);
        setModalOffer(false);
    }

    return (
        <div>
            <MetaData
                title="Category List"
                description="Manage your category inventory. Create, View, update, or remove Category from your catalog to keep your store fresh and relevant."
            />


            <div className="row loader-parent">
                <div className="col-12 col-md-2">
                    <div style={{ display: 'flex', flexDirection: 'row', position: 'fixed', top: '0px', zIndex: 99999, backgroundColor: '#fff', minWidth: '100%' }}>
                        <Sidebar isActive={isActive} setIsActive={setIsActive} />
                    </div>
                </div>
                <div className="col-12 col-md-10 smalldevice-space loader-parent">
                    <h1 className="mb-4 admin-dashboard-x">Offers List</h1>
                    <div className="back-button" onClick={() => navigate(-1)}>
                        <ArrowBackIcon fontSize="small" />
                        <span>Back</span>
                    </div>
                    <Fragment>
                        {getloading ? (<div className="container loader-loading-center">
                            <Loader />
                        </div>) :
                            (
                                <>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px', marginBottom: '3px' }}>
                                        <Button variant="success" className="add-category-btn" onClick={() => navigate("/admin/add-discount")}>
                                            <i className="fa fa-plus"></i> Add Offer
                                        </Button>
                                    </div>
                                    <div className='mdb-table ' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <MDBDataTable
                                            data={setProducts()}
                                            bordered
                                            noBottomColumns
                                            hover
                                            className="px-3 product-table"
                                        />
                                    </div>
                                </>
                            )

                        }
                    </Fragment>

                </div>

                {showModal && (
                    <div className="modal" tabIndex="-1" role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Confirm Delete</h5>
                                    <button type="button" className="close" onClick={handleCancelDelete}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p>Are you sure you want to delete this discount?</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" onClick={handleConfirmDelete} disabled={deleteloading}>
                                        {deleteloading ? <LoaderButton fullPage={false} size={20} /> : (
                                            <span>OK</span>
                                        )

                                        }

                                    </button>
                                    <button type="button" className="btn btn-secondary" onClick={handleCancelDelete}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {modalOffer && (
                    <div className="modal" tabIndex="-1" role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">{listViewing?.title}</h5>
                                    <button type="button" className="close" onClick={handleCancelOfferModal}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p>Are you sure you want to delete this discount?</p>
                                </div>
                            </div>
                        </div>
                    </div>

                )}
            </div>
        </div>
    )
}

export default DiscountList;