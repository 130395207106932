import React, { Fragment } from 'react';
import Sidebar from '../admin/Sidebar';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getAdminProducts, updateProduct } from '../../actions/productsActions';
import { clearProductUpdated, clearError, clearproduct } from '../../slices/productSlice';
import { Slide, toast } from 'react-toastify';
import { getProduct } from '../../actions/productAction';
import MetaData from '../Layouts/MetaData';
import LoaderButton from '../Layouts/LoaderButton';
import Loader from '../Layouts/Loader';
import { getCategories } from '../../actions/categoryAction';
import { getMeasurements } from '../../actions/measurementActions';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const UpdateProduct = ({ isActive, setIsActive }) => {

    const [formData, setFormData] = useState({
        englishName: "",
        tamilName: "",
        buyingPrice: "",
        price: "",
        category: "",
        measurement: "",
        range: "",
        maximumQuantity: "",
        percentage: "",
        stocks: "",
        images: [],
        imagesPreview: [],
        imagesCleared: false
    });

    const { id } = useParams();
    const { updateloading, loading, isProductUpdated, error, product } = useSelector((state) => state.productState);
    const { getcategory } = useSelector(state => state.categoryState)
    const { getmeasurement } = useSelector(state => state.measurementState)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    useEffect(() => {
        if (!getcategory) {
            dispatch(getCategories())

        }
        if (!getmeasurement) {
            dispatch(getMeasurements())
        }

    }, [getcategory, getmeasurement])

    useEffect(() => {
        if (product && product._id) {
            setFormData({
                ...formData,
                englishName: product.englishName,
                tamilName: product.tamilName,
                buyingPrice: product.buyingPrice,
                price: product.price,
                category: product.category,
                measurement: product.measurement,
                range: product.range,
                maximumQuantity: product.maximumQuantity,
                percentage: product.percentage,
                stocks: product.stocks,
                imagesPreview: product.images.map(image => image.image)
            });
        }
    }, [product]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleImagesChange = (e) => {
        const files = Array.from(e.target.files);
        const maxSize = 5 * 1024 * 1024; // 10 MB in bytes
        let totalSize = 0;

        // Calculate total size of all selected files
        files.forEach(file => {
            totalSize += file.size;
        });

        // Check if total size exceeds the maximum allowed size
        if (totalSize > maxSize) {
            toast.dismiss();
            setTimeout(() => {
                toast.error('The total size of all selected images exceeds the 5MB limit.', {
                    position: 'bottom-center',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                });
            }, 300);
            return; // Stop further execution if total size exceeds the limit
        }

        // Proceed with setting images if total size is within the limit
        files.forEach(file => {
            const reader = new FileReader();

            reader.onload = () => {
                if (reader.readyState === 2) {
                    setFormData(prevState => ({
                        ...prevState,
                        imagesPreview: [...prevState.imagesPreview, reader.result],
                        images: [...prevState.images, file]
                    }));
                }
            };

            reader.readAsDataURL(file);
        });
    };


    const clearImagesHandler = () => {
        setFormData({
            ...formData,
            images: [],
            imagesPreview: [],
            imagesCleared: true
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();
        formDataToSend.append('englishName', formData.englishName);
        formDataToSend.append('tamilName', formData.tamilName);
        formDataToSend.append('buyingPrice', formData.buyingPrice);
        formDataToSend.append('price', formData.price);
        formDataToSend.append('category', formData.category);
        formDataToSend.append('measurement', formData.measurement);
        formDataToSend.append('range', formData.range);
        formDataToSend.append('maximumQuantity', formData.maximumQuantity);
        formDataToSend.append('percentage', formData.percentage);
        formDataToSend.append('stocks', formData.stocks);
        formData.images.forEach(image => formDataToSend.append('images', image));
        formDataToSend.append('imagesCleared', formData.imagesCleared);
        dispatch(updateProduct({ id, formDataToSend }));
    };

    useEffect(() => {
        dispatch(clearproduct())
    }, [])

    useEffect(() => {
        if (product && product._id !== id) {
            dispatch(getProduct(id));
        }
    }, [dispatch, id, product]);

    useEffect(() => {
        if (!product) {
            dispatch(getProduct(id));
        }
    }, [dispatch, id]);


    useEffect(() => {
        if (formData.buyingPrice && formData.percentage) {
            const buyingPrice = parseFloat(formData.buyingPrice);
            const percentage = parseFloat(formData.percentage);
            if (!isNaN(buyingPrice) && !isNaN(percentage)) {
                const calculatedPrice = buyingPrice + (buyingPrice * (percentage / 100));
                setFormData(prevState => ({
                    ...prevState,
                    price: calculatedPrice.toFixed(2) // Set price with two decimal points
                }));
            }
        }
    }, [formData.buyingPrice, formData.percentage]);


    useEffect(() => {
        if (isProductUpdated) {
            toast.dismiss();
            setTimeout(() => {
                toast.success('Product updated successfully!', {
                    position: 'bottom-center',
                    type: 'success',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onOpen: () => dispatch(clearProductUpdated())
                });
            }, 300);
            dispatch(getAdminProducts())
            navigate('/admin/products');
        }

        if (error) {
            toast.dismiss();
            setTimeout(() => {
                toast.error(error, {
                    position: 'bottom-center',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onOpen: () => dispatch(clearError())
                });
            }, 300);
        }
    }, [dispatch, isProductUpdated, error, navigate]);

    return (
        <div>
            <MetaData
                title="Update Product"
                description="Edit product details, update stock levels, or change product images to keep your catalog accurate and up to date."
            />


            <div className="row loader-parent">
                <div className="col-12 col-md-2">
                    <div style={{ display: 'flex', flexDirection: 'row', position: 'fixed', top: '0px', zIndex: 99999, backgroundColor: '#fff', minWidth: '100%' }}>
                        <Sidebar isActive={isActive} setIsActive={setIsActive} />
                    </div>
                </div>
                <div className="col-12 col-md-10 smalldevice-space loader-parent">
                <div className="">
                            <span className="back-button" onClick={() => navigate(-1)}>
                                <ArrowBackIcon fontSize="small" />
                                <span>Back</span>
                            </span>

                        </div>
                    {
                        loading ? (
                            <div className="container loader-loading-center">
                                <Loader />
                            </div>

                        ) : (
                            <Fragment>

                                <div className="wrapper mt-0">
                                    <form onSubmit={handleSubmit} className="shadow-lg" encType='multipart/form-data'>
                                        <h3 className="mb-4 admin-dashboard-x">Update Product</h3>

                                        <div className="form-group">
                                            <label htmlFor="name">English Name <span style={{ color: 'red' }}>*</span></label>
                                            <input
                                                type="text"
                                                id="englishName"
                                                name="englishName"
                                                className="form-control"
                                                value={formData.englishName}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="name">Tamil Name <span style={{ color: 'red' }}>*</span></label>
                                            <input
                                                type="text"
                                                id="tamilName"
                                                name="tamilName"
                                                className="form-control"
                                                value={formData.tamilName}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="buyingPrice">Buying Price in (Rs) <span style={{ color: 'red' }}>*</span></label>
                                            <input
                                                type="text"
                                                id="buyingPrice"
                                                name="buyingPrice"
                                                className="form-control"
                                                value={formData.buyingPrice}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="percentage">Percentage (%) <span style={{ color: 'red' }}>*</span></label>
                                            <input
                                                type="text"
                                                id="percentage"
                                                name="percentage"
                                                className="form-control"
                                                value={formData.percentage}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="price"> Selling Price in (Rs)</label>
                                            <input
                                                type="text"
                                                id="price"
                                                name="price"
                                                className="form-control"
                                                value={formData.price}
                                                disabled
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="category">Category <span style={{ color: 'red' }}>*</span></label>
                                            <select
                                                id="category"
                                                name="category"
                                                className="form-control"
                                                value={formData.category}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value="">Select</option>
                                                {getcategory && getcategory.map(categoryItem => (
                                                    <option key={categoryItem} value={categoryItem.category}>{categoryItem.category}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="measurement">Measurement  <span style={{ color: 'red' }}>*</span></label>
                                            <select
                                                type="text"
                                                id="measurement"
                                                name="measurement"
                                                className="form-control"
                                                onChange={handleChange}
                                                value={formData.measurement}
                                                required
                                            >
                                                <option value="">Select</option>
                                                {getmeasurement?.map(measurementItem => (
                                                    <option key={measurementItem._id} value={measurementItem.measurement}>{measurementItem.measurement}</option>
                                                ))}
                                            </select>
                                        </div>
                                        {formData.measurement && (
                                            <div className="form-group">
                                                <label htmlFor="range">Range</label>
                                                <input
                                                    type="text"
                                                    id="range"
                                                    name="range"
                                                    className="form-control"
                                                    value={formData.range}
                                                    onChange={handleChange}
                                                    placeholder="Enter range"

                                                />
                                            </div>
                                        )}

                                        <div className="form-group">
                                            <label htmlFor="maxQuantity_field">Maximum Quantity <span style={{ color: 'red' }}>*</span></label>
                                            <input
                                                type="text"
                                                id="maxQuantity_field"
                                                name="maxQuantity_field"
                                                className="form-control"
                                                onChange={handleChange}
                                                value={formData.maximumQuantity}
                                                placeholder="Enter maximum quantity"
                                                required
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="stocks"> Stocks <span style={{ color: 'red' }}>*</span></label>
                                            <select
                                                type="text"
                                                id="stocks"
                                                name="stocks"
                                                className="form-control"
                                                value={formData.stocks}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value="">Select</option>
                                                <option value="Stock">Stock</option>
                                                <option value="No Stock">No Stock</option>
                                            </select>
                                        </div>

                                        <div className='form-group'>
                                            <label>Images (*Size should be within 5mb) <span style={{ color: 'red' }}>*</span></label>
                                            <div className='custom-file'>
                                                <input
                                                    type='file'
                                                    name='product_images'
                                                    accept='.jpg, .jpeg, .png, .webp' // Accepts only jpg, jpeg, and png files
                                                    className='custom-file-input'
                                                    id='images'
                                                    multiple
                                                    onChange={handleImagesChange}

                                                />
                                                <label className='custom-file-label' htmlFor='images'>
                                                    Choose Images
                                                </label>
                                            </div>
                                            {formData.imagesPreview.length > 0 &&
                                                <Fragment>
                                                    <span className="mr-2" onClick={clearImagesHandler} style={{ cursor: "pointer" }}>
                                                        <i className="fa fa-trash"></i>
                                                    </span>
                                                    {formData.imagesPreview.map(image => (
                                                        <img
                                                            className="mt-3 mr-2"
                                                            key={image}
                                                            src={image}
                                                            alt=" Preview"
                                                            width="55"
                                                            height="52"
                                                        />
                                                    ))}
                                                </Fragment>
                                            }
                                        </div>


                                        <button
                                            type="submit"
                                            className="btn btn-block py-3"
                                            disabled={updateloading}
                                        >
                                            {updateloading ? <LoaderButton fullPage={false} size={20} /> : (
                                                <span>  UPDATE</span>
                                            )

                                            }

                                        </button>
                                    </form>
                                </div>
                            </Fragment>
                        )
                    }

                </div>
            </div>
        </div>
    );
}

export default UpdateProduct;






