import axios from 'axios';
import {
    walletTransactionsRequest,
    walletTransactionsSuccess,
    walletTransactionsFail,
} from '../slices/transactionSlice';

export const getWalletTransactions = ({userId, fromDate, toDate}) => async (dispatch) => {
    try {
        dispatch(walletTransactionsRequest());
        const { data } = await axios.get(`/api/v1/wallet/transactions`, {
            params: { userId, fromDate, toDate },
        });
        dispatch(walletTransactionsSuccess(data.transactions));
    } catch (error) {
        dispatch(walletTransactionsFail(error.response?.data?.message || 'Failed to fetch transactions'));
    }
};
