import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
    newDiscountRequest,
    newDiscountSuccess,
    newDiscountFail,
    discountsGetRequest,
    discountsGetSuccess,
    discountsGetFail,
    discountsDeleteRequest,
    discountsDeleteSuccess,
    discountsDeleteFail,
    discountSingleRequest,
    discountSingleSuccess,
    discountSingleFail,
    discountsUpdateRequest,
    discountsUpdateSuccess,
    discountsUpdateFail,
    discountDeleteRequest,
    discountDeleteSuccess,
    discountDeleteFail,
} from '../slices/discountSlices';


export const createDiscount = createAsyncThunk('discount/create discount', async ({payload }, { dispatch }) => {
    try {
      dispatch(newDiscountRequest());
      const { data } = await axios.post('/api/v1/admin/discount/new',payload, {
        withCredentials: true
      });
      dispatch(newDiscountSuccess(data));
    } catch (error) {
      dispatch(newDiscountFail(error?.response?.data?.message));
    }
  });

  // Get all categories
export const getDiscounts = createAsyncThunk('discount/getdiscounts', async (_, { dispatch }) => {
    try {
      dispatch(discountsGetRequest());
      const { data } = await axios.get('/api/v1/admin/discount/getall');
      dispatch(discountsGetSuccess(data.discounts));
    } catch (error) {
      dispatch(discountsGetFail(error.response?.data?.message || 'Error fetching discount'));
    }
  });

  // Delete a discounts
export const deleteDiscounts = createAsyncThunk('discount/deletediscounts', async ({id}, { dispatch }) => {
    try {
      dispatch(discountsDeleteRequest());
      const { data } = await axios.delete(`/api/v1/admin/discount/${id}`);
      dispatch(discountsDeleteSuccess(data));
    } catch (error) {
      dispatch(discountsDeleteFail(error.response?.data?.message || 'Error deleting discount'));
    }
  });

  //get single discount

export const getSingleDiscount = createAsyncThunk('get/getSingleDiscount', async (id,{dispatch}) => {
    try {
              dispatch(discountSingleRequest());
              const {data} = await axios.get(`/api/v1/admin/discount/${id}`);
              dispatch(discountSingleSuccess(data));
        } catch (error) {
              dispatch(discountSingleFail(error.response.data.message));
            
        }
  })

  //update discounts
  export const updateDiscounts = createAsyncThunk('discount/updateDiscounts', async ({ id, payload }, { dispatch }) => {
    try {
      dispatch(discountsUpdateRequest());
      const { data } = await axios.put(`/api/v1/admin/discounts/update/${id}`, payload);
      dispatch(discountsUpdateSuccess(data));
    } catch (error) {
      dispatch(discountsUpdateFail(error.response?.data?.message || 'Error updating category'));
    }
  });

   // Delete a discounts
export const deleteSingleDiscounts = createAsyncThunk('category/deleteCategory', async ({id}, { dispatch }) => {
    try {
      dispatch(discountDeleteRequest());
      const { data } = await axios.delete(`/api/v1/admin/discount/single/${id}`);
      dispatch(discountDeleteSuccess(data));
    } catch (error) {
      dispatch(discountDeleteFail(error.response?.data?.message || 'Error deleting category'));
    }
  });