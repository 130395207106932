
import React, { useEffect, Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loader from '../Layouts/Loader';
import { Slide, toast } from 'react-toastify';
import Sidebar from "../admin/Sidebar";
import { clearError } from '../../slices/productsSlice';
import { clearOrderDeleted, orderDetailClear } from "../../slices/orderSlice";
import MetaData from '../Layouts/MetaData';
import { porterClearData, porterClearResponse } from '../../slices/porterSlice';
import { analysisOrders } from '../../actions/analysisActions';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';



const Analysis = ({ isActive, setIsActive }) => {
    const location = useLocation();
    const { loading = true, error, refundedAmount, dispatchedAmount, usersCount, totalEnquiries, totalOrders, totalAmount } = useSelector(state => state.analysisState);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(orderDetailClear());
        dispatch(porterClearData());
        dispatch(porterClearResponse());
    }, [dispatch]);

    useEffect(() => {
        if (error) {
            toast.dismiss();
            setTimeout(() => {
                toast.error(error, {
                    position: 'bottom-center',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onOpen: () => { dispatch(clearError()) }
                });
            }, 300);
        }
        dispatch(analysisOrders({ startDate, endDate }));
    }, [dispatch, error, startDate, endDate]);

    return (
        <div>
            <MetaData
                title="Analysis"
                description="Gain insights into sales trends, customer behavior, and product performance through detailed analytics and reports."
            />

            <div className="row loader-parent" style={{ margin: '5px' }}>
                <div className="col-12 col-md-2">
                    <div className="sidebar-fixed">
                        <Sidebar isActive={isActive} setIsActive={setIsActive} />
                    </div>
                </div>
                <div className="col-12 col-md-10 smalldevice-space loader-parent">
                    <h1 className="admin-dashboard-x mb-4">Analysis</h1>
                    <div className="back-button" onClick={() => navigate(-1)}>
                        <ArrowBackIcon fontSize="small" />
                        <span>Back</span>
                    </div>
                    <div className="filter-row">
                        <div style={{ display: 'flex', flexDirection: 'row', marginRight: '20px' }}>
                            <label>StartDate : </label>
                            <input
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                className="form-control mb-3 date-input"
                                placeholder="Start Date"
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', marginRight: '20px' }}>
                            <label>EndDate : </label>
                            <input
                                type="date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                className="form-control mb-3 date-input"
                                placeholder="End Date"
                            />
                        </div>

                    </div>

                    {loading ? (
                        <div className="container loader-loading-center">
                            <Loader />
                        </div>
                    ) : (

                        <div className="row " style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
                            <div className="col-lg-3 col-md-3 col-6 mb-3">
                                <div className="card text-white o-hidden " style={{ backgroundColor: '#FED235', minHeight: '150px' }}>
                                    <div className="card-body">
                                        <div className="text-center card-font-size">
                                            Total Orders<br />
                                            <b>{totalOrders && totalOrders}</b>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-3 col-6 mb-3">
                                <div className="card text-white o-hidden " style={{ backgroundColor: '#02441E', minHeight: '150px' }}>
                                    <div className="card-body">
                                        <div className="text-center card-font-size">
                                            Total Amount<br />
                                            <b>{totalAmount?.toFixed(2)}</b>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-3 col-md-3 col-6 mb-3">
                                <div className="card text-white o-hidden " style={{ backgroundColor: '#FED235', minHeight: '150px' }}>
                                    <div className="card-body">
                                        <div className="text-center card-font-size">
                                            Dispatched Amount<br />
                                            <b>{dispatchedAmount?.toFixed(2)}</b>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-3 col-6 mb-3">
                                <div className="card text-white o-hidden " style={{ backgroundColor: '#02441E', minHeight: '150px' }}>
                                    <div className="card-body">
                                        <div className="text-center card-font-size">
                                            Refunded Amount<br />
                                            <b>{refundedAmount?.toFixed(2)}</b>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-3 col-6 mb-3">
                                <div className="card text-white o-hidden " style={{ backgroundColor: '#FED235', minHeight: '150px' }}>
                                    <div className="card-body">
                                        <div className="text-center card-font-size">
                                            Users Count<br />
                                            <b>{usersCount}</b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-6 mb-3">
                                <div className="card text-white o-hidden " style={{ backgroundColor: '#02441E', minHeight: '150px' }}>
                                    <div className="card-body">
                                        <div className="text-center card-font-size">
                                            Total Enquiries<br />
                                            <b>{totalEnquiries}</b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Analysis;
