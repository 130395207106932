import React, { useEffect, useState, Fragment } from "react";
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import Sidebar from "../admin/Sidebar";
import MetaData from "../Layouts/MetaData";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryByDate } from "../../actions/marginAction";
import { clearCategoryReport } from "../../slices/marginReportSlice";
import { Slide, toast } from 'react-toastify';
import Loader from "../Layouts/Loader";

const CategoryDetailPage = ({ isActive, setIsActive }) => {
    const { category, fromDate, toDate } = useParams(); // category ID and dates from URL
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { categoryReport = null } = location.state || {}; // Passed data from the previous screen
    const currentDate = new Date();
    const formattedCurrentDate = currentDate.toISOString().split("T")[0];

    const [inputStartDate, setInputStartDate] = useState(fromDate || formattedCurrentDate);
    const [inputEndDate, setInputEndDate] = useState(toDate || formattedCurrentDate);
    const [startDate, setStartDate] = useState(fromDate || formattedCurrentDate);
    const [endDate, setEndDate] = useState(toDate || formattedCurrentDate);
    const [selectedCategory, setSelectedCategory] = useState(category || "All");

    const { categoryReport: categoryNewReport, categoryerror, categoryloading } = useSelector(
        (state) => state.marginReportState
    );

    // Synchronize selectedCategory with URL parameter
    useEffect(() => {
        setSelectedCategory(category || "All");
    }, [category]);

    // Fetch category data based on date range
    useEffect(() => {
        if (startDate || endDate) {
            dispatch(getCategoryByDate({ startDate, endDate }));
        }
    }, [startDate, endDate, dispatch]);



    const handleStartDateChange = (e) => {
        const newStartDate = e.target.value;
        setStartDate(newStartDate);
        setInputStartDate(newStartDate);
        navigateToUpdatedUrl(selectedCategory, newStartDate, endDate);
    };

    const handleEndDateChange = (e) => {
        const newEndDate = e.target.value;
        setEndDate(newEndDate);
        setInputEndDate(newEndDate);
        navigateToUpdatedUrl(selectedCategory, startDate, newEndDate);
    };

    const handleCategoryChange = (event) => {
        const newCategory = event.target.value;
        setSelectedCategory(newCategory);
        navigateToUpdatedUrl(newCategory, startDate, endDate);
    };

    const navigateToUpdatedUrl = (newCategory, newStartDate, newEndDate) => {
        const url = `/admin/categoryDetailProduct/${newCategory}/${newStartDate}/${newEndDate}`;
        navigate(url);
    };

    useEffect(() => {
        if (categoryerror) {
            toast.dismiss();
            setTimeout(() => {
                toast.error(categoryerror, {
                    position: 'bottom-center',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onOpen: () => { dispatch(clearCategoryReport()) }
                });
            }, 300);
            return
        }


    }, [dispatch, categoryerror])

    const setOrders = () => {
        const data = {
            columns: [
                { label: "S.No", field: "s_no", sort: "disabled" },
                { label: "Product", field: "product", sort: "disabled" },
                { label: "Original Price", field: "originalPrice", sort: "disabled" },
                { label: "Selling Price", field: "sellingPrice", sort: "disabled" },
                { label: "Offer", field: "offer", sort: "disabled" },
                { label: "Total Order", field: "totalOrder", sort: "disabled" },
                { label: "Purchase Cost", field: "amountSpent", sort: "disabled" },
                { label: "Sales Revenue", field: "amountGot", sort: "disabled" },
                { label: "Total Profit", field: "totalProfit", sort: "disabled" },
                { label: "Total Loss", field: "totalLoss", sort: "disabled" },
                { label: "Total Profit/Loss", field: "totalProfitLoss", sort: "disabled" },
            ],
            rows: [],
        };

        const dataSource = categoryNewReport || categoryReport || [];
        let serialNumber = 1;
        
        // Iterate through the dataSource
        dataSource?.forEach((item) => {
            if (item.category === selectedCategory || selectedCategory === "All") {
                // Create a shallow copy of products before sorting
                const sortedProducts = [...item.products].sort((a, b) => a.product.localeCompare(b.product));
        
                // Add each sorted product to the rows
                sortedProducts.forEach((product) => {
                    if (product.totalOrderDocCount > 0) {
                        const totalProfitStyle = product.totalProfit > 0 ? { color: "green" } : product.totalProfit < 0 ? { color: "red" } : { color: "black" };
                        const totalLossStyle = product.totalLoss < 0 ? { color: "red" } : product.totalLoss === 0 ? { color: "black" } : { color: "black" };
                        const totalProfitLossStyle = product.totalProfitAndLoss > 0 ? { color: "green" } : product.totalProfitAndLoss < 0 ? { color: "red" } : { color: "black" };
                        data.rows.push({
                            s_no: serialNumber++,
                            product: (
                                <Link
                                    to={`/admin/productDetailByDate/${encodeURIComponent(product.product)}/${product.productId}/${startDate}/${endDate}`}
                                    state={{ categoryNewReport }}
                                    style={{ textDecoration: "none", color: "blue" }}
                                >
                                    {product.product}
                                </Link>
                            ),
                            originalPrice: product.originalPrice,
                            sellingPrice: product.sellingPrice,
                            offer: product.offer,
                            totalOrder: product.totalOrderDocCount,
                            amountSpent: product.totalAmountSpent.toFixed(2),
                            amountGot: product.totalAmountgot.toFixed(2),
                            // totalProfit: product.totalProfit.toFixed(2),
                            // totalLoss: product.totalLoss.toFixed(2),
                            // totalProfitLoss: product.totalProfitAndLoss.toFixed(2),
                            totalProfit: (
                                <span style={totalProfitStyle}>{product.totalProfit.toFixed(2)}</span>
                            ),
                            totalLoss: (
                                <span style={totalLossStyle}>{product.totalLoss.toFixed(2)}</span>
                            ),
                            totalProfitLoss: (
                                <span style={totalProfitLossStyle}>{product.totalProfitAndLoss.toFixed(2)}</span>
                            ),
                        });
                    }
                });
            }
        });
        
        return data;
    }        

    return (
        <div>
            <MetaData title="Order Summary" />
            <div className="row loader-parent">
                <div className="col-12 col-md-2">
                    <Sidebar isActive={isActive} setIsActive={setIsActive} />
                </div>

                <div className="col-12 col-md-10 smalldevice-space-summary loader-parent mt-0">
                    <h1 className="mb-4 admin-dashboard-x">Margin Report</h1>
                    <div className="button-container mb-2">
                        <span
                            className="order-summary-back-button"
                            onClick={() => navigate(-1)}
                        >
                            <ArrowBackIcon fontSize="small" />
                            <span>Back</span>
                        </span>
                    </div>

                    <div className="paymentlist-container">
                        <div className="paymentlist-date-filter">
                            <div className="start-date">
                                <label htmlFor="startDate">Start Date:</label>
                                <input
                                    type="date"
                                    value={inputStartDate}
                                    onChange={handleStartDateChange}
                                    className="paymentlist-form-control"
                                />
                            </div>
                            <div className="end-date">
                                <label htmlFor="endDate">End Date:</label>
                                <input
                                    type="date"
                                    value={inputEndDate}
                                    onChange={handleEndDateChange}
                                    className="paymentlist-form-control"
                                />
                            </div>
                        </div>
                        <div className="filter-status">
                            <label htmlFor="selectedCategory">Filter:</label>
                            {categoryNewReport && (
                                <select
                                    value={selectedCategory}
                                    onChange={handleCategoryChange}
                                    className="paymentlist-form-select"
                                >
                                    <option value="All">All</option>
                                    {categoryNewReport.map((categoryData) => (
                                        <option key={categoryData.category} value={categoryData.category}>
                                            {categoryData.category}
                                        </option>
                                    ))}
                                </select>
                            )}
                        </div>
                    </div>

                    <Fragment>
                        {categoryloading ? (<div className="container loader-loading-center">
                            <Loader />
                        </div>) :
                            <div className='mdb-table' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <MDBDataTable
                                    data={setOrders()}
                                    bordered
                                    hover
                                    className="px-3 product-table"
                                    noBottomColumns
                                />
                            </div>
                        }
                    </Fragment>
                </div>
            </div>
        </div>
        // </div>
    );
};

export default CategoryDetailPage;