import { Fragment, useEffect, useState } from 'react'
import MetaData from '../Layouts/MetaData';
import { MDBDataTable } from 'mdbreact'
import { useDispatch, useSelector } from 'react-redux';
import { userOrders as userOrdersAction } from '../../actions/orderActions';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import Loader from '../Layouts/Loader';
import { Slide, toast } from "react-toastify";
import { clearError, orderDetailClear } from '../../slices/orderSlice';
import { porterClearData, porterClearResponse } from '../../slices/porterSlice';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


export default function UserOrders() {

    const { loading, userOrders, error } = useSelector(state => state.orderState)
    const dispatch = useDispatch();
    const [iserror, setIserror] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    // const currentDate = new Date();
    // const formattedCurrentDate = currentDate.toISOString().split('T')[0];
    // const [inputStartDate, setInputStartDate] = useState('');
    // const [inputEndDate, setInputEndDate] = useState('');
    // const [startDate, setStartDate] = useState(formattedCurrentDate);
    // const [endDate, setEndDate] = useState(formattedCurrentDate);
    const currentDate = new Date(); // Get the current date
    const formattedCurrentDate = currentDate.toISOString().split('T')[0]; // Format current date

    // Calculate one month ago
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(currentDate.getDate() - 7); // Subtract one month
    const formattedOneWeekAgo = oneWeekAgo.toISOString().split('T')[0]; // Format one month ago date

    // State variables
    const [inputStartDate, setInputStartDate] = useState('');
    const [inputEndDate, setInputEndDate] = useState('');
    const [startDate, setStartDate] = useState(formattedOneWeekAgo); // Initialize to one month ago
    const [endDate, setEndDate] = useState(formattedCurrentDate); // Initialize to today
    useEffect(() => {
        dispatch(orderDetailClear());
        dispatch(porterClearData());
        dispatch(porterClearResponse());
    }, [])

    useEffect(() => {
        if (error) {
            toast.dismiss();
            setTimeout(() => {
                toast.error(error, {
                    position: 'bottom-center',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onOpen: () => { dispatch(clearError()) }
                });
            }, 300);
            setIserror(true)
            return;
        }
    }, [error])

    useEffect(() => {
        if (!userOrders && !iserror) {
            dispatch(userOrdersAction({ startDate, endDate }));
        }

    }, [userOrders, iserror])

    useEffect(() => {
        if (startDate || endDate) {
            dispatch(userOrdersAction({ startDate, endDate }));
        }

    }, [startDate, endDate])

    const setOrders = () => {
        const data = {
            columns: [
                {
                    label: 'S.No',
                    field: 's_no',
                    sort: 'disabled'
                },
                {
                    label: "Order ID",
                    field: 'id',
                    sort: "disabled"
                },
                {
                    label: "TimeStamp",
                    field: 'time',
                    sort: "disabled"
                },
                {
                    label: "Name",
                    field: 'name',
                    sort: "disabled"
                },
                {
                    label: "Amount",
                    field: 'amount',
                    sort: "disabled"
                },
                {
                    label: "Status",
                    field: 'status',
                    sort: "disabled"
                },
                {
                    label: "Actions",
                    field: 'actions',
                    sort: "disabled"
                }
            ],
            rows: []
        }

        // Sort orders by creation date (newest first)
        const sortedOrders = userOrders && [...userOrders].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        sortedOrders && sortedOrders.forEach((userOrder, index) => {
            data.rows.push({
                s_no: index + 1,
                id: userOrder.order_id,
                time: userOrder.createdAt,
                name: userOrder?.shippingInfo?.name,
                amount: `₹ ${userOrder.totalPrice}`,
                status: userOrder.orderStatus && userOrder.orderStatus.includes('Delivered') ?
                    (<p style={{ color: 'green', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px', padding: '0px' }}> {userOrder.orderStatus} </p>) :
                    (<p style={{ color: 'red', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px', padding: '0px' }}> {userOrder && userOrder.paymentStatus && userOrder.paymentStatus === 'CHARGED' ? userOrder.orderStatus : 'Cancelled'} </p>),
                actions: <Link to={`/order/${userOrder.order_id}`} className="userorder_btn" >
                    {/* <i className='fa fa-eye'></i> */}
                    View
                </Link>
            })
        })


        return data;
    }


    return (
        <Fragment>
            <MetaData
                title="Your Orders"
                description="Check all your previous and current orders in one place. Track shipments, view details, and manage your purchases with ease."
            />

            <div className="products_heading">Orders</div>
            <div className="">
                <span className="back-button" onClick={() => navigate(-1)}>
                    <ArrowBackIcon fontSize="small" />
                    <span>Back</span>
                </span>

            </div>
            <div className="paymentlist-date-filter" style={{ marginLeft: '15px', marginTop: '10px' }}>
                <div className='start-date'>
                    <label htmlFor="startDate">From:</label>
                    <input
                        type="date"
                        value={inputStartDate === '' ? startDate : inputStartDate}
                        onChange={(e) => { setStartDate(e.target.value); setInputStartDate(e.target.value) }}
                        className="paymentlist-form-control"
                    />
                </div>
                <div className="end-date">
                    <label htmlFor="endDate">To:</label>
                    <input
                        type="date"
                        value={inputEndDate === '' ? endDate : inputEndDate}
                        onChange={(e) => { setEndDate(e.target.value); setInputEndDate(e.target.value) }}
                        className="paymentlist-form-control"
                    />
                </div>
            </div>

            {loading ?
                <div style={{ marginTop: '4rem' }}>
                    <Loader />
                </div>

                : <div className='mdb-table' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Fragment>
                        <MDBDataTable
                            data={setOrders()}
                            bordered
                            hover
                            className="px-3 userproduct-table"
                            noBottomColumns
                        />
                    </Fragment>
                </div>}
        </Fragment>
    )
}

