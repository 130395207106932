import { createSlice } from '@reduxjs/toolkit';

const transactionSlice = createSlice({
    name: 'transactions',
    initialState: {
        walletTransactions: [],
        walletLoading: false,
        walletError: null,
    },
    reducers: {
        walletTransactionsRequest(state) {
            state.walletLoading = true;
            state.walletError = null;
        },
        walletTransactionsSuccess(state, action) {
            state.walletLoading = false;
            state.walletTransactions = action.payload;
        },
        walletTransactionsFail(state, action) {
            state.walletLoading = false;
            state.walletError = action.payload;
        },
        clearWalletTransactions(state, action) {
            state.walletLoading = false;
            state.walletError = null;
            state.walletTransactions = null;
        },

    },
});


const { actions, reducer } = transactionSlice;

export const { 
    walletTransactionsRequest,
    walletTransactionsSuccess,
    walletTransactionsFail,
    clearWalletTransactions,
 } = actions;

export default reducer;