import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendOtp, verifyOtp } from '../../actions/loginOtpActions';
import { toast, Slide } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { clearSendOtp, clearVerifyError } from '../../slices/authSlice';
import LoaderButton from '../Layouts/LoaderButton';
import NumberInput from '../Layouts/NumberInput';
import OtpInput from 'react-otp-input';

const LoginForm = ({ showModal, onClose }) => {
    const [input, setInput] = useState('');
    const [OTPError, setOTPError] = useState(null);
    const [otp, setOtp] = useState("");
    const [key, setKey] = useState(false);
    const [inputError, setInputError] = useState('');
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [isReOtpSent, setIsReOtpSent] = useState(false);
    const [reSent, setReSent] = useState(false);
    const [isValidInput, setIsValidInput] = useState(false);
    const [timer, setTimer] = useState(60);
    const [showResendLink, setShowResendLink] = useState(false);
    const [selectedInputType, setSelectedInputType] = useState('phone'); // New state for input type
    const otpInputRefs = useRef([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { sendLoading, verifyLoading, verifyError, sendError, sendMessage, error, success, isAuthenticated, user } = useSelector(state => state.authState);
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const inputRefs = useRef([]); // To store references to each input
    // const isAndroid = /Android/.test(navigator.userAgent);
    const [focusedIndex, setFocusedIndex] = useState(null); // Track focused index
    const location = useLocation();

    // Condition to check if user is on the cart page
    const isOnCartPage = location.pathname === '/cart'; // Adjust based on your route

    // If the user is on the cart page and not logged in, don't show the button
    const shouldNavigate =  isOnCartPage && !user && !isAuthenticated ;


    // Validate input (email or phone number)
    const validateInput = (value) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        const phoneRegex = /^\d{10}$/;
        if (emailRegex.test(value) || phoneRegex.test(value)) {
            setInputError('');
            setIsValidInput(true);
            return true;
        } else {
            if (selectedInputType === 'phone') {
                setInputError('Please enter a valid number.');
                setIsValidInput(false);
                return false;
            }
            else {
                setInputError('Please enter a valid email.');
                setIsValidInput(false);
                return false;
            }

        }
    };

    // Handle input change
    const handleInputChange = (e) => {
        const value = e.target.value;
        setInput(value);
        validateInput(value);
    };

    // Send OTP
    const handleSendOtp = () => {
        setOTPError(null);
        if (!validateInput(input)) {
            toast.dismiss();
            setTimeout(() => {
                toast.error(inputError, {
                    position: 'bottom-center',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                });
            }, 300);
            return;
        }

        dispatch(sendOtp({ input, inputType: selectedInputType }));
        otpInputRefs.current[0]?.focus();
    };

    const handleResendOtp = () => {
        setOTPError(null);
        if (!validateInput(input)) {
            toast.dismiss();
            setTimeout(() => {
                toast.error(inputError, {
                    position: 'bottom-center',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                });
            }, 300);
            return;
        }
        dispatch(clearSendOtp());
        dispatch(sendOtp({ input, inputType: selectedInputType }));
        setReSent(true);
        otpInputRefs.current[0]?.focus();
    };



    useEffect(() => {
        if (isAuthenticated) {
            onClose();
            toast.dismiss();
            setTimeout(() => {
                toast.success('Login successfully', {
                    position: 'bottom-center',
                    type: 'success',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                });
            }, 300);
            navigate('/');
        }
    }, [isAuthenticated])


    const handleChange = (otpValue) => {
        setOtp(otpValue);
        if (otpValue.length === 6) {
            handleVerifyOtp(otpValue); // Call verify OTP function when all 6 digits are filled
        }
    };

    // Handle OTP verification
    const handleVerifyOtp = (otp) => {
        setOTPError(null);
        dispatch(verifyOtp({ input, otp: otp }));
    };


    // Reset OTP fields if OTP verification fails
    useEffect(() => {
        if (verifyError) {
            toast.dismiss();
            setOTPError(verifyError);
            dispatch(clearVerifyError());
            setOtp("");
            setKey(!key);
            // inputRefs.current[0].focus();

        }
        if (sendError) {
            toast.dismiss();
            setTimeout(() => {
                toast.error(sendError, {
                    position: 'bottom-center',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                });
            }, 300);
            dispatch(clearSendOtp());
        }
        if (sendMessage && !isOtpSent) {
            setIsOtpSent(true);
            setShowResendLink(false);
            setTimer(60);
            toast.dismiss();
            setTimeout(() => {
                toast.success(sendMessage, {
                    position: 'bottom-center',
                    type: 'success',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                });
            }, 300);
            dispatch(clearSendOtp());
        }
        if (sendMessage && isOtpSent && reSent) {
            setIsReOtpSent(true);
            setShowResendLink(false);
            setTimer(60);
            setReSent(false);
            toast.dismiss();
            setTimeout(() => {
                toast.success(sendMessage, {
                    position: 'bottom-center',
                    type: 'success',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                });
            }, 300);
            dispatch(clearSendOtp());
        }
    }, [verifyError, sendError, sendMessage, reSent]);

    // Timer effect for OTP expiration
    useEffect(() => {
        let interval;
        if (isOtpSent && timer > 0) {
            interval = setInterval(() => {
                setTimer((prev) => prev - 1);
            }, 1000);
        } else if (timer === 0) {
            setShowResendLink(true); // Show resend link when timer reaches 0
        }

        return () => clearInterval(interval);
    }, [isOtpSent, timer]);


    const handleTypeSelection = (type) => {
        setSelectedInputType(type);
        setInput('');  // Clear input when switching between types
        setInputError('');
    };



    const handelBack = () => {
        setIsOtpSent(false);
        setOtp("");
        setKey(!key);
        setInputError('');
        setOTPError(null);
        setIsReOtpSent(false);
        setReSent(false);
        setTimer(60);
        setShowResendLink(false);

    }
    const onCloseHandler = () => {
        if(shouldNavigate){
            setIsOtpSent(false);
            setOtp("");
            setKey(!key);
            setInputError('');
            setOTPError(null);
            setIsReOtpSent(false);
            setReSent(false);
            setTimer(60);
            setShowResendLink(false);
            onClose();
            navigate('/')
        }else{
            setIsOtpSent(false);
            setOtp("");
            setKey(!key);
            setInputError('');
            setOTPError(null);
            setIsReOtpSent(false);
            setReSent(false);
            setTimer(60);
            setShowResendLink(false);
            onClose();
        }
        
    }

    const handlePaste = (e) => {
        e.preventDefault(); // Prevent the default paste behavior
        const clipboardData = e.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData('Text').trim();

        // Ensure the pasted data is a 6-digit numeric OTP
        if (/^\d{6}$/.test(pastedData)) {
            setOtp(pastedData); // Set the full OTP value
            handleVerifyOtp(pastedData); // Optionally, verify OTP immediately
        } else {
            // Show an error if the pasted data is not valid
            toast.error('Invalid OTP format!', {
                position: 'bottom-center',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

    const getInputStyle = (index) => {
        const isActive = focusedIndex === index;
        const isFilled = otp[index] !== undefined && otp[index] !== '';
        return {
            ...styles.input,
            borderColor: isActive ? '#FED235' : isFilled ? 'green' : '#ccc', // Black for active, Green for filled, Gray for default
        };
    };


    if (!showModal) return null;

    return (
        <div className="modal" tabIndex="-1" role="dialog">
            <div className="modal-overlay" role="document" >
                <div className="login-modal-content">
                    <div style={{ display: 'flex', flexDirection: 'column' }} >
                        <div style={{ display: 'flex', flexDirection: 'column' }} >
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                {
                                    isOtpSent && (
                                        <div className="back-button" onClick={() => handelBack()}>
                                            <ArrowBackIcon fontSize="small" />
                                        </div>
                                    )
                                }

                                <h5 className="modal-title" style={{ color: '#02441E' }}>Login  to JAS</h5>

                                <button type="button" className="close" onClick={onCloseHandler}>
                                    <span aria-hidden="true">&times;</span>
                                </button>

                                {/* {shouldShowCloseButton && (
                                    <button type="button" className="close" onClick={onCloseHandler}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                )} */}


                            </div>


                            {
                                !isOtpSent && (
                                    <div className="modal-header">
                                        <button
                                            type="button"
                                            onClick={() => handleTypeSelection('phone')}
                                            className={`phone-email-button ${selectedInputType === 'phone' ? 'selected' : ''}`}
                                        >
                                            Phone Number
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => handleTypeSelection('email')}
                                            className={`phone-email-button ${selectedInputType === 'email' ? 'selected' : ''}`}
                                        >
                                            Email
                                        </button>
                                    </div>
                                )
                            }


                        </div>

                    </div>
                    <div className="modal-body" >

                        <form onSubmit={(e) => {
                            e.preventDefault(); // Prevent form's default submission
                            handleSendOtp(); // Call the send OTP function
                        }}>
                            {!isOtpSent ? (
                                <div >
                                    {
                                        selectedInputType === 'email' ? (
                                            <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                                                <input
                                                    type="text"
                                                    value={input}
                                                    onChange={handleInputChange}
                                                    placeholder="Enter Email"
                                                    style={{
                                                        width: '100%', // Adjust width as necessary
                                                        outline: 'none',
                                                    }}
                                                />
                                            </div>
                                        ) : (
                                            <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                                                <span style={{
                                                    position: 'absolute',
                                                    left: '10px',
                                                    fontSize: '13px',
                                                    color: '#555',

                                                }}>
                                                    +91
                                                </span>
                                                <div style={{
                                                    position: 'absolute',
                                                    left: '35px', // Position to the right of "+91"
                                                    height: '20px',
                                                    width: '1px',
                                                    backgroundColor: '#ccc',
                                                    marginLeft: '5px'
                                                }} />
                                                <NumberInput
                                                    type="number"
                                                    value={input}
                                                    onChange={handleInputChange}
                                                    placeholder="Enter phone number"
                                                    style={{
                                                        paddingLeft: '50px', // Add padding to create space for the prefix
                                                        width: '100%', // Adjust width as necessary
                                                        outline: 'none',
                                                    }}
                                                />
                                            </div>
                                        )
                                    }


                                    {inputError && <p style={{ color: 'red' }}>{inputError}</p>}
                                    <button
                                        type="submit"
                                        className="otp-button"
                                        onClick={handleSendOtp}
                                        disabled={sendLoading || !isValidInput}
                                    >
                                        {sendLoading ? <LoaderButton fullPage={false} size={20} /> : 'Continue'}
                                    </button>
                                </div>
                            ) : (
                                <div>
                                    <div className="modal-line"></div>
                                    <h6 style={{ marginBottom: '5px', marginTop: '20px', textAlign: 'center' }}>
                                        We have sent a verification code to
                                    </h6>
                                    <div style={{ textAlign: 'center', fontSize: '15px', fontWeight: 'bold', marginBottom: '20px', }}>
                                        {
                                            selectedInputType === 'phone' ? (
                                                <span>+91-{input}</span>
                                            ) : (
                                                <span>{input}</span>
                                            )
                                        }

                                    </div>
                                    {/*                                    
                                    <OtpKit
                                        key={key}  // Force re-render on OTP state change
                                        value={otp}  // Ensure OTP value is properly set
                                        onChange={handleChange}
                                        onPaste={handlePaste} // Handle pasting the OTP
                                        type={"number"}
                                        maxLength={6}
                                        autoSubmit={true}
                                        submitOtpButton={{ show: false }}
                                        
                                    /> */}

                                    <div style={styles.container}>
                                        <OtpInput
                                            key={key}
                                            value={otp}
                                            onChange={handleChange}
                                            numInputs={6} // Number of OTP input fields
                                            inputType="tel" // Ensure only numeric input
                                            renderInput={(props, index) => <input {...props}
                                                // ref={(el) => inputRefs.current[index] = el} // Store references to each input
                                                // onFocus={(e) => handleFocus(e)} // Handle focus event
                                                style={getInputStyle(index)} // Apply dynamic styling
                                                onFocus={() => setFocusedIndex(index)} // Set focused index
                                                onBlur={() => setFocusedIndex(null)} // Clear focused index
                                            />}
                                            containerStyle={styles.inputContainer}
                                            inputStyle={styles.input}
                                            shouldAutoFocus
                                            onPaste={(e) => {
                                                const pastedOtp = e.clipboardData.getData('text').trim();
                                                if (pastedOtp.length === 6) {
                                                    setOtp(pastedOtp); // Directly set OTP if it's valid
                                                }
                                            }}
                                        />
                                    </div>

                                    {OTPError ? (
                                        <p style={{
                                            color: 'red',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            marginTop: '0px'
                                        }}>{OTPError}</p>
                                    ) : (
                                        <></>
                                    )
                                    }

                                    {timer > 0 ? (
                                        <p style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            marginTop: '0px'
                                        }}>OTP expires in: {timer}s</p>
                                    ) : (
                                        showResendLink && (
                                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '0px' }}>
                                                {
                                                    sendLoading || verifyLoading ? <LoaderButton fullPage={false} size={20} /> : (
                                                        <a
                                                            href="#"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                handleResendOtp();
                                                            }}
                                                            style={{
                                                                color: 'green',
                                                                cursor: 'pointer',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            Resend Code
                                                        </a>
                                                    )
                                                }

                                            </div>

                                        )
                                    )}
                                </div>
                            )}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
        width: 'auto',
    },
    inputContainer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        gap: '10px',
        marginBottom: '20px',
    },
    input: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '40px',
        height: '40px',
        textAlign: 'center',
        borderRadius: '8px',
        border: '2px solid #ccc',
        outline: 'none',
        transition: 'border-color 0.3s',
    },
};

export default LoginForm;