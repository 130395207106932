import { createAsyncThunk } from '@reduxjs/toolkit';
import {addCartItemRequest, addCartItemSuccess} from '../slices/cartSlice';
import axios from 'axios'

export const addCartItem = createAsyncThunk('post/orderItem', async({productId, quantity, productWeight},{dispatch}) => {
    try {
        dispatch(addCartItemRequest())
        const {data } = await axios.get(`/api/v1/product/${productId}`)
        console.log("data", data)
        dispatch(addCartItemSuccess({
            product: data.product._id,
            name: data.product.englishName,
            tamilname:data.product.tamilName,
            category:data.product.category,
            price: data.product.price,
            image: data.product.images[0].image,
            quantity,
            measurement:data.product.measurement,
            range: data.product.range,
            productWeight,
            stocks: data.product.stocks,
            maximumQuantity:data.product.maximumQuantity,
        }))
    } catch (error) {
        
    }
})