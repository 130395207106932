import React, { Fragment, useEffect, useState } from 'react'
import MetaData from '../Layouts/MetaData'
import { getProducts } from '../../actions/productsActions'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../Layouts/Loader'
import Product from '.././Product/Product'
import { toast } from 'react-toastify';
import { useLocation, useParams } from 'react-router-dom'



const ProductSearch = () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const { products, loading, error } = useSelector((state) => state.productsState)
    const { keyword } = useParams();
    const [category, setCategory] = useState(null);


    const categories = [
        'Vegetables',
        'Fruits'
    ];

    useEffect(() => {
        if (error) {
            return toast.error(error, { position: "bottom-center" });
        }
        dispatch(getProducts({ keyword, category }));

    }, [error, dispatch, keyword, category])

    return (
        <Fragment>
            {loading ? <Loader /> :
                <Fragment>
                    <MetaData title={'Buy Best Products'} />
                    <h1 id="products_heading">Search Products</h1>

                    <section id="products" className="product_container mt-5">
                        <div className="row product_container">
                            <div className=" col-md-12 ">
                                <div className="row">
                                    <Product products={products} />
                                </div>
                            </div>

                        </div>
                    </section>
                </Fragment>
            }
        </Fragment>
    )
}

export default ProductSearch
