import React, { useEffect, useState, Fragment } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import Sidebar from "../admin/Sidebar";
import MetaData from "../Layouts/MetaData";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch, useSelector } from "react-redux";
import { getCategoryByDate, getDateByProduct, getProductsByDate } from "../../actions/marginAction";
import { clearDateProductReport } from "../../slices/marginReportSlice";
import { Slide, toast } from 'react-toastify';
import Loader from "../Layouts/Loader";

const ProductDatePage = ({ isActive, setIsActive }) => {
    const { product, id, fromDate, toDate } = useParams(); // id used to get the selected date
    const decodedProduct = decodeURIComponent(product);
  

    const { dateProductReport, dateProductloading, dateProducterror } = useSelector((state) => state.marginReportState);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    // const { categoryNewReport = null } = location.state || {};
    const currentDate = new Date();
    const formattedCurrentDate = currentDate.toISOString().split("T")[0];

    const [inputStartDate, setInputStartDate] = useState(fromDate || formattedCurrentDate);
    const [inputEndDate, setInputEndDate] = useState(toDate || formattedCurrentDate);
    const [startDate, setStartDate] = useState(fromDate || formattedCurrentDate);
    const [endDate, setEndDate] = useState(toDate || formattedCurrentDate);
    // const [selectedCategory, setSelectedCategory] = useState("All");
    // const [products, setProducts] = useState([]);
    // const [selectedProduct, setSelectedProduct] = useState(id || "");

    // Fetch category data based on date range
    // useEffect(() => {
    //     if (!categoryNewReport) {
    //         dispatch(getCategoryByDate({ startDate, endDate }));
    //     }
    // }, [startDate, endDate, dispatch, categoryNewReport]);

    useEffect(() => {

        dispatch(getDateByProduct({ startDate, endDate, id }));

    }, [startDate, endDate, id, dispatch]);

    const handleStartDateChange = (e) => {
        const newStartDate = e.target.value;
        setStartDate(newStartDate);
        setInputStartDate(newStartDate);
        navigateToUpdatedUrl(newStartDate, endDate);
    };

    const handleEndDateChange = (e) => {
        const newEndDate = e.target.value;
        setEndDate(newEndDate);
        setInputEndDate(newEndDate);
        navigateToUpdatedUrl(startDate, newEndDate);
    };



    const navigateToUpdatedUrl = (newStartDate, newEndDate) => {
        const url = `/admin/productDetailByDate/${product}/${id}/${newStartDate}/${newEndDate}`;
        navigate(url, { replace: true }); // Prevent adding too many entries in history
    };

    useEffect(() => {
        if (dateProducterror) {
            toast.dismiss();
            setTimeout(() => {
                toast.error(dateProducterror, {
                    position: 'bottom-center',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onOpen: () => { dispatch(clearDateProductReport()) }
                });
            }, 300);
            return
        }


    }, [dispatch, dateProducterror])


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if day is less than 10
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month (0-indexed)
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };


    const setOrders = () => {
        const data = {
            columns: [
                { label: "S.No", field: "s_no", sort: "disabled" },
                { label: "Date", field: "date", sort: "disabled" },
                { label: "Original Price", field: "originalPrice", sort: "disabled" },
                { label: "Selling Price", field: "sellingPrice", sort: "disabled" },
                { label: "Offer", field: "offer", sort: "disabled" },
                { label: "Purchase Cost", field: "amountSpent", sort: "disabled" },
                { label: "Sales Revenue", field: "amountGot", sort: "disabled" },
                { label: "Total Profit", field: "totalProfit", sort: "disabled" },
                { label: "Total Loss", field: "totalLoss", sort: "disabled" },
                { label: "Total Profit/Loss", field: "totalProfitLoss", sort: "disabled" },
            ],
            rows: [],
        };


        dateProductReport?.forEach((item, index) => {
            const totalProfitStyle = item.totalProfit > 0 ? { color: "green" } : item.totalProfit < 0 ? { color: "red" } : { color: "black" };
            const totalLossStyle = item.totalLoss < 0 ? { color: "red" } : item.totalLoss === 0 ? { color: "black" } : { color: "black" };
            const totalProfitLossStyle = item.totalProfitAndLoss > 0 ? { color: "green" } : item.totalProfitAndLoss < 0 ? { color: "red" } : { color: "black" };
            // const formattedDate = new Date(item.date).toISOString().split("T")[0];
            const formattedDate = formatDate(item.date); // Format date to dd-mm-yyyy
            data.rows.push({
                s_no: index + 1,
                date: formattedDate,
                originalPrice: item.originalPrice,
                sellingPrice: item.sellingPrice,
                offer: item.offer,
                amountSpent: item.totalAmountSpent.toFixed(2),
                amountGot: item.totalAmountGot.toFixed(2),
                // totalProfit: item.totalProfit.toFixed(2),
                // totalLoss: item.totalLoss.toFixed(2),
                // totalProfitLoss: item.totalProfitAndLoss.toFixed(2),
                totalProfit: (
                    <span style={totalProfitStyle}>{item.totalProfit.toFixed(2)}</span>
                ),
                totalLoss: (
                    <span style={totalLossStyle}>{item.totalLoss.toFixed(2)}</span>
                ),
                totalProfitLoss: (
                    <span style={totalProfitLossStyle}>{item.totalProfitAndLoss.toFixed(2)}</span>
                ),
            });
        });

        return data;
    };

    return (
        <div>
            <MetaData title="Order Summary" />
            <div className="row loader-parent">
                <div className="col-12 col-md-2">
                    <Sidebar isActive={isActive} setIsActive={setIsActive} />
                </div>

                <div className="col-12 col-md-10 smalldevice-space-summary loader-parent mt-0">
                    <h1 className="mb-4 admin-dashboard-x">Margin Report</h1>
                    <div className="button-container mb-2">
                        <span
                            className="order-summary-back-button"
                            onClick={() => navigate(-1)}
                        >
                            <ArrowBackIcon fontSize="small" />
                            <span>Back</span>
                        </span>
                    </div>

                    <div className="paymentlist-container">
                        <div className="paymentlist-date-filter">
                            <div className="start-date">
                                <label htmlFor="startDate">Start Date:</label>
                                <input
                                    type="date"
                                    value={inputStartDate}
                                    onChange={handleStartDateChange}
                                    className="paymentlist-form-control"
                                />
                            </div>
                            <div className="end-date">
                                <label htmlFor="endDate">End Date:</label>
                                <input
                                    type="date"
                                    value={inputEndDate}
                                    onChange={handleEndDateChange}
                                    className="paymentlist-form-control"
                                />
                            </div>
                        </div>
                        <div className="filter-status">
                            <label htmlFor="selectedProduct">Selected Product: <strong>{decodedProduct}</strong></label>

                        </div>

                    </div>


                    <Fragment>
                        {dateProductloading ? (<div className="container loader-loading-center">
                            <Loader />
                        </div>) :
                            <div className='mdb-table' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <MDBDataTable
                                    data={setOrders()}
                                    bordered
                                    hover
                                    className="px-3 product-table"
                                    noBottomColumns
                                />
                            </div>
                        }
                    </Fragment>
                </div>
            </div>
        </div>
    );
};

export default ProductDatePage;