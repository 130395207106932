import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadUser, updateProfile } from '../../actions/userActions';
import LoaderButton from "./LoaderButton";

const UserDataModal = ({ show, closeModal }) => {

    const { user, isAuthenticated, profileupdateloading,isUpdated } = useSelector(state => state.authState);
    const dispatch = useDispatch();
    const [userData, setUserData] = useState({
        name: "",
        gender: "",
    });
    const [isUserExist, setIsUserExist] = useState(false)

    const handleInputChange = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("name", userData.name);
        formData.append("gender", userData.gender);

        dispatch(updateProfile(formData));
        dispatch(loadUser())
        // closeModal(); 
        setIsUserExist(true)
    };

    useEffect(() => {
        if (isAuthenticated && user?.name && isUserExist && isUpdated ) {
            closeModal();
        }

    }, [isUserExist,isUpdated])

    if (!show) return null; // Show modal only if the user is new

    return (
        <div className="usermodal-overlay">
            <div className="usermodal-container">
                <div className="usermodal-content">
                    <div className="usermodal-header">
                        <h5 className="usermodal-title">User Information</h5>
                        {/* <button type="button" className="userclose-button" onClick={closeModal}>
              &times;
            </button> */}
                    </div>
                    <div className="usermodal-body">
                        <form onSubmit={handleFormSubmit}>
                            <div className="form-group">
                                <label>Name</label>
                                <input
                                    type="text"
                                    name="name"
                                    className="form-control"
                                    value={userData.name}
                                    onChange={handleInputChange}
                                    maxLength={30}
                                    minLength={3}
                                    required
                                />
                            </div>
                            {/* <div className="form-group">
                <label>Phone Number/Email</label>
                <input
                  type="text"
                  name="phone"
                  className="form-control"
                  value={user?.email || user?.mobile}
                  readOnly               
                   />
              </div> */}
                            {/* <div className="form-group">
                <label>Date of Birth</label>
                <input
                  type="date"
                  name="dob"
                  className="form-control"
                  value={formData.dob}
                  onChange={handleInputChange}
                  required
                />
              </div> */}
                            <div className="form-group">
                                <label>Gender</label>
                                <select
                                    name="gender"
                                    className="form-control"
                                    value={userData.gender}
                                    onChange={handleInputChange}
                                    required
                                >
                                    <option value="">Select Gender</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    {/* <option value="Other">Other</option> */}
                                </select>
                            </div>

                            <button type="submit" className="userbtn-submit" disabled={profileupdateloading}>
                                {profileupdateloading ? <LoaderButton fullPage={false} size={20} /> : (
                                    <span> Submit </span>
                                )
                                }
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserDataModal;
