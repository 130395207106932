// import { createAsyncThunk } from '@reduxjs/toolkit';
// import {addCartItemRequest, addCartItemSuccess} from '../slices/cartSlice';
// import axios from 'axios'

// export const addCartItem = createAsyncThunk('post/orderItem', async({productId, quantity, productWeight},{dispatch}) => {
//     try {
//         dispatch(addCartItemRequest())
//         const {data } = await axios.get(`/api/v1/product/${productId}`)
//         dispatch(addCartItemSuccess({
//             product: data?.product._id,
//             name: data?.product?.englishName,
//             tamilname:data?.product?.tamilName,
//             category:data?.product?.category,
//             price: data?.product?.price,
//             image: data?.product?.images[0]?.image,
//             quantity,
//             measurement:data?.product?.measurement,
//             range: data?.product?.range,
//             productWeight,
//             stocks: data?.product?.stocks,
//             maximumQuantity:data?.product?.maximumQuantity,
//             finalPrice:data?.product?.finalPrice,
//             offer:data?.product?.offer,
//         }))
//     } catch (error) {
        
//     }
// })

// cartActions.js
import axios from 'axios';
import { 
  addCartRequest, 
  addCartSuccess, 
  addCartFail, 
  getCartRequest, 
  getCartSuccess, 
  getCartFail, 
  updateCartRequest, 
  updateCartSuccess, 
  updateCartFail,
  updateCartProductRequest, 
  updateCartProductSuccess, 
  updateCartProductFail,
  removeCartItemRequest,
  removeCartItemSuccess,
  removeCartItemFail,
  removeCartAllRequest,
  removeCartAllSuccess,
  removeCartAllFail,
  clearRemoveAllCart, 
} from '../slices/cartSlice';

// Add a product to the cart
export const addToCart = ({userId, productId, quantity, productWeight}) => async (dispatch) => {
  try {
    dispatch(addCartRequest());
    const { data } = await axios.post('/api/v1/cart', { userId, productId, quantity, productWeight });
    dispatch(addCartSuccess(data));
  } catch (error) {
    dispatch(addCartFail(error.response?.data?.message || 'Error adding product to cart'));
  }
};

// Get all cart items for a user
export const getCart = ({userId}) => async (dispatch) => {
  try {
    dispatch(getCartRequest());
    const { data } = await axios.get(`/api/v1/cart/${userId}`);
    dispatch(getCartSuccess(data.cartItems));
  } catch (error) {
    dispatch(getCartFail(error.response?.data?.message || 'Error fetching cart items'));
  }
};

export const updateCartProduct = ({ userId, productId, quantity, productWeight }) => async (dispatch) => {
    try {
        dispatch(updateCartProductRequest());
        // API request to update a specific cart item
        const { data } = await axios.put(`/api/v1/cart/product/update/${userId}/${productId}`, { quantity, productWeight });
        // Dispatch success action with updated cart item
        dispatch(updateCartProductSuccess(data));
      } catch (error) {
        dispatch(updateCartProductFail(error.response?.data?.message || 'Error updating cart item'));
      }
    
  };

// Update all cart items with the latest price
export const updateCart = ({userId}) => async (dispatch) => {
  try {
    dispatch(updateCartRequest());
    const { data } = await axios.put(`/api/v1/cart/update/${userId}`);
    dispatch(updateCartSuccess(data));
  } catch (error) {
    dispatch(updateCartFail(error.response?.data?.message || 'Error updating cart items'));
  }
};

export const removeFromCart = ({userId, productId}) => async (dispatch) => {
    try {
      dispatch(removeCartItemRequest());
      const { data } = await axios.delete(`/api/v1/cart/${userId}/${productId}`);
      dispatch(removeCartItemSuccess(data));  // Updated cart items after removal
    } catch (error) {
      dispatch(removeCartItemFail(error.response?.data?.message || 'Error removing item from cart'));
    }
  };

  export const removeAllFromCart = ({userId}) => async (dispatch) => {
    try {
      dispatch(removeCartAllRequest());
      const { data } = await axios.put(`/api/v1/cart/removeall/${userId}`);
      dispatch(removeCartAllSuccess(data));  // Updated cart items after removal
    } catch (error) {
      dispatch(removeCartAllFail(error.response?.data?.message || 'Error removing item from cart'));
    }
  };
