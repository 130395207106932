import React, { useState } from 'react'
import Sidebar from './Sidebar'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from "react";
import { getAdminProducts } from "../../actions/productsActions";
import { getUsers, loadUser } from "../../actions/userActions";
import { adminOrders as adminOrdersAction } from '../../actions/orderActions'
import { Link, useLocation } from 'react-router-dom'
import { getEnquiryDetails } from '../../actions/enquiryActions';
import LoaderButton from '../Layouts/LoaderButton';
import MetaData from '../Layouts/MetaData';


const Dashboard = ({ isActive, setIsActive }) => {

  const { products } = useSelector(state => state.productsState);
  const { enquiry } = useSelector(state => state.enquiryState);
  const { users } = useSelector(state => state.userState);
  const { adminOrders } = useSelector(state => state.orderState);
  const dispatch = useDispatch();
  const { user} = useSelector(state => state.authState);

  // const currentDate = new Date();
  // const formattedCurrentDate = currentDate.toISOString().split('T')[0];
  // const [startDate, setStartDate] = useState(formattedCurrentDate);
  // const [endDate, setEndDate] = useState(formattedCurrentDate);
  const currentDate = new Date(); // Get the current date
const formattedCurrentDate = currentDate.toISOString().split('T')[0]; // Format current date

// Calculate one month ago
const oneMonthAgo = new Date();
oneMonthAgo.setMonth(currentDate.getMonth() - 1); // Subtract one month
const formattedOneMonthAgo = oneMonthAgo.toISOString().split('T')[0]; // Format one month ago date

const [startDate, setStartDate] = useState(formattedOneMonthAgo); // Initialize to one month ago
const [endDate, setEndDate] = useState(formattedCurrentDate); // Initialize to today
  
  let totalAmount = 0;
  if (adminOrders && adminOrders.length > 0) {
    adminOrders.forEach(order => {
      if (order.paymentStatus === 'CHARGED')
        totalAmount += order.totalPrice
    })
  }


  useEffect(() => {
    if (!enquiry) {
      dispatch(getEnquiryDetails());
    }

  }, [enquiry])

  useEffect(() => {
    if (!adminOrders) {
      dispatch(adminOrdersAction({startDate,endDate}));
    }
  }, [adminOrders])

  useEffect(() => {
    if (!users) {
      dispatch(getUsers());
    }

  }, [users])

  useEffect(() => {
    if (!products) {
      dispatch(getAdminProducts());
    }

  }, [products])

  return (
    
    <div >
      <MetaData
        title="Admin Dashboard"
        description="Access a comprehensive overview of your eCommerce store, including order statistics, sales performance, and user activity."
      />

      <div className="row" style={{ margin: '10px' }}>
        <div className="col-md-2 col-12">
          <div style={{ display: 'flex', flexDirection: 'row', position: 'fixed', top: '0px', zIndex: 99999, backgroundColor: '#fff', minWidth: '100%' }}>
            <Sidebar isActive={isActive} setIsActive={setIsActive} />
          </div>
        </div>
        <div className="col-md-10 col-12 smalldevice-space-dashboard ">

          <h1 className="mb-4 admin-dashboard-x">Dashboard</h1>
          {/* Total Amount Card */}
          {user && user.role === "admin" ? (
            <div className="row" >
              <div className="col-12 mb-3">
                <div className="card text-white o-hidden h-100" style={{ backgroundColor: '#02441E' }}>
                  <div className="card-body">
                    <div className="text-center card-font-size">
                      Total Amount<br />
                      <b>Rs.{parseFloat(totalAmount).toFixed(2)}</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {/* Other Cards */}
          <div className="row " style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
            <div className="col-lg-3 col-md-3 col-6 mb-3">
              <div className="card text-white o-hidden h-100" style={{ backgroundColor: '#FED235' }}>
                <div className="card-body">
                  <div className="text-center card-font-size">
                    Products<br />
                    <b>{products && products.length ? products.length : <LoaderButton fullPage={false} size={20} />}</b>
                  </div>
                </div>
                <Link className="card-footer text-white clearfix small z-1" to="/admin/products">
                  <span className="float-left">View Details</span>
                  <span className="float-right">
                    {/* <i className="fa fa-angle-right"></i> */}
                  </span>
                </Link>
              </div>
            </div>

            <div className="col-lg-3 col-md-3 col-6 mb-3">
              <div className="card text-white o-hidden h-100" style={{ backgroundColor: '#02441E' }}>
                <div className="card-body">
                  <div className="text-center card-font-size">
                    Orders<br />
                    <b>{adminOrders && adminOrders.length ? adminOrders.length : <LoaderButton fullPage={false} size={20} />}</b>
                  </div>
                </div>
                <Link className="card-footer text-white clearfix small z-1" to="/admin/allorders">
                  <span className="float-left">View Details</span>
                  <span className="float-right">
                    {/* <i className="fa fa-angle-right"></i> */}
                  </span>
                </Link>
              </div>
            </div>

            <div className="col-lg-3 col-md-3 col-6 mb-3">
              <div className="card text-white o-hidden h-100" style={{ backgroundColor: '#FED235' }}>
                <div className="card-body">
                  <div className="text-center card-font-size">
                    Users<br />
                    <b>{users && users.length ? users.length : <LoaderButton fullPage={false} size={20} />}</b>
                  </div>
                </div>
                <Link className="card-footer text-white clearfix small z-1" to="/admin/users">
                  <span className="float-left">View Details</span>
                  <span className="float-right">
                    {/* <i className="fa fa-angle-right"></i> */}
                  </span>
                </Link>
              </div>
            </div>

            <div className="col-lg-3 col-md-3 col-6 mb-3">
              <div className="card text-white o-hidden h-100" style={{ backgroundColor: '#02441E' }}>
                <div className="card-body">
                  <div className="text-center card-font-size">
                    Enquiry<br />
                    <b>{enquiry && enquiry.length ? enquiry.length : <LoaderButton fullPage={false} size={20} />}</b>
                  </div>
                </div>
                <Link className="card-footer text-white clearfix small z-1" to="/admin/getenquiry">
                  <span className="float-left">View Details</span>
                  <span className="float-right">
                    {/* <i className="fa fa-angle-right"></i> */}
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  )
}

export default Dashboard
