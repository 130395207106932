
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrderSummary } from '../../actions/orderActions';
import Loader from '../Layouts/Loader';
import MetaData from '../Layouts/MetaData';
import Sidebar from '../admin/Sidebar';
import { useLocation, useNavigate } from 'react-router-dom';
import { getMeasurements } from '../../actions/measurementActions';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";


const OrderSummary = ({ isActive, setIsActive }) => {
    const dispatch = useDispatch();
    const { loading, orderSummary, error } = useSelector((state) => state.orderState);
    const location = useLocation();
    const { getmeasurement } = useSelector(state => state.measurementState);
    const navigate = useNavigate();
    const currentDate = new Date().toISOString().split('T')[0];
    const [date, setDate] = useState(currentDate);
    useEffect(() => {
        dispatch(fetchOrderSummary(date));
    }, [date, dispatch]);

    const isOrderSummaryArray = Array.isArray(orderSummary);

    useEffect(() => {
        if (!getmeasurement) {
            dispatch(getMeasurements())
        }
    }, [getmeasurement])
    const totalByMeasurement = {};
    const groupedOrderSummary = {};

    getmeasurement && getmeasurement.forEach((measurement) => {
        totalByMeasurement[measurement.measurement] = 0;
        groupedOrderSummary[measurement.measurement] = [];
    });
    if (isOrderSummaryArray) {
        orderSummary && orderSummary.forEach((item) => {
            const { productName, tamilname, totalWeight, totalPrice } = item;
            if (totalWeight) {
                const [weightValue, unit] = totalWeight.trim().split(/\s+/);
                const weight = parseFloat(weightValue) || 0;

                if (totalByMeasurement.hasOwnProperty(unit)) {
                    groupedOrderSummary[unit].push({ productName, tamilname, totalWeight, totalPrice });
                    totalByMeasurement[unit] += weight;
                } else {
                    console.warn(`Unexpected unit: '${unit}' in totalWeight: '${totalWeight}'`);
                }
            } else {
                console.warn("Missing totalWeight for item:", item);
            }
        });
    }

    // const handleDownload = () => {
    //     if (!isOrderSummaryArray || orderSummary.length === 0) {
    //         alert('No data available to download');
    //         return;
    //     }

    //     // Get the current date in the desired format
    //     const currentDate = new Date();
    //     const formattedDate = `${currentDate.getDate()}/${
    //         currentDate.toLocaleString('default', { month: 'short' })
    //     }/${currentDate.getFullYear()}`;

    //     // Format and sort data for Excel
    //     const formattedData = orderSummary
    //         .map((item, index) => ({
    //             'S.NO': index + 1,
    //             'REF': item.category || '',
    //             'PRODUCT': item.productName || '',
    //             'PRODUCT NAME': item.tamilname || '',
    //             'Total Order': item.totalWeight || '',
    //         }))
    //         .sort((a, b) => a['PRODUCT'].localeCompare(b['PRODUCT']));

    //     // Create a worksheet and workbook
    //     const worksheet = XLSX.utils.json_to_sheet([]);

    //     // Add the date at the top-right corner
    //     XLSX.utils.sheet_add_aoa(worksheet, [[`Date: ${formattedDate}`]], { origin: 'D1' });

    //     // Append the formatted data below the header
    //     XLSX.utils.sheet_add_json(worksheet, formattedData, { origin: 'A3' });

    //     const workbook = XLSX.utils.book_new();
    //     XLSX.utils.book_append_sheet(workbook, worksheet, 'Order Summary');

    //     // Download the file
    //     XLSX.writeFile(workbook, `Order_Summary_${formattedDate}.xlsx`);
    // };





    // const handleDownload = async () => {
    //     if (!isOrderSummaryArray || orderSummary.length === 0) {
    //         alert("No data available to download");
    //         return;
    //     }

    //     // Extract the order date from the first item in the orderSummary array
    //     const orderDescription = orderSummary[0].orderDescription;
    //     const orderDateMatch = orderDescription.match(/\d{2}\/[A-Za-z]{3}\/\d{4}/); // Extracts the date in the format dd/Mon/yyyy
    //     const formattedDate = orderDateMatch ? orderDateMatch[0] : 'Date not available';

    //     // Format the data for Excel and ensure it starts from S.No = 1 and is sorted
    //     const formattedData = orderSummary
    //         .map((item, index) => ({
    //             SNo: index + 1,  // Start from 1
    //             REF: item.category || "",
    //             PRODUCT: item.productName || "",
    //             PRODUCT_NAME: item.tamilname || "",
    //             Total_Order: item.totalWeight || "",
    //         })).sort((a, b) => a.REF.localeCompare(b.REF));

    //     // Create a new workbook and worksheet
    //     const workbook = new ExcelJS.Workbook();
    //     const worksheet = workbook.addWorksheet("Order Summary");

    //     // Merge cells in the first row for the date (across columns A to E)
    //     worksheet.mergeCells('A1:E1');
    //     worksheet.getCell("A1").value = `${formattedDate}`;
    //     const dateCell = worksheet.getCell("A1");

    //     // Center align the order date within the merged cell
    //     dateCell.alignment = { horizontal: "center", vertical: "middle" };
    //     dateCell.font = { bold: true };

    //     // Now, proceed to add headers in the second row (manual addition)
    //     worksheet.addRow([]);  // Add an empty row between date and header

    //     // Add headers manually in the second row
    //     worksheet.getCell("A2").value = "S.No";
    //     worksheet.getCell("B2").value = "REF";
    //     worksheet.getCell("C2").value = "PRODUCT";
    //     worksheet.getCell("D2").value = "PRODUCT NAME";
    //     worksheet.getCell("E2").value = "Total Order";

    //     // Set the header row font to bold
    //     worksheet.getRow(2).font = { bold: true };

    //     // Set the column widths manually after adding the header row
    //     worksheet.getColumn(1).width = 5;  // S.No
    //     worksheet.getColumn(2).width = 15; // REF
    //     worksheet.getColumn(3).width = 25; // PRODUCT
    //     worksheet.getColumn(4).width = 35; // PRODUCT NAME
    //     worksheet.getColumn(5).width = 10; // Total Order

    //     // Add data rows starting from row 3
    //     formattedData.forEach((item) => {
    //         worksheet.addRow([item.SNo, item.REF, item.PRODUCT, item.PRODUCT_NAME, item.Total_Order]);
    //     });

    //     // Apply borders and alignment to all rows
    //     worksheet.eachRow((row, rowNumber) => {
    //         row.eachCell((cell) => {
    //             cell.border = {
    //                 top: { style: "thin" },
    //                 left: { style: "thin" },
    //                 bottom: { style: "thin" },
    //                 right: { style: "thin" },
    //             };
    //             // Align text to left for all cells except for the date row
    //             if (rowNumber > 1) {  // Skip the first row where the date is placed
    //                 cell.alignment = { horizontal: "left" };
    //             }
    //         });
    //     });

    //     // Generate the Excel file
    //     const buffer = await workbook.xlsx.writeBuffer();

    //     // Save the file
    //     saveAs(new Blob([buffer]), `Order_Summary_${formattedDate}.xlsx`);
    // };

    // const handleDownload = async () => {
    //     if (!isOrderSummaryArray || orderSummary.length === 0) {
    //         alert("No data available to download");
    //         return;
    //     }
    
    //     // Extract the order date from the first item in the orderSummary array
    //     const orderDescription = orderSummary[0].orderDescription;
    //     const orderDateMatch = orderDescription.match(/\d{2}\/[A-Za-z]{3}\/\d{4}/); // Extracts the date in the format dd/Mon/yyyy
    //     const formattedDate = orderDateMatch ? orderDateMatch[0] : 'Date not available';
    
    //     // Format the data for Excel and ensure it starts from S.No = 1 and is sorted alphabetically by REF and then PRODUCT
    //     const formattedData = orderSummary
    //         .map((item, index) => ({
    //             SNo: index + 1,  // Start from 1
    //             REF: item.category || "",
    //             PRODUCT: item.productName || "",
    //             PRODUCT_NAME: item.tamilname || "",
    //             Total_Order: item.totalWeight || "",
    //         }))
    //         .sort((a, b) => {
    //             if (a.REF === b.REF) {
    //                 return a.PRODUCT.localeCompare(b.PRODUCT);
    //             }
    //             return a.REF.localeCompare(b.REF);
    //         }); // Sort by REF first, then PRODUCT alphabetically
    
    //     // Create a new workbook and worksheet
    //     const workbook = new ExcelJS.Workbook();
    //     const worksheet = workbook.addWorksheet("Order Summary");
    
    //     // Merge cells in the first row for the date (across columns A to E)
    //     worksheet.mergeCells('A1:E1');
    //     worksheet.getCell("A1").value = `${formattedDate}`;
    //     const dateCell = worksheet.getCell("A1");
    
    //     // Center align the order date within the merged cell
    //     dateCell.alignment = { horizontal: "center", vertical: "middle" };
    //     dateCell.font = { bold: true };
    
    //     // Now, proceed to add headers in the second row (manual addition)
    //     worksheet.addRow([]);  // Add an empty row between date and header
    
    //     // Add headers manually in the second row
    //     worksheet.getCell("A2").value = "S.No";
    //     worksheet.getCell("B2").value = "REF";
    //     worksheet.getCell("C2").value = "PRODUCT";
    //     worksheet.getCell("D2").value = "PRODUCT NAME";
    //     worksheet.getCell("E2").value = "Total Order";
    
    //     // Set the header row font to bold
    //     worksheet.getRow(2).font = { bold: true };
    
    //     // Set the column widths manually after adding the header row
    //     worksheet.getColumn(1).width = 5;  // S.No
    //     worksheet.getColumn(2).width = 15; // REF
    //     worksheet.getColumn(3).width = 25; // PRODUCT
    //     worksheet.getColumn(4).width = 35; // PRODUCT NAME
    //     worksheet.getColumn(5).width = 10; // Total Order
    
    //     // Add data rows starting from row 3
    //     formattedData.forEach((item) => {
    //         worksheet.addRow([item.SNo, item.REF, item.PRODUCT, item.PRODUCT_NAME, item.Total_Order]);
    //     });
    
    //     // Apply borders and alignment to all rows
    //     worksheet.eachRow((row, rowNumber) => {
    //         row.eachCell((cell) => {
    //             cell.border = {
    //                 top: { style: "thin" },
    //                 left: { style: "thin" },
    //                 bottom: { style: "thin" },
    //                 right: { style: "thin" },
    //             };
    //             // Align text to left for all cells except for the date row
    //             if (rowNumber > 1) {  // Skip the first row where the date is placed
    //                 cell.alignment = { horizontal: "left" };
    //             }
    //         });
    //     });
    
    //     // Generate the Excel file
    //     const buffer = await workbook.xlsx.writeBuffer();
    
    //     // Save the file
    //     saveAs(new Blob([buffer]), `Order_Summary_${formattedDate}.xlsx`);
    // };

    const handleDownload = async () => {
        if (!isOrderSummaryArray || orderSummary.length === 0) {
            alert("No data available to download");
            return;
        }
    
        // Extract the order date from the first item in the orderSummary array
        const orderDescription = orderSummary[0].orderDescription;
        const orderDateMatch = orderDescription.match(/\d{2}\/[A-Za-z]{3}\/\d{4}/); // Extracts the date in the format dd/Mon/yyyy
        const formattedDate = orderDateMatch ? orderDateMatch[0] : 'Date not available';
    
        // Format the data for Excel and ensure it is sorted alphabetically by REF and PRODUCT
        let formattedData = orderSummary
            .map((item) => ({
                REF: item.category || "",
                PRODUCT: item.productName || "",
                PRODUCT_NAME: item.tamilname || "",
                Total_Order: item.totalWeight || "",
            }))
            .sort((a, b) => {
                if (a.REF === b.REF) {
                    return a.PRODUCT.localeCompare(b.PRODUCT);
                }
                return a.REF.localeCompare(b.REF);
            });
    
        // Reassign SNo after sorting
        formattedData = formattedData.map((item, index) => ({
            SNo: index + 1, // Assign SNo starting from 1 after sorting
            ...item,
        }));
    
        // Create a new workbook and worksheet
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Order Summary");
    
        // Merge cells in the first row for the date (across columns A to E)
        worksheet.mergeCells('A1:E1');
        worksheet.getCell("A1").value = `${formattedDate}`;
        const dateCell = worksheet.getCell("A1");
    
        // Center align the order date within the merged cell
        dateCell.alignment = { horizontal: "center", vertical: "middle" };
        dateCell.font = { bold: true };
    
        // Add headers in the second row
        worksheet.addRow([]);  // Add an empty row between date and header
        worksheet.getCell("A2").value = "S.No";
        worksheet.getCell("B2").value = "REF";
        worksheet.getCell("C2").value = "PRODUCT";
        worksheet.getCell("D2").value = "PRODUCT NAME";
        worksheet.getCell("E2").value = "Total Order";
    
        // Set the header row font to bold
        worksheet.getRow(2).font = { bold: true };
    
        // Set the column widths manually
        worksheet.getColumn(1).width = 5;  // S.No
        worksheet.getColumn(2).width = 15; // REF
        worksheet.getColumn(3).width = 25; // PRODUCT
        worksheet.getColumn(4).width = 35; // PRODUCT NAME
        worksheet.getColumn(5).width = 10; // Total Order
    
        // Add data rows starting from row 3
        formattedData.forEach((item) => {
            worksheet.addRow([item.SNo, item.REF, item.PRODUCT, item.PRODUCT_NAME, item.Total_Order]);
        });
    
        // Apply borders and alignment to all rows
        worksheet.eachRow((row, rowNumber) => {
            row.eachCell((cell) => {
                cell.border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };
                // Align text to left for all cells except for the date row
                if (rowNumber > 1) {  // Skip the first row where the date is placed
                    cell.alignment = { horizontal: "left" };
                }
            });
        });
    
        // Generate the Excel file
        const buffer = await workbook.xlsx.writeBuffer();
    
        // Save the file
        saveAs(new Blob([buffer]), `Order_Summary_${formattedDate}.xlsx`);
    };
    
    
    





    return (

        <div>
            <MetaData title="Order Summary" description="Review your order summary, including product details, pricing, and shipping information before confirming your purchase." />

            <div className="row loader-parent">
                <div className="col-12 col-md-2">
                    <Sidebar isActive={isActive} setIsActive={setIsActive} />
                </div>

                <div className="col-12 col-md-10 smalldevice-space-summary loader-parent">
                    <h1 className='admin-dashboard-x mb-4'>Total Purchase Order</h1>
                    <div className='button-container'>
                        {/* <div className=""> */}
                        <span className="order-summary-back-button" onClick={() => navigate(-1)}>
                            <ArrowBackIcon fontSize="small" />
                            <span>Back</span>
                        </span>

                        {/* </div> */}
                        <button
                            className="btn download-button"
                            onClick={handleDownload}
                        >
                            Download Purchase Order
                        </button>
                    </div>

                    <input
                        type="date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        className="form-control mb-3 date-input"
                    />
                    {loading ? (
                        <div className="container loader-loading-center">
                            <Loader />
                        </div>
                    ) : error ? (
                        <p className="text-danger">{error}</p>
                    ) : (
                        <div className="container ordersummary-detail-container">
                            {isOrderSummaryArray && orderSummary.length === 0 ? (
                                <p>No orders found for the selected date.</p>
                            ) : (
                                <div className="updatetable-responsive">
                                    <table className="updatetable updatetable-bordered">
                                        <thead>
                                            <tr>
                                                <th>S.NO</th>
                                                <th>Product Name</th>
                                                <th>Total Weight</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {getmeasurement?.map((measurement) => (
                                                <>
                                                    {groupedOrderSummary[measurement.measurement].length > 0 && (
                                                        <tr key={`${measurement.measurement}-header`}>
                                                            <td colSpan="3" style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                                                Quantity in {measurement.measurement}
                                                            </td>
                                                        </tr>
                                                    )}

                                                    {groupedOrderSummary[measurement.measurement].sort((a, b) => a.productName.localeCompare(b.productName)).map(({ productName, tamilname, totalWeight }, index) => (
                                                        <tr key={`${measurement.measurement}-${index}`}>
                                                            <td>{index + 1}</td>
                                                            <td>{productName} / {tamilname}</td>
                                                            <td>{totalWeight}</td>
                                                        </tr>
                                                    ))}

                                                    {groupedOrderSummary[measurement.measurement].length > 0 && (
                                                        <tr key={`${measurement.measurement}-total`}>
                                                            <td colSpan="2" style={{ textAlign: 'right' }}><strong>Total {measurement.measurement}</strong></td>
                                                            <td><strong>{totalByMeasurement[measurement.measurement].toFixed(2)} {measurement.measurement}</strong></td>
                                                        </tr>
                                                    )}
                                                </>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default OrderSummary;
