import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import Sidebar from "../admin/Sidebar";
import { fetchDateWiseReport, getCategoryByDate } from "../../actions/marginAction";
import MetaData from "../Layouts/MetaData";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Loader from "../Layouts/Loader";
import { clearCategoryReport, clearDateReport } from "../../slices/marginReportSlice";
import { Slide, toast } from 'react-toastify';

const DateReportPage = ({ isActive, setIsActive }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { report, dateloading, dateerror, categoryReport, categoryerror, categoryloading } = useSelector((state) => state.marginReportState);

    const currentDate = new Date();
    const formattedCurrentDate = currentDate.toISOString().split("T")[0];
    const todayDate = new Date().toISOString().split("T")[0];

    const [inputStartDate, setInputStartDate] = useState(todayDate ? todayDate : "");
    const [inputEndDate, setInputEndDate] = useState(todayDate ? todayDate : "");
    const [startDate, setStartDate] = useState(formattedCurrentDate);
    const [endDate, setEndDate] = useState(formattedCurrentDate);
    const [selectedCategory, setSelectedCategory] = useState("By Date");


    useEffect(() => {
        if ((startDate || endDate) && selectedCategory === 'By Date') {
            dispatch(fetchDateWiseReport({ startDate, endDate }));
            // dispatch(getCategoryByDate({ startDate, endDate}))
        }
    }, [startDate, endDate, selectedCategory, dispatch]);



    useEffect(() => {
        if ((startDate || endDate) && selectedCategory === 'By Category') {
            // dispatch(fetchDateWiseReport({ startDate, endDate }));
            dispatch(getCategoryByDate({ startDate, endDate }))
        }
    }, [startDate, endDate, selectedCategory, dispatch]);

    // Function to format date from yyyy-mm-dd to dd-mm-yyyy
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if day is less than 10
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month (0-indexed)
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };


    // Function to set the "By Date" table data
    const setOrders = () => {
        const data = {
            columns: [
                { label: "S.No", field: "s_no", sort: "disabled" },
                { label: "Date", field: "date", sort: "disabled" },
                { label: "Total Orders", field: "totalOrders", sort: "disabled" },
                { label: "Purchase Cost", field: "spentAmount", sort: "disabled" },
                { label: "Sales Revenue", field: "dispatchAmount", sort: "disabled" },
                { label: "Total Profit", field: "totalProfit", sort: "disabled" },
                { label: "Total Loss", field: "totalLoss", sort: "disabled" },
                { label: "Total Profit/Loss", field: "totalProfitLoss", sort: "disabled" },
            ],
            rows: [],
        };

        if (report && report.report && Array.isArray(report.report)) {
            report.report.forEach((item, index) => {
                const totalProfitStyle = item.totalProfit > 0 ? { color: "green" } : item.totalProfit < 0 ? { color: "red" } : { color: "black" };
                const totalLossStyle = item.totalLoss < 0 ? { color: "red" } : item.totalLoss === 0 ? { color: "black" } : { color: "black" };
                const totalProfitLossStyle = item.totalPandL > 0 ? { color: "green" } : item.totalPandL < 0 ? { color: "red" } : { color: "black" };
                const formattedDate = formatDate(item.date); // Format date to dd-mm-yyyy
                data.rows.push({
                    s_no: index + 1,
                    date: item?.date && (
                        <Link to={`/admin/productDetail/${item.date}`} state={{ report }} style={{ textDecoration: "none", color: "blue" }}>
                            {formattedDate}
                        </Link>
                    ),
                    totalOrders: item.totalOrders,
                    spentAmount: item.totalAmountSpent.toFixed(2),
                    dispatchAmount: item.totalDispatchAmount.toFixed(2),
                    // totalProfit: item.totalProfit.toFixed(2),
                    // totalLoss: item.totalLoss.toFixed(2),
                    // totalProfitLoss: item.totalPandL.toFixed(2),
                    totalProfit: (
                        <span style={totalProfitStyle}>{item.totalProfit.toFixed(2)}</span>
                    ),
                    totalLoss: (
                        <span style={totalLossStyle}>{item.totalLoss.toFixed(2)}</span>
                    ),
                    totalProfitLoss: (
                        <span style={totalProfitLossStyle}>{item.totalPandL.toFixed(2)}</span>
                    ),
                });
            });
        }

        return data;
    };

    useEffect(() => {
        if (dateerror) {
            toast.dismiss();
            setTimeout(() => {
                toast.error(dateerror, {
                    position: 'bottom-center',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onOpen: () => { dispatch(clearDateReport()) }
                });
            }, 300);
            return
        }
        if (categoryerror) {
            toast.dismiss();
            setTimeout(() => {
                toast.error(categoryerror, {
                    position: 'bottom-center',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onOpen: () => { dispatch(clearCategoryReport()) }
                });
            }, 300);
            return
        }

    }, [dispatch, dateerror, categoryerror])

    // Function to set the "By Category" table data
    const setCategoryOrders = () => {
        const data = {
            columns: [
                { label: "S.No", field: "s_no", sort: "disabled" },
                { label: "Category", field: "category", sort: "disabled" },
                { label: "Total Orders", field: "totalOrders", sort: "disabled" },
                { label: "Purchase Cost", field: "amountSpent", sort: "disabled" },
                { label: "Sales Revenue", field: "amountGot", sort: "disabled" },
                { label: "Total Profit", field: "totalProfit", sort: "disabled" },
                { label: "Total Loss", field: "totalLoss", sort: "disabled" },
                { label: "Total Profit/Loss", field: "totalProfitLoss", sort: "disabled" },

            ],
            rows: [],
        };

        categoryReport?.forEach((item, index) => {
            const totalProfitStyle = item.totalProfit > 0 ? { color: "green" } : item.totalProfit < 0 ? { color: "red" } : { color: "black" };
            const totalLossStyle = item.totalLoss < 0 ? { color: "red" } : item.totalLoss === 0 ? { color: "black" } : { color: "black" };
            const totalProfitLossStyle = item.totalProfitAndLoss > 0 ? { color: "green" } : item.totalProfitAndLoss < 0 ? { color: "red" } : { color: "black" };
            data.rows.push({
                s_no: index + 1,
                category: item && item.category && (
                    <Link to={`/admin/categoryDetailProduct/${item.category}/${startDate}/${endDate}`} state={{ categoryReport }} style={{ textDecoration: "none", color: "blue" }}>
                        {item.category}
                    </Link>
                ),
                totalOrders: item.totalOrderDocCount,
                amountSpent: item.totalAmountSpent.toFixed(2),
                amountGot: item.totalAmountgot.toFixed(2),
                // totalProfit: item.totalProfit.toFixed(2),
                // totalLoss: item.totalLoss.toFixed(2),
                // totalProfitLoss: item.totalProfitAndLoss.toFixed(2),
                totalProfit: (
                    <span style={totalProfitStyle}>{item.totalProfit.toFixed(2)}</span>
                ),
                totalLoss: (
                    <span style={totalLossStyle}>{item.totalLoss.toFixed(2)}</span>
                ),
                totalProfitLoss: (
                    <span style={totalProfitLossStyle}>{item.totalProfitAndLoss.toFixed(2)}</span>
                ),
            });
        });

        return data;
    };

    return (
        <div>
            <MetaData title="Order Summary" />
            <div className="row loader-parent">
                <div className="col-12 col-md-2">
                    <Sidebar isActive={isActive} setIsActive={setIsActive} />
                </div>

                <div className="col-12 col-md-10 smalldevice-space-summary loader-parent mt-0">
                    <h1 className="mb-4 admin-dashboard-x">Margin Report</h1>
                    <div className="button-container mb-2">
                        <span className="order-summary-back-button" onClick={() => navigate(-1)}>
                            <ArrowBackIcon fontSize="small" />
                            <span>Back</span>
                        </span>
                    </div>

                    <div className="paymentlist-container">
                        <div className="paymentlist-date-filter">
                            <div className="start-date">
                                <label htmlFor="startDate">
                                    Start Date: <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                    type="date"
                                    value={inputStartDate}
                                    onChange={(e) => {
                                        setStartDate(e.target.value);
                                        setInputStartDate(e.target.value);
                                    }}
                                    className="paymentlist-form-control"
                                />
                            </div>
                            <div className="end-date">
                                <label htmlFor="endDate">
                                    End Date: <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                    type="date"
                                    value={inputEndDate}
                                    onChange={(e) => {
                                        setEndDate(e.target.value);
                                        setInputEndDate(e.target.value);
                                    }}
                                    className="paymentlist-form-control"
                                />
                            </div>
                        </div>
                        <div className="filter-status">
                            <label htmlFor="selectedCategory">Filter:</label>
                            <select
                                value={selectedCategory}
                                onChange={(e) => setSelectedCategory(e.target.value)}
                                className="paymentlist-form-select"
                            >
                                <option value="By Date">By Date</option>
                                <option value="By Category">By Category</option>
                            </select>
                        </div>
                    </div>
                    {/* <Fragment>
                        {dateloading ? (
                            <div className="container loader-loading-center">
                                <Loader />
                            </div>
                        ) : (
                            <div className="mdb-table">
                                {selectedCategory === "By Date" ? (
                                    report && report.report && report.report.length > 0 ? (
                                        <MDBDataTable
                                            bordered
                                            hover
                                            className="px-3 product-table"
                                            data={setOrders()}
                                            noBottomColumns
                                        />
                                    ) : (
                                        <p>No records found for the selected date range.</p>
                                    )
                                ) : categoryloading ? (
                                    <div className="container loader-loading-center">
                                        <Loader />
                                    </div>
                                ) : (
                                    <MDBDataTable
                                        bordered
                                        hover
                                        className="px-3 product-table"
                                        data={setCategoryOrders()}
                                        noBottomColumns
                                    />
                                )}
                            </div>
                        )}
                    </Fragment> */}

                    <Fragment>
                        {dateloading ? (
                            <div className="container loader-loading-center">
                                <Loader />
                            </div>
                        ) : (
                            <div className="mdb-table">
                                {selectedCategory === "By Date" ? (
                                    report && report.report && report.report.length > 0 ? (
                                        <MDBDataTable
                                            bordered
                                            hover
                                            className="px-3 product-table"
                                            data={setOrders()}
                                            noBottomColumns
                                        />
                                    ) : (
                                        <p>No records found for the selected date range.</p>
                                    )
                                ) : categoryloading ? (
                                    // Use the same container styling for the second loader
                                    <div className="container loader-loading-center">
                                        <Loader />
                                    </div>
                                ) : (
                                    <MDBDataTable
                                        bordered
                                        hover
                                        className="px-3 product-table"
                                        data={setCategoryOrders()}
                                        noBottomColumns
                                    />
                                )}
                            </div>
                        )}
                    </Fragment>



                </div>
            </div>
        </div>
    );
};

export default DateReportPage;