import React, { useEffect, Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MDBDataTable } from 'mdbreact';
import { Button } from "react-bootstrap";
import { Slide, toast } from 'react-toastify';
import Sidebar from "../admin/Sidebar";
import Loader from '../Layouts/Loader';
import { getEnquiryDetails, deleteEnquiryDetails } from '../../actions/enquiryActions';
import { clearProductDeleted, clearError } from '../../slices/productSlice';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { clearEnquiryDeleted } from '../../slices/enquirySlice';
import MetaData from '../Layouts/MetaData';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const EnquiryRequest = ({ isActive, setIsActive }) => {
  const location = useLocation();
  const { isEnquiryDeleted, error, loading = true, enquiry } = useSelector(state => state.enquiryState);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [enquiryToDelete, setEnquiryToDelete] = useState(null);
  const navigate = useNavigate();

  const setEnquiryDetails = () => {

    const data = {
      columns: [
        {
          label: 'S.NO',
          field: 's_no',
          attributes: {
            'className': 'sno-column'
          }
        },
        {
          label: 'Name',
          field: 'name',
          attributes: {
            'className': 'name-column'
          }
        },
        {
          label: 'Email',
          field: 'email',
          attributes: {
            'className': 'email-column'
          }
        },
        {
          label: 'Mobile',
          field: 'mobile',
          attributes: {
            'className': 'mobile-column'
          }
        },
        {
          label: 'Message',
          field: 'messageData',
          attributes: {
            'className': 'message-column'
          }
        },
        {
          label: 'Date',
          field: 'date',
          attributes: {
            'className': 'date-column'
          }
        },
        {
          label: 'Actions',
          field: 'actions',
          attributes: {
            'className': 'actions-column'
          }
        }
      ],
      rows: []
    };



    // Sort orders by creation date (newest first)
    const sortedEnquiry = enquiry && [...enquiry].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    sortedEnquiry && sortedEnquiry.forEach((user, index) => {
      data.rows.push({
        s_no: index + 1,
        name: user.name,
        email: user.email,
        mobile: user.mobile,
        messageData: (
          <div style={{ position: 'relative', maxWidth: 'auto', wordBreak: 'break-word' }}>
            {user.messageData || 'N/A'}
          </div>
        ),
        date: new Date(user.createdAt).toLocaleString(),
        actions: (
          <Fragment>
            <Button onClick={() => handleDeleteClick(user._id)} className="btn btn-danger py-1 px-2 ml-2">
              <i className="fa fa-trash"></i>
            </Button>
          </Fragment>
        )
      })
    })


    return data;
  }

  useEffect(() => {
    if (error) {
      toast.dismiss();
      setTimeout(() => {
        toast.error(error, {
          position: 'bottom-center',
          type: 'error',
          autoClose: 700,
          transition: Slide,
          hideProgressBar: true,
          className: 'small-toast',
          onOpen: () => { dispatch(clearError()) }
        });
      }, 300);
      return
    }
    if (isEnquiryDeleted) {
      toast.dismiss();
      setTimeout(() => {
        toast.success('Enquiry Deleted Succesfully!', {
          position: 'bottom-center',
          type: 'success',
          autoClose: 700,
          transition: Slide,
          hideProgressBar: true,
          className: 'small-toast',
          onOpen: () => dispatch(clearEnquiryDeleted())
        });
      }, 300);
      return;
    }
  }, [dispatch, error, isEnquiryDeleted])

  useEffect(() => {
    if (!enquiry) {
      dispatch(getEnquiryDetails())
    }

  }, [enquiry])

  const handleDeleteClick = (id) => {
    setEnquiryToDelete(id);
    setShowModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteEnquiryDetails({ id: enquiryToDelete }));
    setShowModal(false);
  };

  const handleCancelDelete = () => {
    setShowModal(false);
  };


  return (
    <div>
      <MetaData
        title="Enquiry Request"
        description="Handle customer inquiries and requests efficiently, ensuring prompt responses and high customer satisfaction."
      />


      <div className="row loader-parent">
        <div className="col-12 col-md-2">
          <div style={{ display: 'flex', flexDirection: 'row', position: 'fixed', top: '0px', zIndex: 99999, backgroundColor: '#fff', minWidth: '100%' }}>
            <Sidebar isActive={isActive} setIsActive={setIsActive} />
          </div>
        </div>
        <div className="col-12 col-md-10 smalldevice-space loader-parent">
          <h1 className="mb-4 admin-dashboard-x">Enquiry List</h1>
          
          <div className="back-button" onClick={() => navigate(-1)}>
                        <ArrowBackIcon fontSize="small" />
                        <span>Back</span>
                    </div>

          <Fragment>
            {loading ? (<div className="container loader-loading-center">
              <Loader />
            </div>) :
              <div className='mdb-table' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <MDBDataTable
                  data={setEnquiryDetails()}
                  bordered
                  noBottomColumns
                  hover
                  className="px-3 product-table "
                />
              </div>
            }
          </Fragment>
        </div>

        {showModal && (
          <div className="modal" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Confirm Delete</h5>
                  <button type="button" className="close" onClick={handleCancelDelete}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p>Are you sure you want to delete this Enquiry?</p>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-danger" onClick={handleConfirmDelete}>OK</button>
                  <button type="button" className="btn btn-secondary" onClick={handleCancelDelete}>Cancel</button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default EnquiryRequest;
