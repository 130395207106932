import React, { Fragment, useEffect, useState } from 'react'
import MetaData from './Layouts/MetaData'
import { getProducts } from '../actions/productsActions'
import { useDispatch, useSelector } from 'react-redux'
import Loader from './Layouts/Loader'
import Product from './Product/Product'
import Search from './Layouts/Search'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CarouselLayout from './Layouts/CarouselLayout'
import { getCategories } from '../actions/categoryAction'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';



const AllDeals = ({onLoginClick}) => {
  const location = useLocation();
  const { category,type } = useParams();
  const { products, loading, error } = useSelector((state) => state.productsState);
  const [keyword, setKeyword] = useState("")
  const { getcategory } = useSelector((state) => state.categoryState);
  const dispatch = useDispatch();
  const navigate = useNavigate();
//   const filterCategories = products ? products.filter((product) => product.category === category ) : [];
const filterCategories = products
  ? products.filter((product) => 
      product.offer > 0 && 
      parseFloat(product.finalPrice) > 0
    )
  : [];
  const searchCategory = keyword ? filterCategories.filter((filterCategory) => filterCategory.englishName.toLowerCase().includes(keyword.toLowerCase())) : filterCategories;


  // Sort the filtered vegetables in ascending order by name
  const sortedCategories = searchCategory.sort((a, b) => a.englishName.localeCompare(b.englishName));

// useEffect(()=>{
//   if (!getcategory) {
//     dispatch(getCategories());
// }
// })

  return (
    <Fragment>
      {loading ? <Loader /> :
        <Fragment>
          <MetaData
            title="Fresh Vegetables"
            description="Discover a wide variety of fresh vegetables at our store. Search, filter, and explore organic and high-quality vegetables to add to your shopping cart."
          />

          {/* <div className="products_heading">{category && category}</div> */}
          <div className="products_heading">Deal of the Day</div>
          <div className="">
                    <span className="back-button" onClick={() => navigate(-1)}>
                        <ArrowBackIcon fontSize="small" />
                        <span>Back</span>
                    </span>

                </div>
          <div className="search-responsive col-12 col-md-6 mt-2 mt-md-0" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', minWidth: '100%', height: 'auto', flexDirection: 'column', margin: '0px', padding: '0px', overflowX: 'hidden' }}>
            <div style={{ display: 'flex', flexDirection: 'row', position: 'relative', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
              <Search keyword={keyword} setKeyword={setKeyword} />
            </div>

            {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', maxWidth: '100vw', height: 'auto', marginTop: '30px', overflowX: 'hidden' }}>
              <CarouselLayout category={category} type={type} deals={true}/>
            </div> */}
          </div>
          {
            sortedCategories.length === 0 ? (
              <div className="product-not-found">
                <img src="/images/not-found.jpg" alt="No Products Found" />
                <p>Product Not Found</p>
              </div>

            ) : (
              <section id="products" className="container">
                <div className="row">
                  <Product products={sortedCategories} category={category} onLoginClick={onLoginClick}/>

                </div>
              </section>
            )
          }

        </Fragment>
      }
    </Fragment>
  )
}

export default AllDeals