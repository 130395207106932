
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'
import Loader from './Layouts/Loader';
import MetaData from './Layouts/MetaData';
import { getCategories } from '../actions/categoryAction';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const LandingPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  // const { categoryItem } = location.state || {} ;
  const { category='Fresh' } = useParams();  
  const { products, loading, error } = useSelector((state) => state.productsState);
  const { getcategory } = useSelector((state) => state.categoryState);
  const navigate = useNavigate();
  const [categoryItem, setCategoryItem] = useState({})

const categories = [
  {
    _id: "1",
    name: "Fruits & Vegetables",
    type: "Fresh",
    image: "/images/jas-fruits.jpeg",
    category: "Fresh",
  },
  {
    _id: "2",
    name: "Monthly Groceries",
    type: "Groceries",
    image: "/images/jas-groceries.jpeg",
    category: "Groceries",
  },
];


useEffect(() => {
 
    const itemCategory = categories.find(item => item.category === 'Fresh');
    setCategoryItem(itemCategory);

}, []);

  useEffect(() => {
    if (!getcategory) {
      dispatch(getCategories())
    }

  }, [])

  const filteredCategories = getcategory?.filter(
    (item) => item.type === category
  );



  return (
    <div>

      <div className="products_heading">{categoryItem && categoryItem.name}</div>
      {/* <div className="back-button" onClick={() => navigate(-1)}>
                    <ArrowBackIcon fontSize="small" />
                    <span>Back</span>
                </div> */}
      <MetaData
        title="Jas Home"
        description="Discover a wide variety of fresh fruits,vegetables and keerai at our store. Search, filter, and explore organic and high-quality fruits, vegetables and keerai to add to your shopping cart."
      />
      <div className="container " style={{ marginTop: '60px' }}>

        {
          loading ? <Loader /> : (

            <div className="row d-flex justify-content-center">
              {filteredCategories && filteredCategories.map((categoryItem) => (
                <div key={categoryItem._id} className="col-6 col-xs-5 col-sm-6 col-md-4 col-lg-3 my-3 landingpage-category-card ">
                  <Link
                    to={`/categories/${categoryItem.category}/type/${categoryItem.type}`} // Dynamic link based on category name
                    state={{ category: categoryItem.category ,type: categoryItem.type }}
                    style={{ textDecoration: 'none' }}
                  >
                    <div className="card p-1 rounded">
                      <div className="d-flex justify-content-center align-items-center square-card">
                        <div className="card-content">
                        {categoryItem?.images && categoryItem?.images.length > 0 ? (
                          <img
                            className="card-img-top-vegetable"
                            src={categoryItem?.images[0]?.image} // Adjust if the image path differs
                            alt={categoryItem.name}
                          />
                        ) : (
                          <div className="card-img-top-vegetable">No Image</div>
                        )}
                        <div className="card-body d-flex flex-column">
                        <h3 className="card-title-vegetable">{categoryItem.name}</h3>
                      </div>
                      </div>
                        </div>
                       
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          )
        }

      </div>

    </div>
   
  );
};

export default LandingPage;