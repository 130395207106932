import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  porterRequest,
  porterSuccess,
  porterFail,
  porterOrderResponseRequest,
  porterOrderResponseSuccess,
  porterOrderResponseFail,
  porterCancelRequest,
  porterCancelSuccess,
  porterCancelFail,
  porterRemoveRequest,
  porterRemoveSuccess,
  porterRemoveFail,
  packedOrderRequest,
  packedOrderSuccess,
  packedOrderFail,
  getpackedOrderRequest,
  getpackedOrderSuccess,
  getpackedOrderFail,
  allpackedOrderRequest,
  allpackedOrderSuccess,
  allpackedOrderFail,
  refundRequest,
  refundSuccess,
  refundFail,
  updatepackedOrderRequest,
  updatepackedOrderSuccess,
  updatepackedOrderFail,

} from '../slices/porterSlice';


export const getporterOrder = createAsyncThunk('init/order/create', async ({ order_id }, { dispatch }) => {
  try {
    dispatch(porterRequest());
    const { data } = await axios.post('/api/v1/admin/porter/orders', { order_id }, { withCredentials: true });
    dispatch(porterSuccess(data));
  } catch (error) {
    dispatch(porterFail(error.response.data.message));
  }
});

export const createPorterOrderResponse = createAsyncThunk('/order/createResponse', async ({ order_id, porterOrder_id }, { dispatch }) => {
  try {
    dispatch(porterOrderResponseRequest());
    const { data } = await axios.post('/api/v1/admin/porter/createResponse', { order_id, porterOrder_id }, { withCredentials: true });
    dispatch(porterOrderResponseSuccess(data));
  } catch (error) {
    dispatch(porterOrderResponseFail(error.response.data.message));
  }
});

export const CancelOrderResponse = createAsyncThunk('/order/cancelResponse', async ({ order_id, porterOrder_id }, { dispatch }) => {
  try {
    dispatch(porterCancelRequest());
    const { data } = await axios.post('/api/v1/admin/porter/cancelOrder', { order_id, porterOrder_id }, { withCredentials: true });
    dispatch(porterCancelSuccess(data));
  } catch (error) {
    dispatch(porterCancelFail(error.response.data.message));
  }
});

export const RemoveOrderResponse = createAsyncThunk('/order/cancelResponse', async ({ order_id, removalReason }, { dispatch }) => {
  try {
    dispatch(porterRemoveRequest());
    const { data } = await axios.post('/api/v1/admin/removeOrder', { order_id, removalReason }, { withCredentials: true });
    dispatch(porterRemoveSuccess(data));
  } catch (error) {
    dispatch(porterRemoveFail(error.response.data.message));
  }
});

export const packedOrder = createAsyncThunk('/order/postpackedOrderResponse', async ({ reqPackedData }, { dispatch }) => {
  try {
    dispatch(packedOrderRequest());
    const { data } = await axios.post('/api/v1/admin/packedOrder', reqPackedData, { withCredentials: true });
    dispatch(packedOrderSuccess(data));
  } catch (error) {
    dispatch(packedOrderFail(error.response.data.message));
  }
});

export const getPackedOrder = createAsyncThunk('/order/getpackedOrderResponse', async ({ order_id }, { dispatch }) => {
  try {
    dispatch(getpackedOrderRequest());
    const { data } = await axios.post('/api/v1/admin/getPackedOrder', { order_id }, { withCredentials: true });
    dispatch(getpackedOrderSuccess(data));
  } catch (error) {
    dispatch(getpackedOrderFail(error.response.data.message));
  }
});


export const allPackedOrder = createAsyncThunk('/order/allpackedOrderResponse', async ({ }, { dispatch }) => {
  try {
    dispatch(allpackedOrderRequest());
    const { data } = await axios.get('/api/v1/admin/PackedOrder/all', { withCredentials: true });
    dispatch(allpackedOrderSuccess(data));
  } catch (error) {
    dispatch(allpackedOrderFail(error.response.data.message));
  }
});

// export const updatedPackedOrder = createAsyncThunk('/order/allpackedOrderResponse', async ({}, { dispatch }) => {
//   try {
//     dispatch(updatepackedOrderRequest());
//     const { data } = await axios.get('/api/v1/admin/PackedOrder/all',{withCredentials: true });
//     dispatch(updatepackedOrderSuccess(data));
//   } catch (error) {
//     dispatch(updatepackedOrderFail(error.response.data.message));
//   }
// });
export const updatedPackedOrder = createAsyncThunk('/order/allpackedOrderResponse',async ({ startDate, endDate }, { dispatch }) => {
    try {
      dispatch(updatepackedOrderRequest());

      // Build the query parameters for the API request
      const queryParams = {};
      if (startDate) queryParams.startDate = startDate;
      if (endDate) queryParams.endDate = endDate;

      // Send the GET request with query parameters
      const { data } = await axios.get('/api/v1/admin/PackedOrder/all', {
        withCredentials: true,
        params: queryParams, // Passing the date filters as query params
      });

      // Dispatch the success action with the data from the API response
      dispatch(updatepackedOrderSuccess(data));
    } catch (error) {
      // Handle any errors during the request
      dispatch(updatepackedOrderFail(error.response?.data?.message || 'An error occurred'));
    }
  }
);
export const initRefund = createAsyncThunk('/order/initRefund', async ({ order_id, RefundableAmount }, { dispatch }) => {
  try {
    dispatch(refundRequest());
    const { data } = await axios.post('/api/v1/admin/refund', { order_id, RefundableAmount }, { withCredentials: true });
    dispatch(refundSuccess(data));
  } catch (error) {
    dispatch(refundFail(error.response.data.message));
  }
});


