// import { Fragment, useEffect } from "react";
// import Sidebar from "./Sidebar";
// import { useDispatch, useSelector } from 'react-redux';
// import { useLocation, useNavigate, useParams } from "react-router-dom";
// import { orderDetail as orderDetailAction, updateOrder, porterOrder, RemoveOrderResponse, adminOrders } from "../../actions/orderActions";
// import { CancelOrderResponse, createPorterOrderResponse, getPackedOrder, getporterOrder, packedOrder } from "../../actions/porterActions";
// import { Slide, toast } from "react-toastify";
// import { clearOrderUpdated, clearError, adminOrderRemoveClearError } from "../../slices/orderSlice";

// import {
//     clearpackedUpdated,
// } from '../../slices/porterSlice';
// import NumberInput from "../Layouts/NumberInput";
// import Loader from "../Layouts/Loader";
// import React, { useState, useRef } from 'react';
// import MetaData from "../Layouts/MetaData";
// import LoaderButton from "../Layouts/LoaderButton";
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';


// const UpdateOrder = ({ isActive, setIsActive }) => {
//     const location = useLocation();
//     // sessionStorage.setItem('redirectPath', location.pathname);
//     const { loading, isOrderUpdated, error, orderDetail, porterOrderDetail, orderRemoveResponse, orderRemoveError } = useSelector(state => state.orderState);
//     const { products } = useSelector((state) => state.productsState);
//     const { porterOrderData, porterOrderResponse, porterCancelResponse, porterCancelError, portererror, packedOrderData, getpackedOrderData, loading: packedloading, packedOrderError } = useSelector((state) => state.porterState);
//     const { user = {}, orderItems = [], shippingInfo = {}, totalPrice = 0, statusResponse = {} } = orderDetail;
//     const [orderStatus, setOrderStatus] = useState("Processing");
//     const [dropStatus, setDropStatus] = useState("");
//     const [showDispatchModal, setShowDispatchModal] = useState(false);
//     const [editableWeights, setEditableWeights] = useState(orderDetail && orderItems && orderItems.map(item => item.productWeight)); // Initial state for weights
//     const [originalWeights, setOriginalWeights] = useState(orderItems.map(item => item.productWeight)); // Original weights
//     const [selectedItems, setSelectedItems] = useState([]);
//     const { id } = useParams();
//     const [refreshData, setRefreshData] = useState(false)
//     const dispatch = useDispatch();
//     const [weightvalue, setweightvalue] = useState(false);
//     const navigate = useNavigate();
//     const [showModal, setShowModal] = useState(false); // State to manage modal visibility
//     const [totalDispatchedAmount, setTotalDispatchedAmount] = useState(0); // To store total dispatched amount

//     const calculateTotalDispatchedAmount = (updatedItems) => {
//         let total = 0;

//         updatedItems && updatedItems.forEach((item, index) => {
//             const dispatchedWeight = parseFloat(item.productWeight);
//             const pricePerKg = parseFloat(orderItems[index].price);
//             total += dispatchedWeight * pricePerKg;
//         });

//         return total;
//     };


//     useEffect(() => {
//         if (orderDetail.order_id) {
//             setOrderStatus(orderDetail.orderStatus);
//             setDropStatus(orderDetail.orderStatus);
//         }
//         if (orderItems) {
//             setEditableWeights(orderItems.map(item => item.productWeight))
//         }

//     }, [orderDetail]);


//     const handleItemSelection = (index) => {
//         const newSelectedItems = [...selectedItems];
//         newSelectedItems[index] = !newSelectedItems[index];

//         if (newSelectedItems[index]) {
//             // If the checkbox is checked, set the weight to zero
//             const newWeights = [...editableWeights];
//             newWeights[index] = 0;
//             setEditableWeights(newWeights);
//         } else {
//             // If the checkbox is unchecked, reset the weight to the original value
//             const newWeights = [...editableWeights];
//             newWeights[index] = orderItems[index].productWeight;
//             setEditableWeights(newWeights);
//         }

//         setSelectedItems(newSelectedItems);
//     };


//     const changeWeight = (e, index) => {
//         const value = e.target.value;
//         const measurement = orderItems[index].measurement;
//         if (value === '' || !isNaN(value)) {
//             const numericValue = parseFloat(value);
//             if (numericValue < 0) {
//                 // If the entered value is negative, reset to the original weight and show an error
//                 setweightvalue(true);
//                 if (!weightvalue) {
//                     toast.dismiss();
//                     setTimeout(() => {
//                         toast.error('Quantity cannot be negative. Reverting to original Quantity.', {
//                             position: 'bottom-center',
//                             type: 'error',
//                             autoClose: 700,
//                             transition: Slide,
//                             hideProgressBar: true,
//                             className: 'small-toast',
//                         });
//                         setweightvalue(false);
//                     }, 300);
//                 }
//                 return;
//             }

//             if (numericValue > orderItems[index].productWeight) {
//                 setweightvalue(true);
//                 if (!weightvalue) {
//                     toast.dismiss();
//                     setTimeout(() => {
//                         toast.error('Entered Quantity is greater than requested Quantity. Reverting to original Quantity.', {
//                             position: 'bottom-center',
//                             type: 'error',
//                             autoClose: 700,
//                             transition: Slide,
//                             hideProgressBar: true,
//                             className: 'small-toast',
//                         });
//                         setweightvalue(false)
//                     }, 300);
//                 }

//                 return;
//             }

//             const weight = measurement && measurement === 'Kg' ? Math.min(parseFloat(numericValue).toFixed(2), orderItems[index].productWeight.toFixed(2)) : Math.floor(numericValue, orderItems[index].productWeight); // Ensure weight does not exceed initially ordered weight
//             const newWeights = [...editableWeights];
//             newWeights[index] = value === '' ? 0 : weight; // Allow empty value temporarily for editing
//             setEditableWeights(newWeights);
//         }

//     };
//     const handleBlur = (index) => {
//         if (editableWeights[index] === '' || editableWeights[index] === null) {
//             const newWeights = [...editableWeights];
//             newWeights[index] = orderItems[index].productWeight;
//             setEditableWeights(newWeights);
//         }
//     };
//     const updatedItems = orderItems && orderItems.map((item, index) => ({
//         ...item,
//         status: editableWeights[index] > 0 ? 'confirm' : 'cancel',
//         productWeight: editableWeights[index]
//     }));
//     const handlePack = async (e) => {
//         e.preventDefault();
//         const dispatchedAmount = calculateTotalDispatchedAmount(updatedItems && updatedItems);
//         setTotalDispatchedAmount(dispatchedAmount); // Update the total dispatched amount

//         // Open modal
//         setShowModal(true);
//     };

//     const submitHandlerPacked = async (e) => {
//         e.preventDefault();

//         let totalDispatchedAmount = 0;
//         let totalRefundableAmount = 0;

//         const dispatchedTable = orderItems.map((item, index) => {
//             const orderedWeight = parseFloat(item.productWeight);
//             const measurement = item.measurement;
//             const range = item.range && item.range;
//             const dispatchedWeight = parseFloat(updatedItems[index].productWeight);
//             const refundableWeight = parseFloat((orderedWeight - dispatchedWeight).toFixed(2)); 
//             const pricePerKg = parseFloat((item.price).toFixed(2));
//             const totalAmount = parseFloat((dispatchedWeight * pricePerKg).toFixed(2));
//             const refundableAmount = parseFloat((refundableWeight * pricePerKg).toFixed(2)); 

//             totalRefundableAmount += refundableAmount;

//             totalDispatchedAmount += totalAmount;

//             return {
//                 image: item.image,
//                 name: item.name,
//                 orderedWeight,
//                 measurement,
//                 range: range ? range : '',
//                 pricePerKg,
//                 dispatchedWeight,
//                 refundableWeight,

//             };
//         });

//         totalDispatchedAmount = parseFloat(totalDispatchedAmount.toFixed(2)); 
//         totalRefundableAmount = parseFloat(totalRefundableAmount.toFixed(2)); 

//         const reqPackedData = {
//             user: user,
//             user_id: user._id,
//             order_id: orderDetail.order_id,
//             orderDetail: orderDetail,
//             updatedItems: updatedItems,
//             dispatchedTable: dispatchedTable,
//             orderDate: orderDetail.orderDate,
//             totalDispatchedAmount: totalDispatchedAmount,
//             totalRefundableAmount: totalRefundableAmount
//         };

//         try {
//             await dispatch(packedOrder({ reqPackedData }));

//         } catch (error) {
//             toast.dismiss();
//             setTimeout(() => {
//                 toast.error(error, {
//                     position: 'bottom-center',
//                     type: 'error',
//                     autoClose: 700,
//                     transition: Slide,
//                     hideProgressBar: true,
//                     className: 'small-toast',
//                 });
//             }, 300);
//         }
//     }

//     useEffect(() => {

//         dispatch(orderDetailAction(id));
//         dispatch(getporterOrder({ order_id: id }));
//         dispatch(getPackedOrder({ order_id: id }));

//         setRefreshData(true)

//     }, [dispatch, id, porterOrderDetail]);

//     useEffect(() => {
//         if (packedOrderData) {
//             toast.dismiss();
//             setTimeout(() => {
//                 toast.success('Order Updated Successfully!', {
//                     position: 'bottom-center',
//                     type: 'success',
//                     autoClose: 700,
//                     transition: Slide,
//                     hideProgressBar: true,
//                     className: 'small-toast',
//                     onClose: () => dispatch(clearpackedUpdated())
//                 });
//                 setTimeout(() => {
//                     dispatch(adminOrders());
//                     dispatch(orderDetailAction(id));
//                     dispatch(getporterOrder({ order_id: id }));
//                     dispatch(getPackedOrder({ order_id: id }));
//                 }, 700);

//             }, 300);
//             setShowModal(false);

//         }
//         if (packedOrderError) {
//             toast.dismiss();
//             setTimeout(() => {
//                 toast.error(packedOrderError, {
//                     position: 'bottom-center',
//                     type: 'error',
//                     autoClose: 700,
//                     transition: Slide,
//                     hideProgressBar: true,
//                     className: 'small-toast',
//                     onClose: () => dispatch(clearpackedUpdated())
//                 });

//             }, 300);
//             setShowModal(false);
//         }
//     }, [packedOrderData, packedOrderError])

//     useEffect(() => {
//         if (error) {
//             toast.dismiss();
//             setTimeout(() => {
//                 toast.error(error, {
//                     position: 'bottom-center',
//                     type: 'error',
//                     autoClose: 700,
//                     transition: Slide,
//                     hideProgressBar: true,
//                     className: 'small-toast',
//                     onOpen: () => { dispatch(clearError()) }
//                 });
//             }, 300);
//             return;
//         }
//     }, [error])


//     useEffect(() => {
//         if (porterOrderData && refreshData) {
//             dispatch(createPorterOrderResponse({ order_id: porterOrderData && porterOrderData.order_id, porterOrder_id: porterOrderData?.porterOrder?.order_id }))
//         }
//     }, [porterOrderData])

//     useEffect(() => {
//         if (refreshData && porterOrderResponse) {
//             dispatch(getporterOrder({ order_id: id }))
//             setRefreshData(false)
//         }
//     }, [refreshData, porterOrderResponse])

//     const capitalizeFirstLetter = (str) => {
//         return str
//             .toLowerCase()
//             .split(' ')
//             .map(word => word.charAt(0).toUpperCase() + word.slice(1))
//             .join(' ');
//     };

//     return (
//         <div>
//             <MetaData
//                 title="Update Order"
//                 description="Modify order details, update order status, and handle special requests from customers to ensure order accuracy."
//             />



//             <div className="row loader-parent">
//                 <div className="col-12 col-md-2">
//                     <div style={{ display: 'flex', flexDirection: 'row', position: 'fixed', top: '0px', zIndex: 99999, backgroundColor: '#fff', minWidth: '100%' }}>
//                         <Sidebar isActive={isActive} setIsActive={setIsActive} />
//                     </div>
//                 </div>

//                 <div className="col-12 col-md-10 smalldevice-space container order-detail-container loader-parent">
//                     <div className="back-button" onClick={() => navigate(-1)}>
//                         <ArrowBackIcon fontSize="small" />
//                         <span>Back</span>
//                     </div>
//                     {
//                         loading ? (
//                             <div className="container loader-loading-center">
//                                 <Loader />
//                             </div>
//                         ) : (
//                             <Fragment>
//                                 <div className="col-12 col-lg-12 mt-5 order-details">
//                                     <h1 className="order-id-title mb-4">Order ID: {orderDetail.order_id}</h1>

//                                     <h4 className="shipping-title mb-2">Shipping Info</h4>
//                                     <div className="shipping-info">
//                                         <div><b>Name:</b> {shippingInfo.name}</div>
//                                         <div><b>Phone:</b> {shippingInfo.phoneNo}</div>
//                                         <div className='order-address-formatted'>
//                                             <b>Address:</b>
//                                             {shippingInfo.address && `${shippingInfo.address},`}
//                                             {shippingInfo.area && `${shippingInfo.area},`}
//                                             {shippingInfo.landmark && `${shippingInfo.landmark},`}
//                                             {shippingInfo.city && `${shippingInfo.city}`}
//                                             {shippingInfo.postalCode && -`${shippingInfo.postalCode}`}
//                                         </div>

//                                         <div><b>Amount:</b> Rs.{parseFloat(totalPrice).toFixed(2)}</div>
//                                         {orderDetail && orderDetail.statusResponse && orderDetail.statusResponse.payment_method && (
//                                             <div><b>Payment Mode:</b> {orderDetail && orderDetail.statusResponse && orderDetail.statusResponse.payment_method}</div>

//                                         )

//                                         }
//                                     </div>
//                                     <hr />

//                                     <div className="status-container" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
//                                         <div style={{ display: 'flex', alignItems: 'center' }}>
//                                             <div><b>Payment Status:</b></div>
//                                             <div style={{ color: orderDetail && orderDetail.paymentStatus === 'CHARGED' ? 'green' : 'red', marginLeft: '10px' }}>
//                                                 <b>{orderDetail && orderDetail.paymentStatus === 'CHARGED' ? 'Success' : orderDetail ? orderDetail.paymentStatus : 'Pending'}</b>
//                                             </div>
//                                         </div>
//                                         <div style={{ display: 'flex', alignItems: 'center', marginRight: '50px', whiteSpace: 'nowrap' }}>
//                                             <div><b>Order Status:</b></div>
//                                             <div style={{ color: orderStatus && orderStatus.includes('Delivered') ? 'green' : 'red', marginLeft: '10px' }}>
//                                                 <b>{orderStatus}</b>
//                                             </div>

//                                         </div>

//                                     </div>


//                                     <hr />
//                                     <h4 className=" order-items my-4">Order Items:</h4>

//                                     <div className="invoice-table-container">
//                                         <div className="updatetable-responsive">
//                                             <table className="updatetable updatetable-bordered">
//                                                 <thead>
//                                                     <tr>
//                                                         {getpackedOrderData && getpackedOrderData.dispatchedTable ? (
//                                                             <>
//                                                                 <th>Image</th>
//                                                                 <th>Name</th>
//                                                                 <th>Ordered Quantity</th>
//                                                                 <th>Price per kg</th>
//                                                                 <th>Dispatched Quantity</th>
//                                                                 <th>Refundable Weight</th>
//                                                                 <th>Refundable Amount</th>
//                                                             </>
//                                                         ) : (
//                                                             <>
//                                                                 <th>Select Item</th>
//                                                                 <th>Image</th>
//                                                                 <th>Name</th>
//                                                                 <th>Price per kg</th>
//                                                                 <th>Ordered Quantity</th>
//                                                                 <th>Dispatch Quantity</th>
//                                                                 <th>Total Price</th>
//                                                             </>
//                                                         )}
//                                                     </tr>
//                                                 </thead>
//                                                 <tbody>
//                                                     {getpackedOrderData && getpackedOrderData.dispatchedTable ? (
//                                                         getpackedOrderData.dispatchedTable.map((item, index) => (
//                                                             <tr key={index}>
//                                                                 <td>
//                                                                     <img src={item.image} alt={item.name} className="updateTableproduct-image" />
//                                                                 </td>
//                                                                 <td>{item && item.range ? `${capitalizeFirstLetter(item.name)} (${item.range})` : `${capitalizeFirstLetter(item.name)}`}</td>
//                                                                 <td>{item.orderedWeight} {item.measurement}</td>
//                                                                 <td>Rs. {item.pricePerKg}</td>
//                                                                 <td>{item.dispatchedWeight ? item.dispatchedWeight : 0} {item.measurement}</td>
//                                                                 <td>{item.refundableWeight ? item.refundableWeight : 0} {item.measurement}</td>
//                                                                 <td>Rs. {item.refundableWeight ? item.refundableWeight * item.pricePerKg : 0}</td>

//                                                             </tr>
//                                                         ))
//                                                     ) : (
//                                                         orderItems.map((item, index) => {
//                                                             const product = products.find((product) => product.englishName === item.name);
//                                                             if (!product) return null;

//                                                             return (
//                                                                 <tr key={index}>
//                                                                     <td>
//                                                                         <input
//                                                                             type="checkbox"
//                                                                             className="updatecheck-input"
//                                                                             checked={selectedItems[index]}
//                                                                             onChange={() => handleItemSelection(index)}
//                                                                         />
//                                                                     </td>
//                                                                     <td>
//                                                                         <img src={item.image} alt={item.name} className="updateTableproduct-image" />
//                                                                     </td>
//                                                                     <td>{item && item.range ? `${capitalizeFirstLetter(item.name)} (${item.range})` : `${capitalizeFirstLetter(item.name)}`}</td>
//                                                                     <td>Rs. {item.price}</td>
//                                                                     <td>{item.productWeight} {item.measurement}</td>
//                                                                     {editableWeights && (
//                                                                         <>
//                                                                             <td style={{ maxWidth: '70px' }}>
//                                                                                 <NumberInput
//                                                                                     type="number"
//                                                                                     className="no-arrow-input updateTableInput"
//                                                                                     value={editableWeights[index] === 0 ? '' : editableWeights[index]}
//                                                                                     step="0.01"
//                                                                                     onChange={(e) => changeWeight(e, index)}
//                                                                                     placeholder={editableWeights[index] === 0 ? 0 : ''}
//                                                                                     onBlur={() => handleBlur(index)}
//                                                                                     disabled={!selectedItems[index]}
//                                                                                     required
//                                                                                 />

//                                                                             </td>
//                                                                             <td>Rs. {(editableWeights[index] * item.price).toFixed(2)}</td>
//                                                                         </>
//                                                                     )}
//                                                                 </tr>
//                                                             );
//                                                         })
//                                                     )}
//                                                 </tbody>
//                                             </table>
//                                         </div>
//                                     </div>

//                                     <hr />
//                                     <div>
//                                         <button className='btn btn-primary' onClick={(e) => handlePack(e)} disabled={dropStatus === "Processing" ? false : true} style={{ cursor: (dropStatus === "Processing") ? 'pointer' : 'not-allowed' }}>{dropStatus === "Processing" ? 'Pack' : dropStatus === "Cancelled" ? 'Already Cancelled' : dropStatus === "Dispatched" ? 'Already Dispatched' : dropStatus === "Packed" ? 'Already Packed' : dropStatus}</button>

//                                     </div>

//                                 </div>
//                             </Fragment>
//                         )
//                     }
//                 </div>



//             </div>
//             {showModal && (
//                 <div className="modal" tabIndex="-1" role="dialog">
//                     <div className="modal-dialog" role="document">
//                         <div className="modal-content">
//                             <div className="modal-header">
//                                 <h5 className="modal-title">Confirm Action</h5>
//                                 <button type="button" className="close" disabled={packedloading} onClick={() => setShowModal(false)}>
//                                     <span aria-hidden="true">&times;</span>
//                                 </button>
//                             </div>
//                             <div className="modal-body">
//                                 {totalDispatchedAmount > 0 ? (
//                                     <p>Do you want to confirm the pack?</p>
//                                 ) : (
//                                     <p>There is no item to pack. Please confirm to cancel this order.</p>
//                                 )}
//                             </div>
//                             <div className="modal-footer">
//                                 {totalDispatchedAmount > 0 ? (
//                                     <button type="button" disabled={packedloading} className="btn btn-primary" onClick={submitHandlerPacked}>

//                                         {packedloading ? <LoaderButton fullPage={false} size={20} /> : (
//                                             <span>Confirm Pack</span>
//                                         )

//                                         }
//                                     </button>
//                                 ) : (
//                                     <button type="button" disabled={packedloading} className="btn btn-danger" onClick={submitHandlerPacked}>


//                                         {packedloading ? <LoaderButton fullPage={false} size={20} /> : (
//                                             <span>Cancel Order</span>
//                                         )

//                                         }
//                                     </button>
//                                 )}

//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             )}
//         </div>
//     );



// };

// export default UpdateOrder;


import { Fragment, useEffect } from "react";
import Sidebar from "./Sidebar";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { orderDetail as orderDetailAction, updateOrder, porterOrder, RemoveOrderResponse, adminOrders } from "../../actions/orderActions";
import { CancelOrderResponse, createPorterOrderResponse, getPackedOrder, getporterOrder, packedOrder } from "../../actions/porterActions";
import { Slide, toast } from "react-toastify";
import { clearOrderUpdated, clearError, adminOrderRemoveClearError } from "../../slices/orderSlice";

import {
    clearpackedUpdated,
} from '../../slices/porterSlice';
import NumberInput from "../Layouts/NumberInput";
import Loader from "../Layouts/Loader";
import React, { useState, useRef } from 'react';
import MetaData from "../Layouts/MetaData";
import LoaderButton from "../Layouts/LoaderButton";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


const UpdateOrder = ({ isActive, setIsActive }) => {
    const location = useLocation();
    // sessionStorage.setItem('redirectPath', location.pathname);
    const { loading, isOrderUpdated, error, orderDetail, porterOrderDetail, orderRemoveResponse, orderRemoveError } = useSelector(state => state.orderState);
    const { products } = useSelector((state) => state.productsState);
    const { porterOrderData, porterOrderResponse, porterCancelResponse, porterCancelError, portererror, packedOrderData, getpackedOrderData, loading: packedloading, packedOrderError, packedOrderMessage } = useSelector((state) => state.porterState);
    const { user = {}, orderItems = [], shippingInfo = {}, totalPrice = 0, statusResponse = {} } = orderDetail;
    const [orderStatus, setOrderStatus] = useState("Processing");
    const [dropStatus, setDropStatus] = useState("");
    const [editRepack, setEditRepack] = useState(false);
    const [showDispatchModal, setShowDispatchModal] = useState(false);
    const [editableWeights, setEditableWeights] = useState(editRepack ? getpackedOrderData && getpackedOrderData.dispatchedTable.map(item => item.dispatchedWeight) : orderDetail && orderItems && orderItems.map(item => item.productWeight)); // Initial state for weights
    const [originalWeights, setOriginalWeights] = useState(orderItems.map(item => item.productWeight)); // Original weights
    const [selectedItems, setSelectedItems] = useState([]);
    const { id } = useParams();
    const [refreshData, setRefreshData] = useState(false)
    const dispatch = useDispatch();
    const [weightvalue, setweightvalue] = useState(false);
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false); // State to manage modal visibility
    const [totalDispatchedAmount, setTotalDispatchedAmount] = useState(0); // To store total dispatched amount
    console.log("editableWeights", editableWeights)


    const calculateTotalDispatchedAmount = (updatedItems) => {
        let total = 0;

        if (editRepack) {
            updatedItems && updatedItems.forEach((item, index) => {
                const dispatchedWeight = parseFloat(item.productWeight);
                const pricePerKg = parseFloat(getpackedOrderData && getpackedOrderData.dispatchedTable[index].pricePerKg);
                total += dispatchedWeight * pricePerKg;
            });

            return total;
        }
        else {
            updatedItems && updatedItems.forEach((item, index) => {
                const dispatchedWeight = parseFloat(item.productWeight);
                const pricePerKg = parseFloat(orderItems[index].price);
                total += dispatchedWeight * pricePerKg;
            });

            return total;
        }


    };
    const handleEditClick = () => {
        setEditRepack(!editRepack); // Set edit mode to true when clicked
    };

    console.log("orderItems", orderItems)


    useEffect(() => {
        if (orderDetail.order_id) {
            setOrderStatus(orderDetail.orderStatus);
            setDropStatus(orderDetail.orderStatus);
        }
        if (orderItems && !editRepack) {
            setEditableWeights(orderItems.map(item => item.productWeight))
        }
        if (getpackedOrderData && editRepack) {
            setEditableWeights(getpackedOrderData && getpackedOrderData.dispatchedTable.map(item => item.dispatchedWeight))
        }

    }, [orderDetail, editRepack]);


    const handleItemSelection = (index) => {
        const newSelectedItems = [...selectedItems];
        newSelectedItems[index] = !newSelectedItems[index];

        if (!editRepack) {
            if (newSelectedItems[index]) {
                // If the checkbox is checked, set the weight to zero
                const newWeights = [...editableWeights];
                newWeights[index] = 0;
                setEditableWeights(newWeights);
            } else {
                // If the checkbox is unchecked, reset the weight to the original value
                const newWeights = [...editableWeights];
                newWeights[index] = orderItems[index].productWeight;
                setEditableWeights(newWeights);
            }
        }
        if (editRepack) {
            if (newSelectedItems[index]) {
                // If the checkbox is checked, set the weight to zero
                const newWeights = [...editableWeights];
                newWeights[index] = getpackedOrderData?.dispatchedTable[index].dispatchedWeight;
                setEditableWeights(newWeights);
            } else {
                // If the checkbox is unchecked, reset the weight to the original value
                const newWeights = [...editableWeights];
                newWeights[index] = getpackedOrderData?.dispatchedTable[index].dispatchedWeight;
                setEditableWeights(newWeights);
            }
        }

        setSelectedItems(newSelectedItems);
    };


    const changeWeight = (e, index) => {
        const value = e.target.value;
        const measurement = editRepack ? getpackedOrderData?.dispatchedTable[index].measurement : orderItems[index].measurement;
        if (editRepack) {
            if (value === '' || !isNaN(value)) {
                const numericValue = parseFloat(value);
                if (numericValue < 0) {
                    // If the entered value is negative, reset to the original weight and show an error
                    setweightvalue(true);
                    if (!weightvalue) {
                        toast.dismiss();
                        setTimeout(() => {
                            toast.error('Quantity cannot be negative. Reverting to original Quantity.', {
                                position: 'top-right',
                                type: 'error',
                                autoClose: 700,
                                transition: Slide,
                                hideProgressBar: true,
                                className: 'small-toast-input',
                            });
                            setweightvalue(false);
                        }, 300);
                    }
                    return;
                }

                if (numericValue > getpackedOrderData?.dispatchedTable[index].orderedWeight) {
                    setweightvalue(true);
                    if (!weightvalue) {
                        toast.dismiss();
                        setTimeout(() => {
                            toast.error('Entered Quantity is greater than requested Quantity!', {
                                position: 'top-right',
                                type: 'error',
                                autoClose: 700,
                                transition: Slide,
                                hideProgressBar: true,
                                className: 'small-toast-input',
                            });
                            setweightvalue(false)
                        }, 300);
                    }

                    return;
                }

                const weight = measurement && measurement === 'Kg' ? Math.min(parseFloat(numericValue).toFixed(2), getpackedOrderData?.dispatchedTable[index].orderedWeight.toFixed(2)) : Math.floor(numericValue, getpackedOrderData?.dispatchedTable[index].orderedWeight); // Ensure weight does not exceed initially ordered weight
                const newWeights = [...editableWeights];
                newWeights[index] = value === '' ? 0 : weight; // Allow empty value temporarily for editing
                setEditableWeights(newWeights);
            }
        }
        else {
            if (value === '' || !isNaN(value)) {
                const numericValue = parseFloat(value);
                if (numericValue < 0) {
                    // If the entered value is negative, reset to the original weight and show an error
                    setweightvalue(true);
                    if (!weightvalue) {
                        toast.dismiss();
                        setTimeout(() => {
                            toast.error('Quantity cannot be negative. Reverting to original Quantity.', {
                                position: 'top-right',
                                type: 'error',
                                autoClose: 700,
                                transition: Slide,
                                hideProgressBar: true,
                                className: 'small-toast-input',
                            });
                            setweightvalue(false);
                        }, 300);
                    }
                    return;
                }

                if (numericValue > orderItems[index].productWeight) {
                    setweightvalue(true);
                    if (!weightvalue) {
                        toast.dismiss();
                        setTimeout(() => {
                            toast.error('Entered Quantity is greater than requested Quantity. Reverting to original Quantity.', {
                                position: 'top-right',
                                type: 'error',
                                autoClose: 700,
                                transition: Slide,
                                hideProgressBar: true,
                                className: 'small-toast-input',
                            });
                            setweightvalue(false)
                        }, 300);
                    }

                    return;
                }

                const weight = measurement && measurement === 'Kg' ? Math.min(parseFloat(numericValue).toFixed(2), orderItems[index].productWeight.toFixed(2)) : Math.floor(numericValue, orderItems[index].productWeight); // Ensure weight does not exceed initially ordered weight
                const newWeights = [...editableWeights];
                newWeights[index] = value === '' ? 0 : weight; // Allow empty value temporarily for editing
                setEditableWeights(newWeights);
            }
        }

    };

    const handleBlur = (index) => {
        if (!editRepack) {
            if (editableWeights[index] === '' || editableWeights[index] === null) {
                const newWeights = [...editableWeights];
                newWeights[index] = orderItems[index].productWeight;
                setEditableWeights(newWeights);
            }
        }
        if (editRepack) {
            if (editableWeights[index] === '' || editableWeights[index] === null) {
                const newWeights = [...editableWeights];
                newWeights[index] = getpackedOrderData?.dispatchedTable[index].dispatchedWeight;
                setEditableWeights(newWeights);
            }
        }

    };

    const updatedItems = orderItems && orderItems.map((item, index) => ({
        ...item,
        status: editableWeights && editableWeights[index] > 0 ? 'confirm' : 'cancel',
        productWeight: editableWeights && editableWeights[index]
    }));
    console.log("updatedItems", updatedItems)

    const handlePack = async (e) => {
        e.preventDefault();
        if (editRepack) {
            const dispatchedAmount = calculateTotalDispatchedAmount(updatedItems && updatedItems);
            setTotalDispatchedAmount(dispatchedAmount); // Update the total dispatched amount

            // Open modal
            setShowModal(true);
        }
        else {
            const dispatchedAmount = calculateTotalDispatchedAmount(updatedItems && updatedItems);
            setTotalDispatchedAmount(dispatchedAmount); // Update the total dispatched amount

            // Open modal
            setShowModal(true);
        }

    };

    const submitHandlerPacked = async (e) => {
        e.preventDefault();
        if (editRepack) {
            let totalDispatchedAmount = 0;
            let totalRefundableAmount = 0;

            const dispatchedTable = getpackedOrderData?.dispatchedTable.map((item, index) => {
                const orderedWeight = parseFloat(item.orderedWeight);
                const measurement = item.measurement;
                const range = item.range && item.range;
                const dispatchedWeight = parseFloat(updatedItems[index].productWeight);
                const refundableWeight = parseFloat((orderedWeight - dispatchedWeight).toFixed(2));
                const pricePerKg = parseFloat((item.pricePerKg).toFixed(2));
                const totalAmount = parseFloat((dispatchedWeight * pricePerKg).toFixed(2));
                const refundableAmount = parseFloat((refundableWeight * pricePerKg).toFixed(2));

                totalRefundableAmount += refundableAmount;

                totalDispatchedAmount += totalAmount;

                return {
                    image: item.image,
                    name: item.name,
                    orderedWeight,
                    measurement,
                    range: range ? range : '',
                    pricePerKg,
                    dispatchedWeight,
                    refundableWeight,
                };
            });

            totalDispatchedAmount = parseFloat(totalDispatchedAmount.toFixed(2));
            totalRefundableAmount = parseFloat(totalRefundableAmount.toFixed(2));

            const reqPackedData = {
                user: user,
                user_id: user._id,
                order_id: orderDetail.order_id,
                orderDetail: orderDetail,
                updatedItems: updatedItems,
                dispatchedTable: dispatchedTable,
                orderDate: orderDetail.orderDate,
                totalDispatchedAmount: totalDispatchedAmount,
                totalRefundableAmount: totalRefundableAmount
            };
            console.log("reqPackedData", reqPackedData)

            try {
                await dispatch(packedOrder({ reqPackedData }));

            } catch (error) {
                toast.dismiss();
                setTimeout(() => {
                    toast.error(error, {
                        position: 'bottom-center',
                        type: 'error',
                        autoClose: 700,
                        transition: Slide,
                        hideProgressBar: true,
                        className: 'small-toast',
                    });
                }, 300);
            }
        }
        else {
            let totalDispatchedAmount = 0;
            let totalRefundableAmount = 0;

            const dispatchedTable = orderItems.map((item, index) => {
                const orderedWeight = parseFloat(item.productWeight);
                const measurement = item.measurement;
                const range = item.range && item.range;
                const dispatchedWeight = parseFloat(updatedItems[index].productWeight);
                const refundableWeight = parseFloat((orderedWeight - dispatchedWeight).toFixed(2));
                const pricePerKg = parseFloat((item.price).toFixed(2));
                const totalAmount = parseFloat((dispatchedWeight * pricePerKg).toFixed(2));
                const refundableAmount = parseFloat((refundableWeight * pricePerKg).toFixed(2));

                totalRefundableAmount += refundableAmount;

                totalDispatchedAmount += totalAmount;

                return {
                    image: item.image,
                    name: item.name,
                    orderedWeight,
                    measurement,
                    range: range ? range : '',
                    pricePerKg,
                    dispatchedWeight,
                    refundableWeight,
                };
            });

            totalDispatchedAmount = parseFloat(totalDispatchedAmount.toFixed(2));
            totalRefundableAmount = parseFloat(totalRefundableAmount.toFixed(2));

            const reqPackedData = {
                user: user,
                user_id: user._id,
                order_id: orderDetail.order_id,
                orderDetail: orderDetail,
                updatedItems: updatedItems,
                dispatchedTable: dispatchedTable,
                orderDate: orderDetail.orderDate,
                totalDispatchedAmount: totalDispatchedAmount,
                totalRefundableAmount: totalRefundableAmount
            };
            console.log("reqPackedData", reqPackedData)

            try {
                await dispatch(packedOrder({ reqPackedData }));
            } catch (error) {
                toast.dismiss();
                setTimeout(() => {
                    toast.error(error, {
                        position: 'bottom-center',
                        type: 'error',
                        autoClose: 700,
                        transition: Slide,
                        hideProgressBar: true,
                        className: 'small-toast',
                    });
                }, 300);
            }

        }
    }

    useEffect(() => {
        dispatch(orderDetailAction(id));
        dispatch(getporterOrder({ order_id: id }));
        dispatch(getPackedOrder({ order_id: id }));

        setRefreshData(true)

    }, [dispatch, id, porterOrderDetail]);

    useEffect(() => {
        if (packedOrderMessage) {
            toast.dismiss();
            setTimeout(() => {
                toast.success(packedOrderMessage, {
                    position: 'bottom-center',
                    type: 'success',
                    autoClose: 500,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onClose: () => {
                        dispatch(clearpackedUpdated());
                        dispatch(adminOrders());
                        dispatch(orderDetailAction(id));
                        dispatch(getporterOrder({ order_id: id }));
                        dispatch(getPackedOrder({ order_id: id }));
                    },
                    onOpen: () => setEditRepack(false)
                });
                // setTimeout(() => {

                // }, 500);

            }, 300);
            setShowModal(false);
        }
        if (packedOrderError) {
            toast.dismiss();
            setTimeout(() => {
                toast.error(packedOrderError, {
                    position: 'bottom-center',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onClose: () => dispatch(clearpackedUpdated())
                });

            }, 300);
            setShowModal(false);
        }
    }, [packedOrderMessage, getpackedOrderData, packedOrderError])

    useEffect(() => {
        if (error) {
            toast.dismiss();
            setTimeout(() => {
                toast.error(error, {
                    position: 'bottom-center',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onOpen: () => { dispatch(clearError()) }
                });
            }, 300);
            return;
        }
    }, [error])


    useEffect(() => {
        if (porterOrderData && refreshData) {
            dispatch(createPorterOrderResponse({ order_id: porterOrderData && porterOrderData.order_id, porterOrder_id: porterOrderData?.porterOrder?.order_id }))
        }
    }, [porterOrderData])

    useEffect(() => {
        if (refreshData && porterOrderResponse) {
            dispatch(getporterOrder({ order_id: id }))
            setRefreshData(false)
        }
    }, [refreshData, porterOrderResponse])

    const capitalizeFirstLetter = (str) => {
        return str
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    return (
        <div>
            <MetaData
                title="Update Order"
                description="Modify order details, update order status, and handle special requests from customers to ensure order accuracy."
            />

            <div className="row loader-parent">
                <div className="col-12 col-md-2">
                    <div style={{ display: 'flex', flexDirection: 'row', position: 'fixed', top: '0px', zIndex: 99999, backgroundColor: '#fff', minWidth: '100%' }}>
                        <Sidebar isActive={isActive} setIsActive={setIsActive} />
                    </div>
                </div>

                <div className="col-12 col-md-10 smalldevice-space container order-detail-container loader-parent">
                    <div className="back-button" onClick={() => navigate(-1)}>
                        <ArrowBackIcon fontSize="small" />
                        <span>Back</span>
                    </div>
                    {
                        loading ? (
                            <div className="container loader-loading-center">
                                <Loader />
                            </div>
                        ) : (
                            <Fragment>
                                <div className="col-12 col-lg-12 mt-5 order-details">
                                    <h1 className="order-id-title mb-4">Order Id: {orderDetail.order_id}</h1>

                                    <h4 className="shipping-title mb-2">Shipping Info</h4>
                                    <div className="shipping-info">
                                        <div><b>Name:</b> {shippingInfo.name}</div>
                                        <div><b>Phone:</b> {shippingInfo.phoneNo}</div>
                                        <div className='order-address-formatted'>
                                            <b>Address:</b>
                                            {shippingInfo.address && `${shippingInfo.address},`}
                                            {shippingInfo.area && `${shippingInfo.area},`}
                                            {shippingInfo.landmark && `${shippingInfo.landmark},`}
                                            {shippingInfo.city && `${shippingInfo.city}`}
                                            {shippingInfo.postalCode && -`${shippingInfo.postalCode}`}
                                        </div>

                                        <div><b>Amount:</b> Rs.{parseFloat(totalPrice).toFixed(2)}</div>
                                        {orderDetail && orderDetail.statusResponse && orderDetail.statusResponse.payment_method && (
                                            <div><b>Payment Mode:</b> {orderDetail && orderDetail.statusResponse && orderDetail.statusResponse.payment_method}</div>

                                        )

                                        }
                                    </div>
                                    <hr />

                                    <div className="status-container" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div><b>Payment Status:</b></div>
                                            <div style={{ color: orderDetail && orderDetail.paymentStatus === 'CHARGED' ? 'green' : 'red', marginLeft: '10px' }}>
                                                <b>{orderDetail && orderDetail.paymentStatus === 'CHARGED' ? 'Success' : orderDetail ? orderDetail.paymentStatus : 'Pending'}</b>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', marginRight: '50px', whiteSpace: 'nowrap' }}>
                                            <div><b>Order Status:</b></div>
                                            <div style={{ color: orderStatus && orderStatus.includes('Delivered') ? 'green' : 'red', marginLeft: '10px' }}>
                                                <b>{orderStatus}</b>
                                            </div>

                                        </div>

                                    </div>


                                    <hr />
                                    {
                                        dropStatus === 'Packed' ? (
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <h4 className=" order-items my-4">Packed Items:</h4>
                                                {
                                                    !editRepack ? (
                                                        <button onClick={handleEditClick} className="btn btn-success packEdit">
                                                            {/* <i className="fa fa-pencil"></i> */}
                                                            Edit
                                                        </button>
                                                    ) : (
                                                        <button onClick={handleEditClick} className="btn btn-danger packEdit">
                                                            {/* <i className="fa fa-times"></i> */}
                                                            Cancel
                                                        </button>
                                                    )
                                                }

                                            </div>
                                        ) : (
                                            <h4 className=" order-items my-4">Order Items:</h4>
                                        )
                                    }

                                    <div className="invoice-table-container">
                                        <div className="updatetable-responsive">
                                            <table className="updatetable updatetable-bordered">
                                                <thead>
                                                    <tr>
                                                        {getpackedOrderData && getpackedOrderData.dispatchedTable && !editRepack ? (
                                                            <>
                                                                <th>Image</th>
                                                                <th>Name</th>
                                                                <th>Ordered Quantity</th>
                                                                <th>Price per kg</th>
                                                                <th>Dispatched Quantity</th>
                                                                <th>Refundable Quantity</th>
                                                                <th>Refundable Amount</th>
                                                            </>
                                                        ) : getpackedOrderData && getpackedOrderData.dispatchedTable && editRepack ? (
                                                            <>
                                                                <th>Select Item</th>
                                                                <th>Image</th>
                                                                <th>Name</th>
                                                                <th>Price per kg</th>
                                                                <th>Ordered Quantity</th>
                                                                <th>Dispatched Quantity</th>
                                                                <th>Total Price</th>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <th>Select Item</th>
                                                                <th>Image</th>
                                                                <th>Name</th>
                                                                <th>Price per kg</th>
                                                                <th>Ordered Quantity</th>
                                                                <th>Dispatch Quantity</th>
                                                                <th>Total Price</th>
                                                            </>
                                                        )}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {getpackedOrderData && getpackedOrderData.dispatchedTable && !editRepack ? (
                                                        getpackedOrderData.dispatchedTable.map((item, index) => (
                                                            <tr key={index}>
                                                                <td>
                                                                    <img src={item.image} alt={item.name} className="updateTableproduct-image" />
                                                                </td>
                                                                <td>{item && item.range ? `${capitalizeFirstLetter(item.name)} (${item.range})` : `${capitalizeFirstLetter(item.name)}`}</td>
                                                                <td>{item.orderedWeight} {item.measurement}</td>
                                                                <td>Rs. {item.pricePerKg}</td>
                                                                <td>{item.dispatchedWeight ? item.dispatchedWeight : 0} {item.measurement}</td>
                                                                <td>{item.refundableWeight ? item.refundableWeight : 0} {item.measurement}</td>
                                                                <td>Rs. {item.refundableWeight ? item.refundableWeight * item.pricePerKg : 0}</td>

                                                            </tr>
                                                        ))
                                                    ) : getpackedOrderData && getpackedOrderData.dispatchedTable && editRepack ? (
                                                        getpackedOrderData.dispatchedTable.map((item, index) => {
                                                            const product = products.find((product) => product.englishName === item.name);
                                                            if (!product) return null;

                                                            return (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <input
                                                                            type="checkbox"
                                                                            className="updatecheck-input"
                                                                            checked={selectedItems[index]}
                                                                            onChange={() => handleItemSelection(index)}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <img src={item.image} alt={item.name} className="updateTableproduct-image" />
                                                                    </td>
                                                                    <td>{item && item.range ? `${capitalizeFirstLetter(item.name)} (${item.range})` : `${capitalizeFirstLetter(item.name)}`}</td>
                                                                    <td>Rs. {item.pricePerKg}</td>
                                                                    <td>{item.orderedWeight} {item.measurement}</td>
                                                                    {editableWeights && (
                                                                        <>
                                                                            <td style={{ maxWidth: '70px' }}>
                                                                                <NumberInput
                                                                                    type="number"
                                                                                    className="no-arrow-input form-control updateTableInput"
                                                                                    value={editableWeights[index] === 0 ? "" : editableWeights[index]}
                                                                                    step="0.01"
                                                                                    onChange={(e) => changeWeight(e, index)}
                                                                                    placeholder={editableWeights[index] === 0 ? 0 : ''}
                                                                                    onBlur={() => handleBlur(index)}
                                                                                    disabled={!selectedItems[index]}
                                                                                    required
                                                                                />

                                                                            </td>
                                                                            <td>Rs. {(editableWeights[index] * item.pricePerKg).toFixed(2)}</td>
                                                                        </>
                                                                    )}
                                                                </tr>
                                                            );
                                                        })
                                                    ) : (
                                                        orderItems.map((item, index) => {
                                                            const product = products.find((product) => product.englishName === item.name);
                                                            if (!product) return null;

                                                            return (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <input
                                                                            type="checkbox"
                                                                            className="updatecheck-input"
                                                                            checked={selectedItems[index]}
                                                                            onChange={() => handleItemSelection(index)}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        <img src={item.image} alt={item.name} className="updateTableproduct-image" />
                                                                    </td>
                                                                    <td>{item && item.range ? `${capitalizeFirstLetter(item.name)} (${item.range})` : `${capitalizeFirstLetter(item.name)}`}</td>
                                                                    <td>Rs. {item.price}</td>
                                                                    <td>{item.productWeight} {item.measurement}</td>
                                                                    {editableWeights && (
                                                                        <>
                                                                            <td style={{ maxWidth: '70px' }}>
                                                                                <NumberInput
                                                                                    type="number"
                                                                                    className="no-arrow-input form-control updateTableInput"
                                                                                    value={editableWeights[index] === 0 ? "" : editableWeights[index]}
                                                                                    step="0.01"
                                                                                    onChange={(e) => changeWeight(e, index)}
                                                                                    placeholder={editableWeights[index] === 0 ? 0 : ''}
                                                                                    onBlur={() => handleBlur(index)}
                                                                                    disabled={!selectedItems[index]}
                                                                                    required
                                                                                />

                                                                            </td>
                                                                            <td>Rs. {(editableWeights[index] * item.price).toFixed(2)}</td>
                                                                        </>
                                                                    )}
                                                                </tr>
                                                            );
                                                        })
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <hr />
                                    <div>
                                        <button className='btn btn-primary'
                                            onClick={(e) => handlePack(e)}
                                            disabled={(dropStatus === "Processing" || editRepack) ? false : true}
                                            style={{ cursor: (dropStatus === "Processing") ? 'pointer' : (dropStatus === "Packed" && editRepack) ? 'pointer' : 'not-allowed' }}
                                        >
                                            {dropStatus === "Processing" ? 'Pack' : dropStatus === "Cancelled" ? 'Already Cancelled' : dropStatus === "Dispatched" ? 'Already Dispatched' : (editRepack && dropStatus === "Packed") ? 'RePack' : dropStatus === "Packed" ? 'Already Packed' : dropStatus}
                                        </button>
                                        {
                                            dropStatus === 'Packed' && (
                                                <Link to={`/admin/dispatch/${id}`} style={{  color: 'blue',marginLeft:'30px' }}>
                                                Go to Dispatch <ArrowForwardIcon fontSize="small" />
                                            </Link>
                                            )
                                        }
                                       


                                    </div>

                                </div>
                            </Fragment>
                        )
                    }
                </div>



            </div>
            {showModal && (
                <div className="modal" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Confirm Action</h5>
                                <button type="button" className="close" disabled={packedloading} onClick={() => setShowModal(false)}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {totalDispatchedAmount > 0 ? (
                                    <p>Do you want to confirm the pack?</p>
                                ) : (
                                    <p>There is no item to pack. Please confirm to cancel this order.</p>
                                )}
                            </div>
                            <div className="modal-footer">
                                {totalDispatchedAmount > 0 ? (
                                    <button type="button" disabled={packedloading} className="btn btn-primary" onClick={submitHandlerPacked}>

                                        {packedloading ? <LoaderButton fullPage={false} size={20} /> : (
                                            <span>Confirm Pack</span>
                                        )

                                        }
                                    </button>
                                ) : (
                                    <button type="button" disabled={packedloading} className="btn btn-danger" onClick={submitHandlerPacked}>


                                        {packedloading ? <LoaderButton fullPage={false} size={20} /> : (
                                            <span>Cancel Order</span>
                                        )

                                        }
                                    </button>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );



};

export default UpdateOrder;