
import React, { useEffect, Fragment, useState } from 'react';
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { adminOrders as adminOrdersAction } from "../../actions/orderActions";
import Loader from '../Layouts/Loader';
import { MDBDataTable } from 'mdbreact';
import { Slide, toast } from 'react-toastify';
import Sidebar from "../admin/Sidebar";
import { clearError } from '../../slices/productsSlice';
import { clearOrderDeleted, orderDetailClear } from "../../slices/orderSlice";
import MetaData from '../Layouts/MetaData';
import { porterClearData, porterClearResponse } from '../../slices/porterSlice';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const AllOrders = ({ isActive, setIsActive }) => {
    const location = useLocation();
    const { adminOrders: orders, loading = true, error, isOrderDeleted } = useSelector(state => state.orderState);
    const [date, setDate] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // const currentDate = new Date();
    // const formattedCurrentDate = currentDate.toISOString().split('T')[0];
    // const [inputStartDate, setInputStartDate] = useState('');
    // const [inputEndDate, setInputEndDate] = useState('');
    // const [startDate, setStartDate] = useState(formattedCurrentDate);
    // const [endDate, setEndDate] = useState(formattedCurrentDate);
    const currentDate = new Date(); // Get the current date
    const formattedCurrentDate = currentDate.toISOString().split('T')[0]; // Format current date
    
    // Calculate one month ago
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(currentDate.getMonth() - 1); // Subtract one month
    const formattedOneMonthAgo = oneMonthAgo.toISOString().split('T')[0]; // Format one month ago date
    
    // State variables
    const [inputStartDate, setInputStartDate] = useState('');
    const [inputEndDate, setInputEndDate] = useState('');
    const [startDate, setStartDate] = useState(formattedOneMonthAgo); // Initialize to one month ago
    const [endDate, setEndDate] = useState(formattedCurrentDate); // Initialize to today
    useEffect(() => {
        dispatch(orderDetailClear());
        dispatch(porterClearData());
        dispatch(porterClearResponse());
    }, [])

    const setOrders = () => {
        const data = {
            columns: [
                { label: 'S.No', field: 's_no', sort: 'asc' },
                { label: 'Order ID', field: 'id', sort: 'asc' },
                { label: 'Name', field: 'name', sort: 'asc' },
                { label: 'Phone.No', field: 'phone_no', sort: 'asc' },
                { label: 'Email', field: 'email', sort: 'asc' },
                { label: 'Amount', field: 'amount', sort: 'asc' },
                { label: 'OrderStatus', field: 'orderstatus', sort: 'asc' },
                { label: 'PaymentStatus', field: 'paymentstatus', sort: 'asc' },
                { label: 'Actions', field: 'actions', sort: 'asc' }
            ],
            rows: []
        };

        // Sort orders by creation date (newest first)
        const sortedOrders = orders && [...orders].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        // Filter orders by selected date and specific conditions
        const filteredOrders = sortedOrders && sortedOrders.filter(order => {
            if (!order.orderDate) return false;

            // Parse order date and match it with the selected date
            const orderDate = new Date(order.orderDate).toISOString().split('T')[0];
            const matchesDate = date ? orderDate === date : true; // Show all if date is not set
            return matchesDate;
        });

        // Map the filtered orders to table rows
        filteredOrders && filteredOrders.forEach((order, index) => {
            data.rows.push({
                s_no: index + 1,
                id: order.order_id,
                name: order.user.name,
                phone_no: order.shippingInfo.phoneNo,
                email: order?.user?.email ? order.user.email : '-',
                amount: `₹${order.totalPrice}`,
                orderstatus: (
                    <div className={order.orderStatus.includes('Delivered') ? 'greenColor' : 'redColor'}>
                        {order.orderStatus}
                    </div>
                ),
                paymentstatus: (
                    <div style={{ color: order && order.paymentStatus === 'CHARGED' ? 'green' : 'red' }}>
                        {order && order.paymentStatus === 'CHARGED' ? 'Successful' : order ? order.paymentStatus : 'Pending'}
                    </div>

                ),
                actions: (
                    <Fragment>
                        <Link to={`/admin/orderdetail/${order.order_id}`} className="btn btn-primary py-1 px-2 ml-2">
                            <i className="fa fa-pencil"></i>
                        </Link>
                    </Fragment>
                )
            });
        });

        return data;
    };

    useEffect(() => {
        if (error) {
            toast.dismiss();
            setTimeout(() => {
                toast.error(error, {
                    position: 'bottom-center',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onOpen: () => { dispatch(clearError()) }
                });
            }, 300);
        }

        if (isOrderDeleted) {
            toast.dismiss();
            setTimeout(() => {
                toast.success('Order Deleted Successfully!', {
                    position: 'bottom-center',
                    type: 'success',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onOpen: () => dispatch(clearOrderDeleted())
                });
            }, 300);
        }
    }, [dispatch, error, isOrderDeleted]);

    useEffect(() => {
        if (!orders) {
            dispatch(adminOrdersAction({ startDate, endDate }));
        }
    }, [orders])

    useEffect(() => {
        if (startDate || endDate) {
            dispatch(adminOrdersAction({ startDate, endDate }));
        }
    }, [startDate, endDate])

    return (
        <div>
            <MetaData
                title="All Orders"
                description="Manage all customer orders in one place. Review order details, update statuses, and ensure timely dispatch for every order."
            />


            <div className="row loader-parent">
                <div className="col-12 col-md-2">
                    <div style={{ display: 'flex', flexDirection: 'row', position: 'fixed', top: '0px', zIndex: 99999, backgroundColor: '#fff', minWidth: '100%' }}>
                        <Sidebar isActive={isActive} setIsActive={setIsActive} />
                    </div>
                </div>

                <div className="col-12 col-md-10 smalldevice-space loader-parent" >
                    <h1 className="mb-4 admin-dashboard-x">All Orders</h1>
                    <div className="back-button" onClick={() => navigate(-1)}>
                        <ArrowBackIcon fontSize="small" />
                        <span>Back</span>
                    </div>
                    {/* <input
                        type="date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        className="form-control mb-3 date-input"
                    /> */}


                    <div className="paymentlist-date-filter">
                        <div className='start-date'>
                            <label htmlFor="startDate">From:</label>
                            <input
                                type="date"
                                value={inputStartDate === '' ? startDate : inputStartDate}
                                onChange={(e) => { setStartDate(e.target.value); setInputStartDate(e.target.value) }}
                                className="paymentlist-form-control"
                            />
                        </div>
                        <div className="end-date">
                            <label htmlFor="endDate">To:</label>
                            <input
                                type="date"
                                value={inputEndDate === '' ? endDate : inputEndDate}
                                onChange={(e) => { setEndDate(e.target.value); setInputEndDate(e.target.value) }}
                                className="paymentlist-form-control"
                            />
                        </div>
                    </div>

                    <Fragment>
                        {loading ? (<div className="container loader-loading-center">
                            <Loader />
                        </div>) :
                            <div className='mdb-table' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <MDBDataTable
                                    data={setOrders()}
                                    bordered
                                    hover
                                    className="px-3 product-table"
                                    noBottomColumns
                                />
                            </div>
                        }
                    </Fragment>
                </div>
            </div>
        </div>

    );
};

export default AllOrders;


