// import React, { useEffect, Fragment } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import { deleteOrder, adminOrders as adminOrdersAction } from "../../actions/orderActions"
// import Loader from '../Layouts/Loader';
// import { MDBDataTable } from 'mdbreact';
// import { Slide, toast } from 'react-toastify';
// import Sidebar from "../admin/Sidebar";
// import { clearError } from '../../slices/productsSlice';
// import { clearOrderDeleted, orderDetailClear } from "../../slices/orderSlice";
// import MetaData from '../Layouts/MetaData';
// import { porterClearData, porterClearResponse } from '../../slices/porterSlice';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';


// const PaymentList = ({ isActive, setIsActive }) => {
//     const location = useLocation();
//     const { adminOrders: orders, loading = true, error, isOrderDeleted } = useSelector(state => state.orderState);
//     const dispatch = useDispatch();
//     const navigate = useNavigate();

//     useEffect(() => {
//         dispatch(orderDetailClear());
//         dispatch(porterClearData());
//         dispatch(porterClearResponse());
//     }, [])


//     const setOrders = () => {
//         const data = {
//             columns: [
//                 {
//                     label: 'S.No',
//                     field: 's_no',
//                     sort: 'disabled'
//                 },
//                 {
//                     label: 'Order ID',
//                     field: 'id',
//                     sort: 'disabled'
//                 },
//                 {
//                     label: 'Name',
//                     field: 'name',
//                     sort: 'disabled'
//                 },
//                 {
//                     label: 'Phone No',
//                     field: 'phoneNo',
//                     sort: 'disabled'
//                 },
//                 {
//                     label: 'Amount',
//                     field: 'amount',
//                     sort: 'disabled'
//                 },
//                 {
//                     label: 'PaymentStatus',
//                     field: 'paymentstatus',
//                     sort: 'disabled'
//                 },
//                 {
//                     label: 'Actions',
//                     field: 'actions',
//                     sort: 'disabled'
//                 }
//             ],
//             rows: []
//         };


//         // Sort orders by creation date (newest first)
//         const sortedOrders = orders && [...orders].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

//         sortedOrders && sortedOrders.forEach((order, index) => {
//             data.rows.push({
//                 s_no: index + 1,
//                 id: order.order_id,
//                 name: order.shippingInfo.name,
//                 phoneNo: order.shippingInfo.phoneNo,
//                 amount: `Rs.${order.totalPrice}`,
//                 paymentstatus: (
//                     <div style={{ color: order && order.paymentStatus === 'CHARGED' ? 'green' : 'red' }}>
//                         {order && order.paymentStatus === 'CHARGED' ? 'Success' : order ? order.paymentStatus : 'Pending'}
//                     </div>
//                 ),
//                 actions: <Link to={`/admin/orderdetail/${order.order_id}`} className="btn btn-success" >
//                     {/* <i className='fa fa-eye'></i> */}
//                     View
//                 </Link>
//             });
//         });

//         return data;
//     };


//     useEffect(() => {
//         if (error) {
//             toast.dismiss();
//             setTimeout(() => {
//                 toast.error(error, {
//                     position: 'bottom-center',
//                     type: 'error',
//                     autoClose: 700,
//                     transition: Slide,
//                     hideProgressBar: true,
//                     className: 'small-toast',
//                     onOpen: () => { dispatch(clearError()) }
//                 });
//             }, 300);
//             return;
//         }
//         if (isOrderDeleted) {
//             toast.dismiss();
//             setTimeout(() => {
//                 toast.success('Order Deleted Successfully!', {
//                     position: 'bottom-center',
//                     type: 'success',
//                     autoClose: 700,
//                     transition: Slide,
//                     hideProgressBar: true,
//                     className: 'small-toast',
//                     onOpen: () => dispatch(clearOrderDeleted())
//                 });
//             }, 300);
//             return;
//         }
//     }, [dispatch, error, isOrderDeleted]);

//     useEffect(() => {
//         if (!orders) {
//             dispatch(adminOrdersAction());
//         }
//     }, [orders])

//     return (
//         <div>
//             <MetaData
//                 title="Payment List"
//                 description="Track all payments received from customers. View payment statuses and handle any pending or failed transactions."
//             />


//             <div className="row loader-parent">
//                 <div className="col-12 col-md-2">
//                     <div style={{ display: 'flex', flexDirection: 'row', position: 'fixed', top: '0px', zIndex: 99999, backgroundColor: '#fff', minWidth: '100%' }}>
//                         <Sidebar isActive={isActive} setIsActive={setIsActive} />
//                     </div>
//                 </div>
//                 <div className="col-12 col-md-10 smalldevice-space loader-parent" >
//                     <h1 className="mb-4 admin-dashboard-x" >Payment List</h1>
//                     <div className="">
//                             <span className="back-button" onClick={() => navigate(-1)}>
//                                 <ArrowBackIcon fontSize="small" />
//                                 <span>Back</span>
//                             </span>

//                         </div>
//                     <Fragment>
//                         {loading ? (
//                             <div className="container loader-loading-center">
//                                 <Loader />
//                             </div>


//                         ) :
//                             <div className='mdb-table' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//                                 <MDBDataTable
//                                     data={setOrders()}
//                                     bordered
//                                     hover
//                                     className="px-3 product-table"
//                                     noBottomColumns
//                                 />
//                             </div>
//                         }
//                     </Fragment>

//                 </div>
//             </div>
//         </div>
//     );
// };

// export default PaymentList;


import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import { deleteOrder, adminOrders as adminOrdersAction } from "../../actions/orderActions";
import Loader from '../Layouts/Loader';
import { MDBDataTable } from 'mdbreact';
import { Slide, toast } from 'react-toastify';
import Sidebar from "../admin/Sidebar";
import { clearError } from '../../slices/productsSlice';
import { clearOrderDeleted, orderDetailClear } from "../../slices/orderSlice";
import MetaData from '../Layouts/MetaData';
import { porterClearData, porterClearResponse } from '../../slices/porterSlice';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const PaymentList = ({ isActive, setIsActive }) => {
    const { adminOrders: orders, error, isOrderDeleted } = useSelector(state => state.orderState);
    const dispatch = useDispatch();
    const navigate = useNavigate();
console.log("orders",orders)
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedStatus, setSelectedStatus] = useState("");

    useEffect(() => {
        dispatch(orderDetailClear());
        dispatch(porterClearData());
        dispatch(porterClearResponse());
    }, []);

    useEffect(() => {
        if (!orders) {
            dispatch(adminOrdersAction()); 
        } else{
            setLoading(false);
        }
    }, [orders]);

    const paymentStatusOptions = [
        "ALL",
        "CHARGED",
        "INITIATED",
        "PENDING",
        "PENDING_VBV",
        "AUTHORIZATION_FAILED",
        "AUTHENTICATION_FAILED",
        "NEW",
        "AUTHORIZING",
    ];

    const setOrders = () => {
        const data = {
            columns: [
                { label: 'S.No', field: 's_no', sort: 'disabled' },
                { label: 'Order ID', field: 'id', sort: 'disabled' },
                { label: 'Name', field: 'name', sort: 'disabled' },
                { label: 'Phone No', field: 'phoneNo', sort: 'disabled' },
                { label: 'Amount', field: 'amount', sort: 'disabled' },
                { label: 'PaymentStatus', field: 'paymentstatus', sort: 'disabled' },
                { label: 'Actions', field: 'actions', sort: 'disabled' }
            ],
            rows: []
        };
        //     // Filter and sort orders
        //     const filteredOrders = (orders || []).filter(order => {
        //         const orderDate = new Date(order.createdAt);
        //         if (startDate && endDate) {
        //             return orderDate >= startDate && orderDate <= endDate;
        //         }
        //         return true;
        //     }).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        //     console.log("Orders: ", orders);
        //     console.log("filteredOrders",filteredOrders)


        //     filteredOrders.forEach((order, index) => {
        //         data.rows.push({
        //             s_no: index + 1,
        //             id: order.order_id,
        //             name: order.shippingInfo.name,
        //             phoneNo: order.shippingInfo.phoneNo,
        //             amount: `Rs.${order.totalPrice}`,
        //             paymentstatus: (
        //                 <div style={{ color: order.paymentStatus === 'CHARGED' ? 'green' : 'red' }}>
        //                     {order.paymentStatus === 'CHARGED' ? 'Success' : order?.paymentStatus}
        //                 </div>
        //             ),
        //             actions: (
        //                 <Link to={`/admin/orderdetail/${order.order_id}`} className="btn btn-success">
        //                     View
        //                 </Link>
        //             )
        //         });
        //     });

        //     return data;

        const filteredOrders = (orders || [])
            .filter(order => {
                const orderDate = new Date(order.createdAt);
                const normalizedStatus = selectedStatus.toLowerCase(); // Convert selected status to lowercase
                const orderStatus = order.paymentStatus?.toLowerCase(); // Convert order status to lowercase

                const statusMatch =
                    normalizedStatus === "all" || !selectedStatus
                        ? true
                        : orderStatus === normalizedStatus;

                if (startDate && endDate) {
                    return (
                        statusMatch &&
                        orderDate >= startDate &&
                        orderDate <= endDate
                    );
                }
                return statusMatch;
            })
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        filteredOrders.forEach((order, index) => {
            data.rows.push({
                s_no: index + 1,
                id: order.order_id,
                name: order.shippingInfo.name,
                phoneNo: order.shippingInfo.phoneNo,
                amount: `Rs.${order.totalPrice}`,
                paymentstatus: (
                    <div style={{ color: order.paymentStatus === 'CHARGED' ? 'green' : 'red' }}>
                        {order.paymentStatus === 'CHARGED' ? 'SUCCESS' : order?.paymentStatus.toUpperCase()}
                    </div>
                ),
                actions: (
                    <Link to={`/admin/orderdetail/${order.order_id}`} className="btn btn-success">
                        View
                    </Link>
                )
            });
        });

        return data;

    };

    useEffect(() => {
        if (error) {
            toast.error(error, {
                position: 'bottom-center',
                autoClose: 700,
                transition: Slide,
                hideProgressBar: true,
                onOpen: () => dispatch(clearError())
            });
        }
        if (isOrderDeleted) {
            toast.success('Order Deleted Successfully!', {
                position: 'bottom-center',
                autoClose: 700,
                transition: Slide,
                hideProgressBar: true,
                onOpen: () => dispatch(clearOrderDeleted())
            });
        }
    }, [dispatch, error, isOrderDeleted]);

    return (
        <div>
            <MetaData title="Payment List" description="Track all payments received from customers." />
            <div className="row loader-parent">
                <div className="col-12 col-md-2">
                    <Sidebar isActive={isActive} setIsActive={setIsActive} />
                </div>
                <div className="col-12 col-md-10 smalldevice-space loader-parent">
                    <h1 className="mb-4 admin-dashboard-x">Payment Report</h1>
                    <div>
                        <span className="back-button" onClick={() => navigate(-1)}>
                            <ArrowBackIcon fontSize="small" />
                            <span>Back</span>
                        </span>
                    </div>
                    <div className="paymentlist-container">
                        <div className='paymentlist-date-filter'>
                        <div className='start-date'>
                        <label htmlFor="startDate">Start Date:</label>
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                placeholderText="mm/dd/yyyy"
                                className="paymentlist-form-control"
                            />
                        </div>
                        <div className="end-date">
                        <label htmlFor="endDate">End Date:</label>
                            <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                placeholderText="mm/dd/yyyy "
                                className="paymentlist-form-control"
                                // style={{ marginLeft: '10px' }}
                            />
                        </div>
                        </div>
                        {/* Payment Status Filter */}
                        <div className="filter-status" >
                            <label htmlFor="paymentStatusFilter" className="paymentlist-form-label">Filter:</label>
                            <select
                                id="paymentStatusFilter"
                                className="paymentlist-form-select"
                                value={selectedStatus}
                                onChange={(e) => setSelectedStatus(e.target.value)}
                            >
                                {paymentStatusOptions.map((status) => (
                                    <option key={status} value={status}>
                                        {status}
                                    </option>
                                ))}
                            </select>
                        </div>

                    </div>


                    <Fragment>
                        {loading ? (
                            <div className="container loader-loading-center">
                                <Loader />
                            </div>
                        ) : (
                            <div className='mdb-table'>
                                <MDBDataTable
                                    data={setOrders()}
                                    bordered
                                    hover
                                    className="px-3 product-table"
                                    noBottomColumns
                                />
                            </div>
                        )}
                    </Fragment>
                </div>
            </div>
        </div>
    );
};

export default PaymentList;

