import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { orderCompleted } from "../../slices/cartSlice";
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import MetaData from '../Layouts/MetaData';
import CryptoJS from 'crypto-js';
import { createOrder, orderDetail as orderDetailAction, userOrders as userOrdersAction } from '../../actions/orderActions';


const PaymentConfirm = () => {

  const { id } = useParams();
  const location = useLocation();
  const [paymentStatus, setPaymentStatus] = useState('LOADING');
  const [paymentDetails, setPaymentDetails] = useState({});
  const [orderDateString, setOrderDateString] = useState(null);
  const dispatch = useDispatch();
  const { userOrders, orderDetail } = useSelector(state => state.orderState)
  console.log("orderDetail", orderDetail)
  console.log("userOrders", userOrders)
  console.log("orderDate as Date", orderDateString);

  const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;

  // Function to decrypt the data
  const decryptData = (id, encryptionKey) => {
    const bytes = CryptoJS.AES.decrypt(id, encryptionKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  // Decrypt the order ID
  const orderId = decryptData(id, encryptionKey);
  console.log("Decrypted Order ID:", orderId);

  useEffect(() => {
    dispatch(userOrdersAction());
    dispatch(orderDetailAction(orderId));
  }, [orderId]);

  useEffect(() => {
    async function fetchData() {
      try {
        const url = `/api/v1/handleJuspayResponse/${encodeURIComponent(id)}`;
        const { data } = await axios.get(url, { withCredentials: true });
        if (data && data.sessionResponse) {
          setPaymentStatus(data.sessionResponse.status);
          setPaymentDetails(data.sessionResponse);
        } else {
          setPaymentStatus('UNKNOWN');
        }
      } catch (error) {
        setPaymentStatus('ERROR');
      }
    }
    fetchData();
    dispatch(orderCompleted())
  }, [id]);


  // const lastOrder = userOrders?.reduce((latestOrder, currentOrder) => {
  //   return new Date(currentOrder.createdAt) > new Date(latestOrder.createdAt) ? currentOrder : latestOrder;
  // }, userOrders[0]);

  // const lastOrderDate = lastOrder ? lastOrder.orderDate : null;
  // console.log("lastOrderDate",lastOrderDate)

  const deliveryDate = orderDetail?.orderDate;
  console.log("deliveryDate", deliveryDate)
  // const orderDate = new Date(orderDetail?.orderDate).toISOString().split('T')[0];
  // console.log("orderDate", orderDate)

  useEffect(() => {
    if (deliveryDate) {
      const orderDate = new Date(deliveryDate).toISOString().split('T')[0];
      console.log("orderDate", orderDate)
      console.log(typeof orderDate);
      setOrderDateString(orderDate);
    }

  }, [deliveryDate])



  const renderPaymentDetails = () => {
    const { amount, payment_method, order_id, txn_id, date_created } = paymentDetails;
    return (
      <div style={{marginTop:'80px'}}>
        <MetaData
          title="Payment Confirmation"
          description="Your payment has been successfully processed. Review your payment confirmation details and proceed with your order tracking."
        />

        <div className="payment-details">
          <h1 >{getTitle(paymentStatus)}</h1>
          <img src={getIcon(paymentStatus)} alt={paymentStatus} />
          <table>
            <tbody>
              <tr>
                <td>Amount</td>
                <td>₹ {parseFloat(amount).toFixed(2)}</td>
              </tr>
              <tr>
                <td>Payment method</td>
                <td>{(payment_method ? payment_method : 'N/A')}</td>
              </tr>
              <tr>
                <td>Status</td>
                <td>{getStatusText(paymentStatus)}</td>
              </tr>
              <tr>
                <td>order Id</td>
                <td>{order_id}</td>
              </tr>
              <tr>
                <td>Transaction Id</td>
                <td>{(txn_id ? txn_id : 'N/A')}</td>
              </tr>
              <tr>
                <td>Order Date</td>
                {/* <td>
                  {(() => {
                    const date = new Date(date_created);
                    if (isNaN(date)) return 'Invalid date';

                    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
                    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

                    // Extract UTC values
                    const dayName = days[date.getUTCDay()];
                    const day = String(date.getUTCDate()).padStart(2, '0');
                    const month = months[date.getUTCMonth()];
                    const year = date.getUTCFullYear();

                    return `${dayName}, ${day}/${month}/${year}`;
                  })()}
                </td> */}
                <td>
                  {(() => {
                    const date = new Date(date_created);

                    if (isNaN(date)) return 'Invalid date';

                    // Convert to Indian Standard Time
                    const options = {
                      weekday: 'short', // e.g., "Mon"
                      day: '2-digit',  // e.g., "06"
                      month: 'short',  // e.g., "Jan"
                      year: 'numeric', // e.g., "2025"
                      timeZone: 'Asia/Kolkata',
                    };
                    console.log("date_created", date_created)

                    return new Intl.DateTimeFormat('en-IN', options).format(date);
                  })()}
                </td>


              </tr>
              <tr>
                <td>Delivery Date</td>
                <tr>
                  {/* <td>Delivery Date</td> */}
                  {/* <td>
                    {(() => {
                      const date = new Date(deliveryDate);
                      if (isNaN(date)) return 'Invalid date';

                      const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
                      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

                      // Extract UTC values
                      const dayName = days[date.getUTCDay()];
                      const day = String(date.getUTCDate()).padStart(2, '0');
                      const month = months[date.getUTCMonth()];
                      const year = date.getUTCFullYear();

                      return `${dayName}, ${day}/${month}/${year}`;
                    })()}
                  </td> */}
                  <td>
                    {orderDateString ?
                    (() => {
                      const date = new Date(orderDateString);
                      if (isNaN(date)) return 'Invalid date';

                      // Convert to Indian Standard Time
                      const options = {
                        weekday: 'short', // e.g., "Mon"
                        day: '2-digit',  // e.g., "06"
                        month: 'short',  // e.g., "Jan"
                        year: 'numeric', // e.g., "2025"
                        timeZone: 'Asia/Kolkata',
                      };

                      return new Intl.DateTimeFormat('en-IN', options).format(date);
                    })(): (<>-</>)
                    }
                  </td>
                 

                </tr>


              </tr>
            </tbody>
          </table>
          <div className="link-container">
            <Link to="/orders" className="orders-link">Go to Orders</Link>
          </div>
        </div>
      </div>
    );
  };

  const getTitle = (status) => {
    switch (status) {
      case 'CHARGED':
        return 'Transaction Successful!';
      case 'PENDING':
        return 'Transaction Pending';
      case 'PENDING_VBV':
        return 'Transaction Pending';
      case 'AUTHORIZATION_FAILED':
        return 'Transaction Failed';
      case 'AUTHENTICATION_FAILED':
        return 'Transaction Failed';
      case 'NEW':
        return 'Transaction Cancelled';
      case 'AUTHORIZING':
        return 'Transaction Pending';
      default:
        return 'Transaction Status';
    }
  };

  const getIcon = (status) => {
    switch (status) {
      case 'CHARGED':
        return 'https://img.icons8.com/color/48/000000/checkmark.png';
      case 'PENDING':
        return 'https://img.icons8.com/color/48/000000/hourglass-sand-bottom.png';
      case 'PENDING_VBV':
        return 'https://img.icons8.com/color/48/000000/hourglass-sand-bottom.png';
      case 'AUTHORIZATION_FAILED':
        return 'https://img.icons8.com/color/48/000000/cancel.png';
      case 'AUTHENTICATION_FAILED':
        return 'https://img.icons8.com/color/48/000000/cancel.png';
      case 'NEW':
        return 'https://img.icons8.com/color/48/000000/cancel.png';
      case 'AUTHORIZING':
        return 'https://img.icons8.com/color/48/000000/hourglass-sand-bottom.png';
      default:
        return '❌';
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case 'CHARGED':
        return '✔️ Successful';
      case 'PENDING':
        return '⌛ Pending';
      case 'PENDING_VBV':
        return '⌛ Pending';
      case 'AUTHORIZATION_FAILED':
        return '❌ Failed';
      case 'AUTHENTICATION_FAILED':
        return '❌ Failed';
      case 'NEW':
        return '❌ Cancelled';
      case 'AUTHORIZING':
        return '⌛ AUTHORIZING';
      default:
        return '❓ Unknown';
    }
  };

  return (
    <div className="payment-confirm">

      {paymentStatus === 'LOADING' && <p style={{marginTop:'120px'}}>Loading...</p>}
      {paymentStatus !== 'LOADING' && renderPaymentDetails()}



    </div>
  );
};

export default PaymentConfirm;

