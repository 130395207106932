import { Fragment, useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { orderDetail as orderDetailAction, updateOrder, porterOrder, RemoveOrderResponse, adminOrders } from "../../actions/orderActions";
import { CancelOrderResponse, createPorterOrderResponse, getPackedOrder, getporterOrder, packedOrder } from "../../actions/porterActions";
import { Slide, toast } from "react-toastify";
import { clearOrderUpdated, clearError } from "../../slices/orderSlice";
import { v4 as uuidv4 } from 'uuid';
import Loader from "../Layouts/Loader";
import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import ReactDOM from 'react-dom';
import JasInvoice from "../Layouts/JasInvoice";
import MetaData from "../Layouts/MetaData";
import LoaderButton from "../Layouts/LoaderButton";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


const Dispatch = ({ isActive, setIsActive }) => {
    const location = useLocation();
    const { loading, isOrderUpdated, error, orderDetail, porterloading, porterOrderDetail, orderRemoveResponse, orderRemoveError } = useSelector(state => state.orderState);
    const { products } = useSelector((state) => state.productsState);
    const { porterOrderData, porterOrderResponse, porterCancelResponse, porterCancelError, portererror, getpackedOrderData } = useSelector((state) => state.porterState);
    const { user = {}, orderItems = [], shippingInfo = {}, totalPrice = 0, statusResponse = {} } = orderDetail;
    const [orderStatus, setOrderStatus] = useState("Processing");
    const [dropStatus, setDropStatus] = useState("");
    const [editableWeights, setEditableWeights] = useState(orderDetail && orderItems && orderItems.map(item => item.productWeight)); // Initial state for weights
    const [originalWeights, setOriginalWeights] = useState(orderItems.map(item => item.productWeight)); // Original weights
    const [selectedItems, setSelectedItems] = useState([]);
    const { id } = useParams();
    const [refreshData, setRefreshData] = useState(false)
    const invoiceRef = useRef();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);

    const handlePrint = useReactToPrint({
        content: () => invoiceRef.current,
        documentTitle: `Invoice_${id || 'unknown'}`, // Set the document title.
        onAfterPrint: () => {
            // toast.dismiss();
            // setTimeout(() => {
            //     toast.success("Invoice downloaded successfully.", {
            //         position: 'bottom-center',
            //         type: 'error',
            //         autoClose: 700,
            //         transition: Slide,
            //         hideProgressBar: true,
            //         className: 'small-toast',
            //     });
            // }, 300);
            console.log("Invoice downloaded successfully")
        },

    });

    useEffect(() => {
        if (orderDetail && orderDetail.order_id) {
            setOrderStatus(orderDetail.orderStatus);
            setDropStatus(orderDetail.orderStatus);
        }
        if (orderItems) {
            setEditableWeights(orderItems.map(item => item.productWeight))
        }
    }, [orderDetail]);

    const submitHandler = async (e) => {
        e.preventDefault();
        const requestId = `Jas_0_${uuidv4()}`;
        const porterData = {
            "request_id": requestId,
            "delivery_instructions": {
                "instructions_list": [
                    {
                        "type": "text",
                        "description": "handle with care"
                    }
                ]
            },
            "pickup_details": {
                "address": {
                    "apartment_address": "29",
                    "street_address1": "Reddy St,Nerkundram",
                    "landmark": "Jas Fruits And Vegetables",
                    "city": "Chennai",
                    "state": "Tamilnadu",
                    "pincode": "600107",
                    "country": "India",
                    "lat": 13.0671844,
                    "lng": 80.1775087,
                    "contact_details": {
                        "name": "Bala Santhanam",
                        "phone_number": "+919176720068"
                    }
                }
            },
            "drop_details": {
                "address": {
                    "street_address1": shippingInfo.address,
                    "landmark": shippingInfo.landmark,
                    "city": shippingInfo.city,
                    "state": shippingInfo.state,
                    "pincode": shippingInfo.postalCode,
                    "country": shippingInfo.country,
                    "lat": shippingInfo && shippingInfo.latitude && shippingInfo.latitude,
                    "lng": shippingInfo && shippingInfo.longitude && shippingInfo.longitude,
                    "contact_details": {
                        "name": user && user.name,
                        "phone_number": shippingInfo && shippingInfo.phoneNo && shippingInfo.phoneNo
                    }
                }
            },
        };

        const reqPorterData = {
            user: user,
            request_id: requestId,
            invoiceNumber:getpackedOrderData?.invoiceNumber,
            user_id: user._id,
            order_id: orderDetail.order_id,
            porterData: porterData,
            updatedItems: getpackedOrderData.updatedItems,
            detailedTable: getpackedOrderData && getpackedOrderData.dispatchedTable,
            orderDetail:getpackedOrderData?.orderDetail,
            totalDispatchedAmount: Number(getpackedOrderData && getpackedOrderData.totalDispatchedAmount),
            totalRefundableAmount: Number(getpackedOrderData && getpackedOrderData.totalRefundableAmount),
            orderDate:getpackedOrderData?.orderDate,
            payment_method:getpackedOrderData?.payment_method,
        };
        if (orderDetail && shippingInfo && shippingInfo.latitude && shippingInfo.longitude && user && user.name && shippingInfo.phoneNo) {

            try {

                await dispatch(porterOrder({ id: orderDetail.order_id, reqPorterData }));
            } catch (error) {
                toast.dismiss();
                setTimeout(() => {
                    toast.error(error, {
                        position: 'bottom-center',
                        type: 'error',
                        autoClose: 700,
                        transition: Slide,
                        hideProgressBar: true,
                        className: 'small-toast',
                    });
                }, 300);
            }
        }
        else {
            toast.dismiss();
            setTimeout(() => {
                toast.error('Can’t Dispatch because some user drop details are missing!', {
                    position: 'bottom-center',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                });
            }, 300);
        }
    };

    useEffect(() => {
        dispatch(orderDetailAction(id));
        dispatch(getporterOrder({ order_id: id }))
        dispatch(getPackedOrder({ order_id: id }))
        setRefreshData(true)

    }, [dispatch, id, porterOrderDetail]);

    useEffect(() => {
        if (isOrderUpdated) {
            toast.dismiss();
            setTimeout(() => {
                toast.success('Order Dispatched Successfully!', {
                    position: 'bottom-center',
                    type: 'success',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onOpen: () => dispatch(clearOrderUpdated())
                });
                // dispatch(adminOrders());
                // dispatch(adminOrders({ startDate, endDate }));

            }, 300);
            setShowModal(false);

        }
    }, [isOrderUpdated])

    useEffect(() => {
        if (error) {
            toast.dismiss();
            setTimeout(() => {
                toast.error(error, {
                    position: 'bottom-center',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onOpen: () => { dispatch(clearError()) }
                });
            }, 300);
            return;
        }
    }, [error])

    useEffect(() => {
        if (porterOrderData && refreshData) {
            dispatch(createPorterOrderResponse({ order_id: porterOrderData && porterOrderData.order_id, porterOrder_id: porterOrderData?.porterOrder?.order_id }))
        }
    }, [porterOrderData])

    useEffect(() => {
        if (refreshData && porterOrderResponse) {
            dispatch(getporterOrder({ order_id: id }))
            setRefreshData(false)
        }
    }, [refreshData, porterOrderResponse])



    const [trackurl, setTrackurl] = useState(false);
    const handleClick = (tracking_url) => {
        setTrackurl(true)
        window.location.href = tracking_url;
    }

    const handeldispatch = () => {
        setShowModal(true);
    }
    const handleCancel = () => {
        setShowModal(false);
    }

    const capitalizeFirstLetter = (str) => {
        return str
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    return (
        <div>
            <MetaData
                title="Dispatch"
                description="Manage the dispatch of orders, track shipping status, and ensure products reach customers on time."
            />


            <div className="row loader-parent">
                <div className="col-12 col-md-2">
                    <div style={{ display: 'flex', flexDirection: 'row', position: 'fixed', top: '0px', zIndex: 99999, backgroundColor: '#fff', minWidth: '100%' }}>
                        <Sidebar isActive={isActive} setIsActive={setIsActive} />
                    </div>
                </div>

                <div className="col-12 col-md-10 col-sm-10 smalldevice-space container order-detail-container loader-parent">
                    <div className="back-button" onClick={() => navigate(-1)}>
                        <ArrowBackIcon fontSize="small" />
                        <span>Back</span>
                    </div>

                    {
                        loading ? (
                            <div className="container loader-loading-center">
                                <Loader />
                            </div>
                        ) : (
                            <Fragment>
                                <div className="col-12 col-lg-12 mt-5 order-details">
                                    <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'nowrap' }}>
                                        <h1 className="order-id-title mb-4" >Order ID: {orderDetail.order_id}</h1>
                                        {
                                            porterOrderData && porterOrderData.tracking_url && (
                                                <button onClick={() => handleClick(porterOrderData.tracking_url)} disabled={trackurl} className='tracking-btn' >
                                                    Track Order
                                                </button>
                                            )
                                        }
                                    </div>


                                    <h4 className="shipping-title mb-2">Shipping Info</h4>
                                    <div className="shipping-info">
                                        <div><b>Name:</b> {shippingInfo.name}</div>
                                        <div><b>Phone:</b> +91 {shippingInfo.phoneNo}</div>
                                        <div className='order-address-formatted'>
                                            <b>Address:</b>
                                            {shippingInfo.address && `${shippingInfo.address},`}
                                            {shippingInfo.area && `${shippingInfo.area},`}
                                            {shippingInfo.landmark && `${shippingInfo.landmark},`}
                                            {shippingInfo.city && `${shippingInfo.city}`}
                                            {shippingInfo.postalCode && -`${shippingInfo.postalCode}`}
                                        </div>
                                        <div><b>Total Amount:</b> ₹ {parseFloat(totalPrice).toFixed(2)}</div>
                                        <div><b>Online Payment:</b> ₹ {orderDetail?.paidOnline}</div>
                                        <div><b>Wallet Payment:</b> ₹ {orderDetail?.paidWallet}</div>
                                        {orderDetail && orderDetail.statusResponse && orderDetail.statusResponse.payment_method ? (
                                            <div><b>Payment Mode:</b> {orderDetail && orderDetail?.statusResponse && orderDetail.statusResponse.payment_method}</div>

                                        ) :(
                                            <div><b>Payment Mode:</b> {orderDetail && orderDetail?.payment_method}</div>
                                        )

                                        }
                                    </div>
                                    <hr />

                                    <div className="status-container" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div><b>Payment Status:</b></div>
                                            <div style={{ color: orderDetail && orderDetail.paymentStatus === 'CHARGED' ? 'green' : 'red', marginLeft: '10px' }}>
                                                <b>{orderDetail && orderDetail.paymentStatus === 'CHARGED' ? 'Success' : orderDetail ? orderDetail.paymentStatus : 'Pending'}</b>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center', marginRight: '50px' }}>
                                            <div><b>Order Status:</b></div>
                                            <div style={{ color: orderStatus && orderStatus.includes('Delivered') ? 'green' : 'red', marginLeft: '10px' }}>
                                                <b>{orderStatus}</b>
                                            </div>
                                        </div>

                                    </div>
                                    {porterOrderData && porterOrderData.porterResponse && (
                                        <Fragment>
                                            <hr />
                                            <h4 className="delivery-title mb-2">Delivery Details:</h4>
                                            <div className="delivery-details">
                                                <div className="detail-column">
                                                    <div className="detail-row">
                                                        <h6>Order ID:</h6>
                                                        <p>{porterOrderData.porterResponse.order_id && porterOrderData.porterResponse.order_id}</p>
                                                    </div>
                                                    <div className="detail-row">
                                                        <h6>Estimated Fair details:</h6>
                                                        <p>Currency: {porterOrderData.porterResponse.fare_details && porterOrderData.porterResponse.fare_details.estimated_fare_details && porterOrderData.porterResponse.fare_details.estimated_fare_details.currency && porterOrderData.porterResponse.fare_details.estimated_fare_details.currency || "INR"}</p>
                                                        <p>Minor Amount: {porterOrderData.porterResponse.fare_details && porterOrderData.porterResponse.fare_details.estimated_fare_details && porterOrderData.porterResponse.fare_details.estimated_fare_details.minor_amount && porterOrderData.porterResponse.fare_details.estimated_fare_details.minor_amount || "N/A"}</p>
                                                    </div>


                                                    <div className="detail-row">
                                                        <h6>Order Timings:</h6>

                                                        {porterOrderData.porterResponse && porterOrderData.porterResponse.order_timings && porterOrderData.porterResponse.order_timings.pickup_time ?
                                                            (
                                                                <p>Pickup Time:  {new Date(porterOrderData.porterResponse.order_timings.pickup_time * 1000).toLocaleString()}</p>
                                                            ) : (<p>Pickup Time:  N/A</p>)
                                                        }

                                                        {porterOrderData.porterResponse && porterOrderData.porterResponse.order_timings && porterOrderData.porterResponse.order_timings.order_accepted_time ?
                                                            (
                                                                <p>Order Accepted Time:  {new Date(porterOrderData.porterResponse.order_timings.order_accepted_time * 1000).toLocaleString()}</p>
                                                            ) : (<p>Order Accepted Time:  N/A</p>)
                                                        }

                                                        {porterOrderData.porterResponse && porterOrderData.porterResponse.order_timings && porterOrderData.porterResponse.order_timings.order_started_time ?
                                                            (
                                                                <p>Order Started Time:  {new Date(porterOrderData.porterResponse.order_timings.order_started_time * 1000).toLocaleString()}</p>
                                                            ) : (<p>Order Started Time:  N/A</p>)
                                                        }
                                                        {porterOrderData.porterResponse && porterOrderData.porterResponse.order_timings && porterOrderData.porterResponse.order_timings.order_ended_time ?
                                                            (
                                                                <p>Order Ended Time:  {new Date(porterOrderData.porterResponse.order_timings.order_ended_time * 1000).toLocaleString()}</p>
                                                            ) : (<p>Order Ended Time:  N/A</p>)
                                                        }
                                                    </div>


                                                </div>
                                                <div className="detail-column">
                                                    <div className="detail-row">
                                                        <h6>Delivery Status:</h6>
                                                        <p>{porterOrderData.porterResponse.status}</p>
                                                    </div>

                                                    <div className="detail-row">
                                                        <h6>Actual Fare Details:</h6>
                                                        <p>Currency: {porterOrderData.porterResponse.fare_details && porterOrderData.porterResponse.fare_details.actual_fare_details && porterOrderData.porterResponse.fare_details.actual_fare_details.currency && porterOrderData.porterResponse.fare_details.actual_fare_details.currency || "INR"}</p>
                                                        <p>Minor Amount: {porterOrderData.porterResponse.fare_details && porterOrderData.porterResponse.fare_details.actual_fare_details && porterOrderData.porterResponse.fare_details.actual_fare_details.minor_amount && porterOrderData.porterResponse.fare_details.actual_fare_details.minor_amount || "N/A"}</p>
                                                    </div>

                                                    {
                                                        porterOrderData.porterResponse.partner_info && (
                                                            <div className="detail-row">
                                                                <h5>Delivery Partner:</h5>
                                                                <p>Name: {porterOrderData.porterResponse.partner_info.name}</p>
                                                                <p>Mobile: {porterOrderData.porterResponse.partner_info.mobile.country_code} {porterOrderData.porterResponse.partner_info.mobile.mobile_number}</p>
                                                                {porterOrderData.porterResponse.partner_info.partner_secondary_mobile && (
                                                                    <>
                                                                        <p>Secondary Mobile: {porterOrderData.porterResponse.partner_info.partner_secondary_mobile.country_code} {porterOrderData.porterResponse.partner_info.partner_secondary_mobile.mobile_number}</p>
                                                                    </>
                                                                )
                                                                }
                                                                <p>Vehicle Number: {porterOrderData.porterResponse.partner_info.vehicle_number}</p>
                                                                <p>Vehicle Type: {porterOrderData.porterResponse.partner_info.vehicle_type}</p>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </Fragment>
                                    )}


                                    <hr />
                                    <h4 className="order-items my-4">Order Items:</h4>

                                    <div className="invoice-table-container">
                                        <div className="updatetable-responsive">
                                            <table className="updatetable updatetable-bordered">
                                                <thead>
                                                    <tr>
                                                        {getpackedOrderData && getpackedOrderData.dispatchedTable && (
                                                            <>
                                                                <th>S.No</th>
                                                                <th>Image</th>
                                                                <th>Name</th>
                                                                <th>Price</th>
                                                                <th>Ordered Quantity</th>
                                                                <th>Dispatched Quantity</th>
                                                                <th>Total Amount</th>
                                                            </>
                                                        )}
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {getpackedOrderData && getpackedOrderData.dispatchedTable && (
                                                        getpackedOrderData.dispatchedTable.map((item, index) => (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>
                                                                    <img src={item.image} alt={item.name} className="updateTableproduct-image" />
                                                                </td>
                                                                <td>{item && item.range ? `${capitalizeFirstLetter(item.name)} (${item.range})` : `${capitalizeFirstLetter(item.name)}`} </td>
                                                                <td>₹ {parseFloat(item.pricePerKg).toFixed(2)}</td>
                                                                <td>{item.orderedWeight}  {item.measurement}</td>
                                                                <td>{item.dispatchedWeight}  {item.measurement}</td>
                                                                <td>₹ {parseFloat(item.pricePerKg * item.dispatchedWeight).toFixed(2)}</td>
                                                            </tr>
                                                        ))

                                                    )}
                                                    <tr>
                                                        <td colSpan="6" style={{ textAlign: 'right' }}><strong>Total Dispatched Amount</strong></td>
                                                        <td className="amount">
                                                            <strong>
                                                            ₹ {getpackedOrderData && getpackedOrderData.totalDispatchedAmount
                                                                    ? parseFloat(getpackedOrderData.totalDispatchedAmount).toFixed(2)
                                                                    : "0.00"}
                                                            </strong>
                                                        </td>

                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <hr />
                                    <div>

                                        <button
                                            className='btn btn-primary'
                                            onClick={handeldispatch}
                                            disabled={dropStatus === "Dispatched" || dropStatus === "Delivered" || dropStatus === "Cancelled"}
                                            style={{ cursor: (dropStatus === "Dispatched" || dropStatus === "Delivered" || dropStatus === "Cancelled") ? 'not-allowed' : 'pointer' }}
                                        >
                                            {dropStatus === "Dispatched" ? "Already Dispatched" : dropStatus === "Delivered" ? "Order Delivered" : dropStatus === "Cancelled" ? "Order Cancelled" : "Dispatch"}
                                        </button>
                                        {
                                            dropStatus === 'Packed' && (
                                                <button
                                                className='btn btn-primary'
                                                style={{ textDecoration: 'none', color: 'blue',marginLeft:'30px' }}
                                            >
                                            <Link to={`/admin/order/${id}`} style={{ textDecoration: 'none', color: '#fff'}}>
                                                Repack <ArrowForwardIcon fontSize="small" />
                                            </Link>
                                            </button>
                                            )
                                        }

                                    </div>

                                    {showModal && (
                                        <div className="modal" tabIndex="-1" role="dialog" style={modalStyle}>
                                            <div className="modal-dialog" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <div>Are you sure you want to dispatch this items?</div>
                                                        <button type="button" className="close" onClick={handleCancel}>
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">

                                                        {getpackedOrderData && getpackedOrderData.dispatchedTable && (
                                                            <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                                                <ul style={{ listStyleType: 'circle', paddingLeft: '20px' }}>
                                                                    {getpackedOrderData.dispatchedTable
                                                                        .filter(item => item.pricePerKg * item.dispatchedWeight > 0) // Filter items with total amount > 0
                                                                        .map((item, index) => (
                                                                            <li key={index} style={{ paddingBottom: '10px' }}>
                                                                                <strong>{item.name}</strong> - {item.dispatchedWeight} {item.measurement && item.measurement == 'Grams' ? 'Piece' : item.measurement}
                                                                            </li>
                                                                        ))}
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-primary" onClick={submitHandler} disabled={porterloading}>
                                                            {porterloading ? <LoaderButton fullPage={false} size={20} /> : (
                                                                <span>  Continue</span>
                                                            )

                                                            }

                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {(getpackedOrderData && porterOrderData) &&(
                                        <div style={{ marginTop: '20px' }}>
                                            <button onClick={handlePrint} className='btn btn-primary'>Download Invoice</button>
                                            {ReactDOM.createPortal(
                                                <div style={{ position: 'absolute', top: '-9999px', left: '-9999px', zIndex: '-9999999999' }}>
                                                    <JasInvoice ref={invoiceRef} invoice={getpackedOrderData} porterData={porterOrderData}/>
                                                </div>,
                                                document.body
                                            )}
                                        </div>

                                    )

                                    }
                                </div>
                            </Fragment>
                        )
                    }
                </div>




            </div>
        </div>
    );



};

const modalStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)'
};

export default Dispatch;