import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
    marginReportRequest,
    marginReportSuccess,
    marginReportFail,
    getProductWiseRequest,
    getProductWiseSuccess,
    getProductWiseFail,
    getCategoryWiseRequest,
    getCategoryWiseSuccess,
    getCategoryWiseFail,
    getDateWiseRequest,
    getDateWiseSuccess,
    getDateWiseFail
} from "../slices/marginReportSlice";



export const fetchDateWiseReport = createAsyncThunk(
    "margindatewiseReport/fetch",
    async ({ startDate, endDate }, { dispatch }) => {
        try {
            dispatch(marginReportRequest());

            // Build the query parameters for the API request
            const queryParams = {};
            if (startDate) queryParams.fromDate = startDate;
            if (endDate) queryParams.toDate = endDate;

            // Send the GET request with query parameters
            const { data } = await axios.get('/api/v1/margin-report', {
                withCredentials: true,
                params: queryParams, // Passing the date filters as query params
            });
           
            dispatch(marginReportSuccess(data));
        } catch (error) {
            dispatch(marginReportFail(error.response?.data?.message || "Error fetching data"));
        }
    }
);


export const getProductsByDate = createAsyncThunk(
    'product/getProductsByDate',
    async ({ date }, { dispatch }) => {
        try {
            dispatch(getProductWiseRequest()); // Start loading state

            // Make the API call and capture the response
            const response = await axios.get(`/api/v1/margin-report/${date}`);

            // Dispatch success with the actual response payload
            dispatch(getProductWiseSuccess(response.data));
        } catch (error) {
            dispatch(getProductWiseFail(error.response?.data?.message || 'Error fetching report'));
        }
    }
);


export const getCategoryByDate = createAsyncThunk('product/getCategoryByDate', async ({ startDate, endDate }, { dispatch }) => {
    
    try {
        dispatch(getCategoryWiseRequest());

        // const queryParams = {};
        // if (startDate) queryParams.fromDate = startDate; 
        // if (endDate) queryParams.toDate = endDate; 
        // // Send the GET request with query parameters
        // const { data } = await axios.get(/api/v1/margin-report/category/${}, {
        //     withCredentials: true,
        //     params: queryParams, 
        // });
        const { data } = await axios.get(`/api/v1/margin-report/category/${startDate}/${endDate}`, {
            withCredentials: true, // Ensure credentials are sent with the request if needed
        });
        dispatch(getCategoryWiseSuccess(data));
    } catch (error) {
        dispatch(getCategoryWiseFail(error.response?.data?.message || 'Error fetching report'));
    }
});


export const getDateByProduct = createAsyncThunk('product/getDateByProduct', async ({ startDate, endDate, id }, { dispatch }) => {
   
    try {
        dispatch(getDateWiseRequest());
        const { data } = await axios.get(`/api/v1/margin-report/products/${id}/${startDate}/${endDate}`, {
            withCredentials: true, // Ensure credentials are sent with the request if needed
        });
        dispatch(getDateWiseSuccess(data));
    } catch (error) {
        dispatch(getDateWiseFail(error.response?.data?.message || 'Error fetching report'));
    }
});