import { Fragment, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { deleteUser, getUsers } from "../../actions/userActions";
import { clearError, clearUser, clearUserDeleted } from "../../slices/userSlice";
import Loader from '../Layouts/Loader';
import { MDBDataTable } from 'mdbreact';
import { Slide, toast } from 'react-toastify';
import Sidebar from "./Sidebar";
import MetaData from "../Layouts/MetaData";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CryptoJS from 'crypto-js';
import { clearWalletTransactions } from "../../slices/transactionSlice";


export default function UserWalletList({ isActive, setIsActive }) {
    const location = useLocation();
    const { users, loading = true, error, isUserDeleted } = useSelector(state => state.userState);
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);
    const navigate = useNavigate();
    const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;

    const decryptData = (walletBalance, encryptionKey) => {
        const bytes = CryptoJS.AES.decrypt(walletBalance, encryptionKey);
        return bytes.toString(CryptoJS.enc.Utf8);
    };

    const setUsers = () => {
        const data = {
            columns: [
                {
                    label: 'S.No',
                    field: 's_no',
                    sort: 'disabled'
                },
                {
                    label: 'Name',
                    field: 'name',
                    sort: 'disabled'
                },
                {
                    label: 'Email/Phone No',
                    field: 'email',
                    sort: 'disabled'
                },
                {
                    label: 'Role',
                    field: 'role',
                    sort: 'disabled'
                },
                {
                    label: 'Verified',
                    field: 'verified',
                    sort: 'disabled'
                },
                {
                    label: 'WalletBalance',
                    field: 'wallet_balance',
                    sort: 'disabled'
                }
            ],
            rows: []
        };

        // Sort users by creation date (newest first)
        const sortedUsers = users && [...users]
            .map(user => ({
                ...user,
                decryptedWalletBalance: user.walletBalance
                    ? parseFloat(decryptData(user.walletBalance, encryptionKey)) || 0
                    : 0
            }))
            .sort((a, b) => b.decryptedWalletBalance - a.decryptedWalletBalance);


        sortedUsers && sortedUsers.forEach((user, index) => {
            const decryptedBalance = user.walletBalance
            ? parseFloat(decryptData(user.walletBalance, encryptionKey)) || 0
            : 0;
            data.rows.push({
                s_no: index + 1,
                name: user && user.name ? (
                    <Link to={`/admin/user/wallet/${user?._id}`} style={{ textDecoration: 'none', color: 'blue' }}>
                        {user.name}
                    </Link>
                ) : (
                    <Link to={`/admin/user/wallet/${user?._id}`} style={{ textDecoration: 'none', color: 'blue' }}>
                        {user._id}
                    </Link>
                ),
                email: user
                    ? (user.email
                        ? (user.mobile
                            ? `${user.email}/${user.mobile}`
                            : user.email)
                        : (user.mobile
                            ? user.mobile
                            : ""))
                    : "",


                role: user.role,
                verified: user.verified ? (
                    <span style={{ color: 'green' }}><i className="fa fa-check-circle"></i> </span>
                ) : (
                    <span style={{ color: 'red' }}><i className="fa fa-times-circle"></i> </span>
                ),
                wallet_balance: (
                    <span style={{ color: decryptedBalance > 0 ? '#006400' : 'black',fontWeight:'700' }}>
                        {decryptedBalance !== 0 ? decryptedBalance.toFixed(2) : 0}
                    </span>
                ),
            });
        });

        return data;
    };

    useEffect(() => {
        if (!users) {
            dispatch(getUsers());
        }

    }, [])

    useEffect(() => {
        dispatch(clearWalletTransactions());
        dispatch(clearUser());
    }, [])

    return (
        <div>
            <MetaData
                title="User List"
                description="View and manage a complete list of registered users. Handle user roles, profile updates, and account management tasks."
            />


            <div className="row loader-parent">
                <div className="col-12 col-md-2">
                    <div style={{ display: 'flex', flexDirection: 'row', position: 'fixed', top: '0px', zIndex: 99999, backgroundColor: '#fff', minWidth: '100%' }}>
                        <Sidebar isActive={isActive} setIsActive={setIsActive} />
                    </div>
                </div>
                <div className="col-12 col-md-10 smalldevice-space loader-parent">
                    <h1 className="mb-4 admin-dashboard-x">User Wallet List</h1>
                    <div className="">
                        <span className="back-button" onClick={() => navigate(-1)}>
                            <ArrowBackIcon fontSize="small" />
                            <span>Back</span>
                        </span>

                    </div>
                    <Fragment>
                        {loading ? (
                            <div className="container loader-loading-center">
                                <Loader />
                            </div>

                        ) :
                            <div className='mdb-table' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <MDBDataTable
                                    data={setUsers()}
                                    bordered
                                    noBottomColumns
                                    hover
                                    className="px-3 product-table"
                                />
                            </div>
                        }
                    </Fragment>

                </div>
            </div>
        </div>
    );
}