
import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
    name: "cart",
    initialState: {
        items: [],
        loading: false,
        error: null,
        addLoading: false,
        addResponse: null,
        addError: null,
        updateLoading: false,
        updateError: null,
        updateCart: null,
        removeLoading: false,
        removeError: null,
        removeItem: null,
        ProducUpdateCart: null,
        ProductUpdateError: null,
        ProductUpdateLoading: false,
        removeAllLoading: false,
        removeAllError: null,
        removeAllItem: null,
    },
    reducers: {
        addCartRequest(state) {
            state.addLoading = true;
            state.addError = null;
        },
        addCartSuccess(state, action) {
            state.addLoading = false;
            state.items = action.payload.cartItems;
            state.addResponse = action.payload.message;
        },
        addCartFail(state, action) {
            state.addLoading = false;
            state.addError = action.payload;
        },
        clearAddCart(state, action) {
            state.addLoading = false;
            state.addError = null;
            state.addResponse = null;
        },
        getCartRequest(state) {
            state.loading = true;
            state.error = null;
        },
        getCartSuccess(state, action) {
            state.loading = false;
            state.items = action.payload;
        },
        getCartFail(state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        clearGetCart(state, action) {
            state.loading = false;
            state.error = null;
        },
        updateCartRequest(state) {
            state.updateLoading = true;
            state.updateError = null;
        },
        updateCartSuccess(state, action) {
            state.updateLoading = false;
            state.items = action.payload.cartItems;
            state.updateCart = action.payload.message;
        },
        updateCartFail(state, action) {
            state.updateLoading = false;
            state.updateError = action.payload;
        },
        clearUpdateCart(state, action) {
            state.updateLoading = false;
            state.updateError = null;
            state.updateCart = null;
        },
        removeCartItemRequest(state) {
            state.removeLoading = true;
            state.removeError = null;
        },
        removeCartItemSuccess(state, action) {
            state.removeLoading = false;
            state.items = action.payload.cartItems;  // Updated cart items after item removal
            state.removeItem = action.payload.message;
        },
        removeCartItemFail(state, action) {
            state.removeLoading = false;
            state.removeError = action.payload;
        },
        clearRemoveCart(state) {
            state.removeLoading = false;
            state.removeError = null;
            state.removeItem = null;
        },
        updateCartProductRequest(state) {
            state.ProductUpdateLoading = true;
            state.ProductUpdateError = null;
        },
        updateCartProductSuccess(state, action) {
            state.ProductUpdateLoading = false;
            state.items = action.payload.cartItems;
            state.ProducUpdateCart = action.payload.message;
        },
        updateCartProductFail(state, action) {
            state.ProductUpdateLoading = false;
            state.ProductUpdateError = action.payload;
        },
        clearProductUpdateCart(state, action) {
            state.ProductUpdateLoading = false;
            state.ProductUpdateError = null;
            state.ProducUpdateCart = null;
        },
        removeCartAllRequest(state) {
            state.removeAllLoading = true;
            state.removeAllError = null;
        },
        removeCartAllSuccess(state, action) {
            state.removeAllLoading = false;
            state.items = action.payload.cartItems;  // Updated cart items after item removal
            state.removeAllItem = action.payload.message;
        },
        removeCartAllFail(state, action) {
            state.removeAllLoading = false;
            state.removeAllError = action.payload;
        },
        clearRemoveAllCart(state) {
            state.removeAllLoading = false;
            state.removeAllError = null;
            state.removeAllItem = null;
        },
    },
});

export const {
    addCartRequest,
    addCartSuccess,
    addCartFail,
    getCartRequest,
    getCartSuccess,
    getCartFail,
    updateCartRequest,
    updateCartSuccess,
    updateCartFail,
    updateCartProductRequest,
    updateCartProductSuccess,
    updateCartProductFail,
    removeCartItemRequest,
    removeCartItemSuccess,
    removeCartItemFail,
    clearAddCart,
    clearGetCart,
    clearUpdateCart,
    clearProductUpdateCart,
    clearRemoveCart,
    removeCartAllRequest,
    removeCartAllSuccess,
    removeCartAllFail,
    clearRemoveAllCart,
} = cartSlice.actions;

export default cartSlice.reducer;
