import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserSummary } from '../../actions/orderActions';
import Loader from '../Layouts/Loader';
import MetaData from '../Layouts/MetaData';
import Sidebar from './Sidebar';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LoaderButton from '../Layouts/LoaderButton';
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

const SummaryUser = ({ isActive, setIsActive }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { loading, userSummary = [], error } = useSelector((state) => state.orderState);
    const navigate = useNavigate();
    // Initialize the date with the current date
    const currentDate = new Date().toISOString().split('T')[0];
    const [date, setDate] = useState(currentDate);
    const [showModal, setShowModal] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);

    useEffect(() => {
        // Fetch data when the component mounts
        dispatch(fetchUserSummary(date));
    }, [date, dispatch]);

    const handleViewList = (products, order) => {
        setSelectedProducts(products || []);
        setSelectedOrder(order);
        setShowModal(true);
    }
    const handleCancel = () => {
        setShowModal(false);
        setSelectedProducts([]);
    }

    const totalWeight = userSummary.reduce((sum, order) => sum + (order.totalWeight || 0), 0);
    const totalAmount = userSummary.reduce((sum, order) => sum + (order.totalAmount || 0), 0);

    const handleDownload = async () => {
        if (!userSummary || userSummary.length === 0) {
            alert("No data available to download");
            return;
        }

        // Create a new workbook and add a worksheet
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("User Summary");

        let rowIndex = 1; // Track row index for adding order details


        // Extract the order date from the first item in the orderSummary array
        const orderDescription = userSummary[0].orderDescription;
        const orderDateMatch = orderDescription.match(/\d{2}\/[A-Za-z]{3}\/\d{4}/); // Extracts the date in the format dd/Mon/yyyy
        const formattedDate = orderDateMatch ? orderDateMatch[0] : 'Date not available';

        // First row: Order Date in merged cells
        worksheet.mergeCells(`A${rowIndex}:E${rowIndex}`);
        const dateCell = worksheet.getCell(`A${rowIndex}`);
        dateCell.value = `${formattedDate}`;
        dateCell.alignment = { horizontal: "center", vertical: "middle" };
        dateCell.font = { bold: true };
        rowIndex++;  // Move to the next row

        // Second row: Add header for each column
        worksheet.getCell(`A${rowIndex}`).value = "S.No";
        worksheet.getCell(`B${rowIndex}`).value = "REF";
        worksheet.getCell(`C${rowIndex}`).value = "PRODUCT";
        worksheet.getCell(`D${rowIndex}`).value = "PRODUCT NAME";
        worksheet.getCell(`E${rowIndex}`).value = "WEIGHT";
        worksheet.getRow(rowIndex).font = { bold: true }; // Make header bold
        rowIndex++; // Move to the next row for order ID and products

        // Iterate through the userSummary to create the Excel content
        userSummary.forEach((order) => {
            // Third row: Merge cells for Order ID
            worksheet.mergeCells(`A${rowIndex}:E${rowIndex}`);
            worksheet.getCell(`A${rowIndex}`).value = `Order ID: ${order.order_id}`;
            worksheet.getCell(`A${rowIndex}`).alignment = { horizontal: "center", vertical: "middle" };
            worksheet.getCell(`A${rowIndex}`).font = { bold: true };
            rowIndex++; // Move to the next row for the products

            // Fourth row: Add the product details for each product
            order.products.slice().sort((a, b) => {
                const refCompare = a.category.localeCompare(b.category);
                return refCompare !== 0 ? refCompare : a.name.localeCompare(b.name);
            }).forEach((product, productIndex) => {
                worksheet.getCell(`A${rowIndex}`).value = productIndex + 1;  // S.No
                worksheet.getCell(`B${rowIndex}`).value = product.category || 'N/A';  // REF
                worksheet.getCell(`C${rowIndex}`).value = product.tamilname;  // PRODUCT
                worksheet.getCell(`D${rowIndex}`).value = product.name;  // PRODUCT NAME
                worksheet.getCell(`E${rowIndex}`).value = `${product.weight} ${product.unit}`;  // WEIGHT
                rowIndex++; // Move to the next row for the next product
            });

            // Optional: Add a blank row after each order section for separation (if desired)
            worksheet.addRow([]);
            rowIndex++;  // Move to the next row
        });

        // Set column widths for better readability
        worksheet.getColumn(1).width = 5;  // S.No
        worksheet.getColumn(2).width = 10;  // REF
        worksheet.getColumn(3).width = 30;  // PRODUCT
        worksheet.getColumn(4).width = 27;  // PRODUCT NAME
        worksheet.getColumn(5).width = 8;  // WEIGHT

        // Apply borders and alignment to all rows
        worksheet.eachRow((row) => {
            row.eachCell((cell) => {
                cell.border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };
                // Align text to the left for all cells
                cell.alignment = { horizontal: "center" };
            });
        });

        // Generate the Excel file and download it
        // Use the first order's date for the filename
        const fileName = `User_Summary_${formattedDate}.xlsx`;
        // Generate the Excel file buffer
        const buffer = await workbook.xlsx.writeBuffer();

        // Save the file using FileSaver.js
        saveAs(new Blob([buffer]), fileName);
    };

    const singleOrderDownload = async () => {
        if (!selectedOrder || selectedProducts.length === 0) {
            alert("No data available to download");
            return;
        }

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Order Summary");

        let rowIndex = 1;

        // Add Order ID as header
        worksheet.mergeCells(`A${rowIndex}:E${rowIndex}`);
        const orderCell = worksheet.getCell(`A${rowIndex}`);
        orderCell.value = `Order ID: ${selectedOrder.order_id}`;
        orderCell.alignment = { horizontal: "center", vertical: "middle" };
        orderCell.font = { bold: true };
        rowIndex++;

        // Add headers
        worksheet.getCell(`A${rowIndex}`).value = "S.No";
        worksheet.getCell(`B${rowIndex}`).value = "REF";
        worksheet.getCell(`C${rowIndex}`).value = "Product";
        worksheet.getCell(`D${rowIndex}`).value = "Product Name";
        worksheet.getCell(`E${rowIndex}`).value = "Weight";
        worksheet.getRow(rowIndex).font = { bold: true };
        rowIndex++;

        // Add products
        selectedProducts && selectedProducts.slice().sort((a, b) => {
            const refCompare = a.category.localeCompare(b.category);
            return refCompare !== 0 ? refCompare : a.name.localeCompare(b.name);
        }).forEach((product, index) => {
            worksheet.getCell(`A${rowIndex}`).value = index + 1;
            worksheet.getCell(`B${rowIndex}`).value = product.category;
            worksheet.getCell(`C${rowIndex}`).value = product.tamilname;
            worksheet.getCell(`D${rowIndex}`).value = product.name;
            worksheet.getCell(`E${rowIndex}`).value = `${product.weight.toFixed(2)} ${product.unit}`;
            rowIndex++;
        });

        // Set column widths
        worksheet.getColumn(1).width = 5;  // S.No
        worksheet.getColumn(2).width = 10;  // REF
        worksheet.getColumn(3).width = 30;  // PRODUCT
        worksheet.getColumn(4).width = 27;  // PRODUCT NAME
        worksheet.getColumn(5).width = 8;  // WEIGHT


        // Apply borders and alignment
        worksheet.eachRow((row) => {
            row.eachCell((cell) => {
                cell.border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };
                cell.alignment = { horizontal: "center", vertical: "middle" };
            });
        });

        // Save the file
        const buffer = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([buffer]), `Order_${selectedOrder.order_id}.xlsx`);
    };


    return (
        <div className="row loader-parent">
            <MetaData title={`User Summary`} />
            <div className="col-12 col-md-2">
                <div style={{ display: 'flex', flexDirection: 'row', position: 'fixed', top: '0px', zIndex: 99999, backgroundColor: '#fff', minWidth: '100%' }}>
                    <Sidebar isActive={isActive} setIsActive={setIsActive} />
                </div>
            </div>
            <div className="col-12 col-md-9 smalldevice-space-summary loader-parent">
                <h1 className='mb-4 admin-dashboard-x'>User Purchase Order</h1>
                <div className='button-container'>
                    {/* <div className=""> */}
                    <span className="order-summary-back-button" onClick={() => navigate(-1)}>
                        <ArrowBackIcon fontSize="small" />
                        <span>Back</span>
                    </span>

                    {/* </div> */}
                    <button
                        className="btn download-button"
                        onClick={handleDownload}
                    >
                        Download Purchase Order
                    </button>
                </div>
                <input
                    type="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    className="form-control mb-3 date-input"
                />
                {loading ? (
                    <div className="container loader-loading-center">
                        <Loader />
                    </div>
                ) : error ? (
                    <p className="text-danger">{error}</p>
                ) : (
                    <div className="container cart-detail-container">
                        {userSummary.length === 0 ? (
                            <p>No user data found for the selected date.</p>
                        ) : (
                            <div className="updatetable-responsive">
                                <table className="updatetable updatetable-bordered">
                                    <thead>
                                        <tr>
                                            <th className="s-no">S.No</th>
                                            <th className="name">OrderID</th>
                                            <th className="name">Name</th>
                                            {/* <th className="email">Email</th> */}
                                            <th className="phone">Phone No</th>
                                            <th className="address">Address</th>
                                            <th className="products">Products</th>
                                            {/* <th className="amount">Total Amount</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {userSummary && userSummary.map((order, index) => (
                                            <tr key={index}>
                                                <td className="s-no">{index + 1}</td>
                                                <td className="s-no">{order.order_id}</td>
                                                <td className="name">{order.shippingInfo?.name || 'N/A'}</td>
                                                {/* <td className="email">{order.shippingInfo?.email || 'N/A'}</td> */}
                                                <td className="phone">{order.shippingInfo?.phoneNo || 'N/A'}</td>
                                                <td className="address">
                                                    {order.shippingInfo?.address || 'N/A'},{order.shippingInfo?.city || 'N/A'},{order.shippingInfo?.country || 'N/A'},{order.shippingInfo?.postalCode || 'N/A'}
                                                </td>
                                                <td className="products">
                                                    <button id="viewList_btn" className="btn btn-block" onClick={() => handleViewList(order.products, order)} disabled={loading}>
                                                        {loading ? <LoaderButton fullPage={false} size={20} /> : (
                                                            <span>View List</span>
                                                        )

                                                        }
                                                    </button>
                                                </td>
                                                {/* <td className="amount">Rs.{(order.totalAmount || 0).toFixed(2)}</td> */}
                                            </tr>
                                        ))}
                                        {/* <tr>
                                            <td colSpan="6" style={{ textAlign: 'right' }}><strong>Total</strong></td>
                                            <td className="amount"><strong>Rs. {totalAmount.toFixed(2)}</strong></td>
                                        </tr> */}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                )}
            </div>

            {showModal && (
                <div className="custom-modal" tabIndex="-1" role="dialog">
                    <div className="custom-modal-dialog" role="document">
                        <div className="custom-modal-content">
                            <div className="custom-modal-header">
                                <h5 className="custom-modal-title">OrderID: {selectedOrder && selectedOrder.order_id}</h5>
                                <button
                                    className="btn download-button"
                                    onClick={singleOrderDownload}
                                >
                                    Download
                                </button>
                                {!loading && (
                                    <button
                                        type="button"
                                        className="custom-modal-close"
                                        onClick={handleCancel}
                                        disabled={loading}
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                )}
                            </div>
                            <div className="custom-modal-body">
                                {selectedProducts.length > 0 ? (
                                    <table className="custom-product-table">
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>REF</th>
                                                <th>Product</th>
                                                <th>Product Name</th>
                                                <th>Weight</th>
                                                {/* <th>Price (Rs.)</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectedProducts.slice().sort((a, b) => {
                                                const refCompare = a.category.localeCompare(b.category);
                                                return refCompare !== 0 ? refCompare : a.name.localeCompare(b.name);
                                            }).map((product, idx) => (
                                                <tr key={idx}>
                                                    <td>{idx + 1}</td>
                                                    <td>{product.category}</td>
                                                    <td>{product.tamilname}</td>
                                                    <td>{product.name}</td>
                                                    <td>{product.weight.toFixed(2)} {product.unit}</td>
                                                    {/* <td>{Number(product.price).toFixed(2)}</td> */}
                                                </tr>
                                            ))}
                                        </tbody>
                                        {/* <tr>
                                            <td colSpan="3" style={{ textAlign: "right", fontWeight: "bold" }}>Total Amount:</td>
                                            <td style={{ fontWeight: "bold" }}>
                                                {selectedProducts.reduce((total, product) => total + Number(product.price), 0).toFixed(2)}
                                            </td>
                                        </tr> */}
                                    </table>
                                ) : (
                                    <p className="custom-no-products">No products found.</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}


        </div>
    );
};
const modalStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)'
};

export default SummaryUser;
