import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import Sidebar from "../admin/Sidebar";
import { adminOrders as adminOrdersAction } from "../../actions/orderActions";
import MetaData from '../Layouts/MetaData';
import Loader from '../Layouts/Loader';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const OrderReport = ({ isActive, setIsActive }) => {
    const dispatch = useDispatch();
    const { adminOrders: orderReport, error, loading } = useSelector((state) => state.orderState);
    const { products } = useSelector((state) => state.productsState);
    const navigate = useNavigate();

    const currentDate = new Date().toISOString().split("T")[0];
    const [startDate, setStartDate] = useState(currentDate);
    const [endDate, setEndDate] = useState(currentDate);
    const [selectedCategory, setSelectedCategory] = useState("All");
    const [modalProductId, setModalProductId] = useState(null);
    const [modalUserData, setModalUserData] = useState([]);
  

    useEffect(() => {
        if (!orderReport) {
            dispatch(adminOrdersAction());
        }
    }, [orderReport, dispatch]);

    // Function to calculate and filter weights based on date range and product data
    const calculateTotalWeights = () => {
        if (!orderReport || !Array.isArray(orderReport) || !products || !Array.isArray(products)) {
            return [];
        }

        const weightMap = {};
        const start = new Date(startDate);
        const end = new Date(endDate);
        end.setHours(23, 59, 59, 999); // Ensure end date is the full day

        // Filter orders by the selected date range
        const filteredOrders = orderReport.filter((order) => order.paymentStatus === "CHARGED").filter((order) => {
            const orderDate = new Date(order.createdAt);
            return orderDate >= start && orderDate <= end;
        });

        // Aggregate weights by product ID
        filteredOrders.forEach((order) => {
            order.orderItems.forEach((item) => {
                const productId = item.product;
                if (!weightMap[productId]) {
                    weightMap[productId] = 0;
                }
                weightMap[productId] += item.productWeight;
            });
        });

        return products
            .filter((product) => selectedCategory === "All" || product.category === selectedCategory)
            .map((product) => ({
                ...product,
                totalWeight: weightMap[product._id] || 0
            }));
    };

    // Aggregate Products based on selected filters
    const aggregatedProducts = calculateTotalWeights();

    // Function to handle "View" button click for modal
    const handleViewClick = (productId) => {
        setModalProductId(productId);

        // Filter orders based on selected product and date range
        const filteredOrders = orderReport.filter((order) => order.paymentStatus === "CHARGED").filter((order) => {
            const orderDate = new Date(order.createdAt);
            const start = new Date(startDate);
            const end = new Date(endDate);
            end.setHours(23, 59, 59, 999); // Ensure end date is the full day

            // Filter by date range and product ID
            return (
                orderDate >= start && orderDate <= end &&
                order.orderItems.some((item) => item.product === productId)
            );
        });


        // Group by userId and calculate the total weight for each user
        const userMap = {};
        filteredOrders.forEach((order) => {
            order.orderItems.forEach((item) => {
                if (item.product === productId) {
                    const userId = order.user_id;
                    if (!userMap[userId]) {
                        userMap[userId] = {
                            userId:order.user_id,
                            userName: order.user.name,
                            contact: order?.user?.email || order?.user?.mobile,
                            totalWeight: 0,
                            measurement: item.measurement,
                        };
                    }
                    userMap[userId].totalWeight += item.productWeight;
                }
            });
        });

        // Convert userMap to an array of users with their total weights
        const userData = Object.values(userMap);
        setModalUserData(userData);
    };


    // Table Data for Product Summary
    const tableData = {
        columns: [
            { label: "S.NO", field: "serial", sort: "asc" },
            { label: "Category", field: "category", sort: "asc" },
            { label: "English Name", field: "englishName", sort: "asc" },
            { label: "Tamil Name", field: "tamilName", sort: "asc" },
            { label: "Total Weight", field: "totalWeight", sort: "asc" },
        ],
        rows: aggregatedProducts.sort((a, b) => a.englishName.localeCompare(b.englishName)).map((product, index) => ({
            serial: index + 1,
            category: product.category,
            // englishName: product.englishName,
            // englishName: (
            //     <span
            //         className="english-name-link"
            //         onClick={() => handleViewClick(product._id)}
            //         style={{ color: "blue", cursor: "pointer" }}
            //     >
            //        {product.englishName}

            //     </span>
            // ),
            englishName: {
                value: product.englishName, // Plain text for search
                display: (
                    <span
                        className="english-name-link"
                        onClick={() => handleViewClick(product._id)}
                        style={{ color: "blue", cursor: "pointer" }}
                    >
                        {product.englishName} {product.range ? `(${product.range})` : ""}

                    </span>
                ),
            },
            tamilName: `${product.tamilName}${product.range ? ` (${product.range})` : ""}`,
            totalWeight: `${product.totalWeight} ${product.measurement}`,
            // view: (
            //     <button onClick={() => handleViewClick(product._id)} className="btn btn-success">
            //         View
            //     </button>
            // )
        })).map(row => ({
            ...row,
            englishName: row.englishName.display, // Render clickable text in UI
            searchableEnglishName: row.englishName.value, // Plain text for search
        }))
    };

    return (
        <div>
            <MetaData title="Order Summary" />
            <div className="row loader-parent">
                <div className="col-12 col-md-2">
                    <Sidebar isActive={isActive} setIsActive={setIsActive} />
                </div>

                <div className="col-12 col-md-10 smalldevice-space-summary loader-parent mt-0">
                    <h1 className="mb-4 admin-dashboard-x">Order Report</h1>
                    <div className="button-container mb-2">
                        <div>
                            <span className="order-summary-back-button" onClick={() => navigate(-1)}>
                                <ArrowBackIcon fontSize="small" />
                                <span>Back</span>
                            </span>
                        </div>
                    </div>

                    <div className="paymentlist-container">
                        <div className="paymentlist-date-filter">
                            <div className='start-date'>
                                <label htmlFor="startDate">Start Date:</label>
                                <input
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                    className="paymentlist-form-control"
                                />
                            </div>
                            <div className="end-date">
                                <label htmlFor="endDate">End Date:</label>
                                <input
                                    type="date"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                    className="paymentlist-form-control"
                                />
                            </div>
                        </div>

                        <div className="filter-status" >
                            <label htmlFor="selectedCategory">Filter:</label>
                            <select
                                value={selectedCategory}
                                onChange={(e) => setSelectedCategory(e.target.value)}
                                className="paymentlist-form-select"
                            >
                                <option value="All">All</option>
                                {[...new Set(products.map((product) => product.category))].map((category) => (
                                    <option key={category} value={category}>
                                        {category}
                                    </option>
                                ))}
                            </select>
                        </div>

                    </div>

                    {loading ? (
                        <div className="container loader-loading-center">
                            <Loader />
                        </div>
                    ) : error ? (
                        <p className="text-danger">{error}</p>
                    ) : aggregatedProducts.length === 0 ? (
                        <p>No orders found for the selected criteria.</p>
                    ) : (
                        <div className="mdb-table">
                            <MDBDataTable
                                bordered
                                hover
                                className="px-3 product-table"
                                data={tableData}
                                noBottomColumns
                            />
                        </div>
                    )}
                </div>
            </div>

            {/* Modal to display user data for selected product */}
            {modalProductId && (
                <div className="orderReport-modal">
                    <div className="orderReportmodal-content">
                        <span
                            className="orderReportmodal-close"
                            onClick={() => setModalProductId(null)}
                        >
                            &times;
                        </span>
                        <h5>
                            {aggregatedProducts.find((product) => product._id === modalProductId)?.englishName} /
                            {aggregatedProducts.find((product) => product._id === modalProductId)?.tamilName}
                        </h5>
                        <div className="orderReport-table-container">
                            <MDBDataTable
                                bordered
                                noBottomColumns
                                hover
                                data={{
                                    columns: [
                                        { label: "S.NO", field: "serial" },
                                        { label: "Name", field: "userName" },
                                        { label: "Email/Phone", field: "contact" },
                                        { label: "Total Weight", field: "totalWeight" }
                                    ],
                                    rows: modalUserData.map((user, index) => ({
                                        serial: index + 1,
                                        userName: user && user.userName ? (
                                            <Link to={`/admin/addressList/${user?.userId}`} style={{ textDecoration: 'none', color: 'blue' }}>
                                                {user.userName}
                                            </Link>
                                        ) : (
                                            <Link to={`/admin/addressList/${user?.userId}`} style={{ textDecoration: 'none', color: 'blue' }}>
                                                {user.userId}
                                            </Link>
                                        ),
                                        contact: user.contact,
                                        // englishName: aggregatedProducts.find(p => p._id === modalProductId).englishName,
                                        // tamilName: aggregatedProducts.find(p => p._id === modalProductId).tamilName,
                                        totalWeight:`${user.totalWeight} ${user.measurement}`
                                    }))
                                }}
                            />
                        </div>
                    </div>
                </div>

            )}
        </div>
    );
};

export default OrderReport;



