import './App.css';
import Footer from './components/Layouts/Footer';
import Header from './components/Layouts/Header';
import Home from './components/Home';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate, Navigate, matchPath } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ProductDetail from './components/Product/ProductDetail';
import ProductSearch from './components/Product/ProductSearch';
import Login from './components/user/Login';
import Register from './components/user/Register';
import { useEffect, useMemo, useState } from 'react';
import store from './store';
import { loadUser } from './actions/userActions';
import { userOrders as userOrdersAction } from './actions/orderActions';
import Dashboard from './components/admin/Dashboard';
import ProtectedRoute from './components/route/ProtectedRoute';
import ProductList from './components/admin/ProductList';
import NewProduct from './components/admin/NewProduct';
import UpdateProduct from './components/admin/UpdateProduct';
import Product from './components/Product/Product';
import LandingPage from './components/LandingPage';
import Vegetables from './components/Vegetables';
import Fruits from './components/Fruits';
import Profile from './components/user/Profile';
import UpdateProfile from './components/user/UpdateProfile';
import UpdatePassword from './components/user/UpdatePassword';
import ForgotPassword from './components/user/ForgotPassword';
import ResetPassword from './components/user/ResetPassword';
import Cart from './components/cart/Cart';
import Shipping from './components/cart/Shipping';
import ConfirmOrder from './components/cart/ConfirmOrder';
import { getAdminProducts, getProducts } from './actions/productsActions';
import About from './components/Layouts/About';
import Keerai from './components/Keerai';
import TermsAndConditions from './components/Layouts/TermsAndConditions'
import PrivacyPolicy from './components/Layouts/PrivacyPolicy';
import RefundPolicy from './components/Layouts/RefundPolicy';
import Enquiry from './components/user/Enquiry';
import EnquiryRequest from './components/admin/EnquiryRequest';
import UserList from './components/admin/UserList';
import UpdateUser from './components/admin/UpdateUser';
import UpdatePrice from './components/admin/UpdatePrice';
import UserOrders from './components/order/UserOrders';
import OrderDetail from './components/order/OrderDetail';
import PaymentConfirm from './components/order/PaymentConfirm';
import OrderList from './components/admin/OrderList';
import UpdateOrder from './components/admin/UpdateOrder';
// import Refund from './components/order/Refund';
import PaymentList from './components/admin/PaymentList';
import PaymentDetails from './components/order/PaymentDetails';
import OrderSummary from './components/order/OrderSummary';
import UserSummary from './components/admin/SummaryUser';
import SummaryUser from './components/admin/SummaryUser';
import ScrollToTop from './components/Layouts/ScrollToTop';
import Dispatch from './components/admin/Dispatch';
import RefundOrder from './components/admin/RefundOrder';
import DispatchList from './components/admin/DispatchList';
import RefundList from './components/admin/RefundList';
import { useDispatch, useSelector } from 'react-redux';
import Loader from './components/Layouts/Loader';
import AllOrders from './components/admin/AllOrders';
import AdminOrderDetail from './components/admin/AdminOrderDetail';
import Analysis from './components/admin/Analysis';
import Unauthorized from './components/Layouts/Unauthorized';
import PageNotFound from './components/Layouts/PageNotFound';
import { orderDetailClear } from './slices/orderSlice';
import { porterClearData, porterClearResponse } from './slices/porterSlice';
import Address from './components/cart/Address';
import { getUserAddresses } from './actions/addressAction';
import { cleargetAddress } from './slices/AddressSlice';
import UpdateAddress from './components/cart/UpdateAddress';
import CategoryList from './components/admin/CategoryList';
import NewCategory from './components/admin/NewCategory';
import UpdateCategory from './components/admin/UpdateCategory';
import Categories from './components/Categories';
import MeasurementList from './components/admin/MeasurementList'
import NewMeasurement from './components/admin/NewMeasurement';
import UpdateMeasurement from './components/admin/UpdateMeasurement'
import CarouselLayout from './components/Layouts/CarouselLayout';
import LoginForm from './components/user/LoginForm';
import SearchProduct from './components/Product/SearchProduct';
import Fresh from './components/Fresh';
import Groceries from './components/Groceries';
import SidebarButtons from './components/Layouts/sidebarButtons';
import { getCategories } from './actions/categoryAction';
import AnnouncementList from './components/admin/AnnouncementList';
import NewAnnouncement from './components/admin/NewAnnouncement';
import UpdateAnnouncement from './components/admin/UpdateAnnouncement';
import { getAnnouncements } from './actions/announcementAction';
import AnnouncementBanner from './components/Layouts/AnnouncemetBanner';
import UserAddressList from './components/admin/UserAddressList';
import UserDataModal from './components/Layouts/UserDataModal';
import OrderReport from './components/admin/OrderReport';


function App() {
    const location = useLocation();
    // const navigate = useNavigate();
    // const redirectPath = sessionStorage.getItem('redirectPath') || location.pathname;
    const [email, setEmail] = useState("");
    // const redirectPath = useMemo(() => sessionStorage.getItem('redirectPath') || '/', []);

    // if (!redirectPath && !location) {
    //     navigate('/')
    //     sessionStorage.setItem('redirectPath', '/');
    // }
    // if(!redirectPath){
    //     sessionStorage.setItem('redirectPath', '/');
    // }

    const phoneNumber = "919176720068";
    const message = "Hello, I need help with my order.";

    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

    const { isAuthenticated, loading, user } = useSelector(state => state.authState);
    const { products, loading: productLoading } = useSelector((state) => state.productsState);
    const { getdata, geterror } = useSelector(state => state.addressState);
    const { userOrders, error } = useSelector(state => state.orderState)
    const [openSide, setOpenSide] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const { getcategory } = useSelector((state) => state.categoryState);
    const {
        getAnnouncement,
        getAnnouncementLoading
    } = useSelector(state => state.announcementState);
    const [showAnnouncement, setShowAnnouncement] = useState(false);
    const [announcements, setAnnouncements] = useState([]);
    const [filteredAnnouncements, setFilteredAnnouncements] = useState([]);
    const dispatch = useDispatch();
    const [userLoaded, setUserLoaded] = useState(false);
    const [bannerAnnouncement, setBannerAnnouncement] = useState(null);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [showUserModal, setShowUserModal] = useState(false);

    // console.log("user", user)
    const handleLoginClick = () => {
        setShowLoginModal(true);
    };

    const closeLoginModal = () => {
        setShowLoginModal(false);
    };


    useEffect(() => {
        if (isAuthenticated && user) {
            if (user && user.name) {
                setShowUserModal(false);
            } else {
                setShowUserModal(true);
            }
        }
    }, [user])

    useEffect(() => {
        const fetchAnnouncements = async () => {
            try {
                const now = new Date();  // Current date and time
                now.setHours(0, 0, 0, 0);

                const filtered = getAnnouncement && getAnnouncement?.filter((announcement) => {
                    if (announcement && announcement.type === 'Announcement') {
                        const startDate = new Date(announcement.startDate);
                        const endDate = new Date(announcement.endDate);

                        // Normalize the dates (removing time)
                        startDate.setHours(0, 0, 0, 0);
                        endDate.setHours(0, 0, 0, 0);

                        // Calculate 7 days before the start date
                        const sevenDaysBeforeStart = new Date(startDate);
                        sevenDaysBeforeStart.setDate(startDate.getDate() - 7);

                        // Check if the current date is between sevenDaysBeforeStart and endDate (inclusive)
                        const isActive = now >= sevenDaysBeforeStart && now <= endDate;

                        return isActive;
                    }

                    else {
                        const startDate = new Date(announcement.startDate);
                        const endDate = new Date(announcement.endDate);

                        // Normalize the dates (removing time)
                        startDate.setHours(0, 0, 0, 0);
                        endDate.setHours(0, 0, 0, 0);

                        // Calculate 7 days before the start date
                        const sevenDaysBeforeStart = new Date(startDate);
                        sevenDaysBeforeStart.setDate(startDate.getDate());

                        // Check if the current date is between sevenDaysBeforeStart and endDate (inclusive)
                        const isActive = now >= sevenDaysBeforeStart && now <= endDate;

                        return isActive;
                    }

                });

                setAnnouncements(getAnnouncement && getAnnouncement);
                setFilteredAnnouncements(filtered);
            } catch (error) {
            }

        };

        if (getAnnouncement) {
            fetchAnnouncements();
        }

    }, [getAnnouncement]);

    useEffect(() => {
        const loadInitialData = async () => {
            await store.dispatch(loadUser());
            await store.dispatch(getProducts());
            setUserLoaded(true); // Set the flag once the user data is loaded
        };
        loadInitialData();
    }, [])

    useEffect(() => {
        if (user) {
            dispatch(userOrdersAction());
        }
    }, [user])

    // useEffect(() => {
    //     if (!isAuthenticated && !loading) {
    //       setShowUserModal(true);
    //     } else {
    //       setShowUserModal(false); 
    //     }
    //   }, [isAuthenticated, loading]);

    useEffect(() => {
        dispatch(orderDetailClear());
        dispatch(porterClearData());
        dispatch(porterClearResponse());
    }, [])

    useEffect(() => {
        if (user && user.role === 'admin') {
            dispatch(getAdminProducts());
        }

    }, [user])


    useEffect(() => {
        if (!isAuthenticated || !user && userLoaded) {

            store.dispatch(loadUser());
        }
        if (!products) {
            store.dispatch(getProducts());
        }
    }, [isAuthenticated, products, userLoaded]);

    useEffect(() => {
        dispatch(cleargetAddress());
    }, [])

    useEffect(() => {
        if (!getcategory) {
            dispatch(getCategories())
        }

    }, [])

    useEffect(() => {
        if (isAuthenticated && user && !getdata) {
            dispatch(getUserAddresses({ userId: user && user._id })); // Fetch user addresses when the component mounts
        }
    }, [isAuthenticated, dispatch]);



    const isAdminRoute = location.pathname.includes('/admin') || location.pathname.includes('/unauthorized') || location.pathname.includes('/page-not-found')
    const isMapRoute = location.pathname.includes('/address/update') || location.pathname.includes('/shipping') || location.pathname.includes('/unauthorized') || location.pathname.includes('/page-not-found')


    useEffect(() => {
        const handleScroll = () => {
            const headerElement = document.querySelector('.main-header');
            if (window.scrollY > 20) {
                headerElement && headerElement.classList.add('header-shadow');
            } else {
                headerElement && headerElement.classList.remove('header-shadow');
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        window.addEventListener('pageshow', (event) => {
            if (event.persisted) {
                window.location.reload(); // Reload if coming from BFCache
            }
        });

        return () => {
            window.removeEventListener('pageshow', () => { });
        };
    }, []);

    useEffect(() => {
        if (!getAnnouncement) {
            dispatch(getAnnouncements())
        }
    }, [])

    useEffect(() => {
        if (filteredAnnouncements && filteredAnnouncements.length > 0 && !bannerAnnouncement) {
            const banner = filteredAnnouncements && filteredAnnouncements?.filter((announcement) => {
                return announcement && announcement.type === 'Offer';
            });
            setBannerAnnouncement(banner);
        }
        if (bannerAnnouncement && bannerAnnouncement.length > 0) {
            setShowAnnouncement(true);
        }

    }, [filteredAnnouncements, bannerAnnouncement])




    return (
        <div className={`${openSide ? "App-blure page-container" : isActive ? "App-blure page-container" : "page-container"}`}>
            <HelmetProvider>
                {
                    productLoading || !userLoaded ? (
                        <div style={{ minHeight: '100vh', minWidth: '100vw', position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Loader />
                        </div>

                    )

                        : (
                            <div style={{ display: 'flex', flexDirection: 'column', position: 'relative', width: '100%' }}>

                                {filteredAnnouncements && filteredAnnouncements.length > 0 && !isAdminRoute &&

                                    <div className="announcement-marquee">

                                        <div className="announcement-marquee-content" >
                                            {filteredAnnouncements && filteredAnnouncements?.map((announcement) => (
                                                <span key={announcement._id} style={{ marginRight: '10vw' }}>{announcement.content}</span>
                                            ))}
                                        </div>

                                    </div>
                                    

                                }

                                {
                                    showAnnouncement && !isAdminRoute ? (
                                        <AnnouncementBanner bannerAnnouncement={bannerAnnouncement} setShowAnnouncement={setShowAnnouncement} />
                                    ) : (
                                        <>
                                        </>
                                    )
                                }



                                <div className={filteredAnnouncements && filteredAnnouncements.length > 0 ? 'main-header-marquee' : 'main-header'}>
                                    {!isAdminRoute && <Header openSide={openSide} setOpenSide={setOpenSide} onLoginClick={handleLoginClick} setShowLoginModal={setShowLoginModal} filteredAnnouncements={filteredAnnouncements} />}
                                    {showLoginModal && <LoginForm showModal={showLoginModal} onClose={closeLoginModal} />}
                                    {showUserModal && <UserDataModal show={showUserModal} closeModal={() => setShowUserModal(false)} />}
                                </div>



                                <ScrollToTop />
                                <div className='page-content'>
                                    <Routes>
                                        {/* <Route path="/login" element={<Login email={email} setEmail={setEmail} />} /> */}
                                        <Route path="/register" element={<Register />} />
                                        {
                                            user && (user.role === "admin") ?
                                                <Route path="/admin/dashboard" element={<ProtectedRoute isAdmin={true} isSubAdmin={false}><Dashboard isActive={isActive} setIsActive={setIsActive} /></ProtectedRoute>} />
                                                :
                                                <Route path="/" element={<LandingPage />} />

                                        }
                                        <Route path="/" element={<LandingPage />} />
                                        {/* <Route path="/categories/:category" element={<LandingPage />} /> */}
                                        <Route path="/unauthorized" element={<Unauthorized />} />
                                        <Route path="/categories/:category/type/:type" element={<Categories />} />
                                        <Route path="/carousel" element={<CarouselLayout />} />
                                        <Route path="/about" element={<About />} />
                                        <Route path="/enquiry" element={<Enquiry />} />
                                        <Route path="/fresh" element={<Fresh />} />
                                        <Route path="/groceries" element={<Groceries />} />
                                        <Route path="/address" element={<Address />} />
                                        <Route path="/profile" element={<SidebarButtons />} />
                                        <Route path="/termsAndConditions" element={<TermsAndConditions />} />
                                        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
                                        <Route path="/refundPolicy" element={<RefundPolicy />} />
                                        <Route path="/search/:keyword" element={<ProductSearch />} />
                                        <Route path="/product/search" element={<SearchProduct products={products} />} />
                                        <Route path="/product/:id" element={<ProductDetail />} />
                                        <Route path="/address/update/:id" element={<UpdateAddress />} />
                                        <Route path="/cart" element={<Cart openSide={openSide} setOpenSide={setOpenSide} onLoginClick={handleLoginClick} setShowLoginModal={setShowLoginModal} />} />
                                        <Route path="/password/forgot" element={<ForgotPassword email={email} setEmail={setEmail} />} />
                                        <Route path="/password/reset/:token" element={<ResetPassword />} />
                                        <Route path="/myProfile" element={<ProtectedRoute isAdmin={false}><Profile /></ProtectedRoute>} />
                                        <Route path="/myProfile/update" element={<ProtectedRoute isAdmin={false}><UpdateProfile /></ProtectedRoute>} />
                                        <Route path="/myProfile/update/password" element={<ProtectedRoute isAdmin={false}><UpdatePassword /></ProtectedRoute>} />
                                        <Route path="/password/reset/:token" element={<ProtectedRoute isAdmin={false}><ResetPassword /></ProtectedRoute>} />
                                        <Route path="/shipping" element={<ProtectedRoute isAdmin={false} ><Shipping /></ProtectedRoute>} />
                                        <Route path="/orders" element={<ProtectedRoute isAdmin={false}><UserOrders /></ProtectedRoute>} />
                                        <Route path='/order/:id' element={<ProtectedRoute isAdmin={false}><OrderDetail /></ProtectedRoute>} />
                                        <Route path='/payment/:id' element={<ProtectedRoute isAdmin={false}><PaymentDetails /></ProtectedRoute>} />
                                        <Route path="/order/confirm" element={<ProtectedRoute isAdmin={false}><ConfirmOrder /></ProtectedRoute>} />
                                        <Route path="/payment/confirm/:id" element={<PaymentConfirm />} />
                                        <Route path="/admin/announcement" element={<ProtectedRoute isAdmin={true} isSubAdmin={false}><AnnouncementList isActive={isActive} setIsActive={setIsActive} /></ProtectedRoute>} />
                                        <Route path="/admin/add-announcement" element={<ProtectedRoute isAdmin={true} isSubAdmin={false}><NewAnnouncement isActive={isActive} setIsActive={setIsActive} /></ProtectedRoute>} />
                                        <Route path="/admin/update-announcement/:id" element={<ProtectedRoute isAdmin={true} isSubAdmin={false}><UpdateAnnouncement isActive={isActive} setIsActive={setIsActive} /></ProtectedRoute>} />
                                        <Route path="/admin/dashboard" element={<ProtectedRoute isAdmin={true} isSubAdmin={false}><Dashboard isActive={isActive} setIsActive={setIsActive} /></ProtectedRoute>} />
                                        <Route path="/admin/products" element={<ProtectedRoute isAdmin={true} isSubAdmin={false}><ProductList isActive={isActive} setIsActive={setIsActive} /></ProtectedRoute>} />
                                        <Route path="/admin/category" element={<ProtectedRoute isAdmin={true} isSubAdmin={false}><CategoryList isActive={isActive} setIsActive={setIsActive} /></ProtectedRoute>} />
                                        <Route path="/admin/add-category" element={<ProtectedRoute isAdmin={true} isSubAdmin={false}><NewCategory isActive={isActive} setIsActive={setIsActive} /></ProtectedRoute>} />
                                        <Route path="/admin/update-category/:id" element={<ProtectedRoute isAdmin={true} isSubAdmin={false}><UpdateCategory isActive={isActive} setIsActive={setIsActive} /></ProtectedRoute>} />
                                        <Route path="/admin/measurement" element={<ProtectedRoute isAdmin={true} isSubAdmin={false}><MeasurementList isActive={isActive} setIsActive={setIsActive} /></ProtectedRoute>} />
                                        <Route path="/admin/add-measurement" element={<ProtectedRoute isAdmin={true} isSubAdmin={false}><NewMeasurement isActive={isActive} setIsActive={setIsActive} /></ProtectedRoute>} />
                                        <Route path="/admin/update-measurement/:id" element={<ProtectedRoute isAdmin={true} isSubAdmin={false}><UpdateMeasurement isActive={isActive} setIsActive={setIsActive} /></ProtectedRoute>} />
                                        <Route path="/admin/products/create" element={<ProtectedRoute isAdmin={true} isSubAdmin={false}><NewProduct isActive={isActive} setIsActive={setIsActive} /></ProtectedRoute>} />
                                        <Route path="/admin/product/:id" element={<ProtectedRoute isAdmin={true} isSubAdmin={false}><UpdateProduct isActive={isActive} setIsActive={setIsActive} /></ProtectedRoute>} />
                                        <Route path="/admin/getenquiry" element={<ProtectedRoute isAdmin={true} isSubAdmin={false}><EnquiryRequest isActive={isActive} setIsActive={setIsActive} /></ProtectedRoute>} />
                                        <Route path='/admin/users' element={<ProtectedRoute isAdmin={true} isSubAdmin={false}><UserList isActive={isActive} setIsActive={setIsActive} /></ProtectedRoute>} />
                                        <Route path='/admin/payments' element={<ProtectedRoute isAdmin={true} isSubAdmin={false}><PaymentList isActive={isActive} setIsActive={setIsActive} /></ProtectedRoute>} />
                                        <Route path="/admin/dispatch/:id" element={<ProtectedRoute isAdmin={true} isSubAdmin={false}><Dispatch isActive={isActive} setIsActive={setIsActive} /></ProtectedRoute>} />
                                        <Route path="/admin/dispatch" element={<ProtectedRoute isAdmin={true} isSubAdmin={false}><DispatchList isActive={isActive} setIsActive={setIsActive} /></ProtectedRoute>} />
                                        <Route path="/admin/refund/:id" element={<ProtectedRoute isAdmin={true} isSubAdmin={false}><RefundOrder isActive={isActive} setIsActive={setIsActive} /></ProtectedRoute>} />
                                        <Route path="/admin/refund" element={<ProtectedRoute isAdmin={true} isSubAdmin={false}><RefundList isActive={isActive} setIsActive={setIsActive} /></ProtectedRoute>} />
                                        <Route path='/admin/user/:id' element={<ProtectedRoute isAdmin={true} isSubAdmin={false}><UpdateUser isActive={isActive} setIsActive={setIsActive} /></ProtectedRoute>} />
                                        <Route path='/admin/products/updateprice' element={<ProtectedRoute isAdmin={true} isSubAdmin={true}><UpdatePrice isActive={isActive} setIsActive={setIsActive} /></ProtectedRoute>} />
                                        <Route path='/admin/orders' element={<ProtectedRoute isAdmin={true} isSubAdmin={false}><OrderList isActive={isActive} setIsActive={setIsActive} /></ProtectedRoute>} />
                                        <Route path='/admin/analysis' element={<ProtectedRoute isAdmin={true} isSubAdmin={false}><Analysis isActive={isActive} setIsActive={setIsActive} /></ProtectedRoute>} />
                                        <Route path='/admin/order/:id' element={<ProtectedRoute isAdmin={true} isSubAdmin={false}><UpdateOrder isActive={isActive} setIsActive={setIsActive} /></ProtectedRoute>} />
                                        <Route path='/admin/order-summary' element={<ProtectedRoute isAdmin={true} isSubAdmin={true}><OrderSummary isActive={isActive} setIsActive={setIsActive} /></ProtectedRoute>} />
                                        <Route path='/admin/user-summary' element={<ProtectedRoute isAdmin={true} isSubAdmin={true}><SummaryUser isActive={isActive} setIsActive={setIsActive} /></ProtectedRoute>} />
                                        <Route path='/admin/allorders' element={<ProtectedRoute isAdmin={true} isSubAdmin={false}><AllOrders isActive={isActive} setIsActive={setIsActive} /></ProtectedRoute>} />
                                        <Route path='/admin/orderdetail/:id' element={<ProtectedRoute isAdmin={true} isSubAdmin={false}><AdminOrderDetail isActive={isActive} setIsActive={setIsActive} /></ProtectedRoute>} />
                                        <Route path='/admin/addressList/:id' element={<ProtectedRoute isAdmin={true} isSubAdmin={false}><UserAddressList isActive={isActive} setIsActive={setIsActive} /></ProtectedRoute>} />
                                        <Route path='/admin/orderReport' element={<ProtectedRoute isAdmin={true} isSubAdmin={false}><OrderReport isActive={isActive} setIsActive={setIsActive} /></ProtectedRoute>} />
                                        <Route path="*" element={<Navigate to="/page-not-found" replace />} />
                                        <Route path="/page-not-found" element={<PageNotFound />} />


                                    </Routes>
                                    {!isAdminRoute && <Footer className='footer' openSide={openSide} setOpenSide={setOpenSide} onLoginClick={handleLoginClick} setShowLoginModal={setShowLoginModal} />}
                                    <ToastContainer theme="dark" />

                                </div>
                                {/* Floating WhatsApp Icon */}
                                {(!isAdminRoute && !isMapRoute) &&
                                    <a
                                        href={whatsappUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="whatsapp-float"
                                    >
                                        <img
                                            src="/WhatsApp_icon.png"
                                            alt="WhatsApp"
                                            className="whatsapp-icon"
                                        />
                                    </a>
                                }


                            </div>
                        )
                }


            </HelmetProvider>
        </div>
    );
}

function RootApp() {

    return (
        <Router>
            <App />
        </Router>
    );
}

export default RootApp;