import React, { Fragment, useState, useEffect } from 'react';
import axios from 'axios';
import { Slide, toast } from 'react-toastify';
import Sidebar from '../admin/Sidebar'; // Assuming Sidebar component is in this path
import { useLocation, useNavigate } from 'react-router-dom';
import MetaData from '../Layouts/MetaData';
import { getAdminProducts } from '../../actions/productsActions';
import { useDispatch } from 'react-redux';
import LoaderButton from '../Layouts/LoaderButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const UpdatePrice = ({ isActive, setIsActive }) => {
    const [items, setItems] = useState([]);
    const [file, setFile] = useState(null);
    const [loading, setloading] = useState(false);
    const [downloadloading, setdownloadloading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        const maxSize = 5 * 1024 * 1024; // 10 MB in bytes

        if (selectedFile && selectedFile.size && selectedFile.size > maxSize) {
            toast.dismiss();
            setTimeout(() => {
                toast.error('The file size exceeds the 5MB limit.', {
                    position: 'bottom-center',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                });
            }, 300);
            setFile(null); // Reset the file state
            return
        } else {
            setFile(selectedFile);
        }
    };


    const handleUpload = async () => {
        try {
            setloading(true);
            const formData = new FormData();
            if (!file) {
                toast.dismiss();
                setTimeout(() => {
                    toast.error('Please Select the File.', {
                        position: 'bottom-center',
                        type: 'error',
                        autoClose: 700,
                        transition: Slide,
                        hideProgressBar: true,
                        className: 'small-toast',
                    });
                }, 300);
                setloading(false);
                return;
            }
            formData.append('Prices', file);
            const data = await axios.post('/api/v1/upload/price', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (data && data.status === 200) {
                setloading(false);
                setItems([]);                 // Clear items
                setFile(null);                // Clear file
                toast.dismiss();
                setTimeout(() => {
                    toast.success(data.data.message, {
                        position: 'bottom-center',
                        type: 'success',
                        autoClose: 700,
                        transition: Slide,
                        hideProgressBar: true,
                        className: 'small-toast',
                        onClose: () => {
                            dispatch(getAdminProducts()); // Dispatch after toast is closed
                        },
                    });
                }, 300);

                if (data.data.warnings && data.data.warnings) {
                    data.data.warnings.forEach((element) => {
                        toast.warning(element);
                    });
                }

            }
            else {
                toast.dismiss();
                setTimeout(() => {
                    toast.error('Error uploading file. Please try again.', {
                        position: 'bottom-center',
                        type: 'error',
                        autoClose: 700,
                        transition: Slide,
                        hideProgressBar: true,
                        className: 'small-toast',
                    });
                }, 300);
            }


        } catch (error) {
            setloading(false);
            toast.dismiss();
            setTimeout(() => {
                toast.error('Error uploading file.', {
                    position: 'bottom-center',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                });
            }, 300);
        }
    };


    useEffect(() => {
        fetch('/api/v1/getproducts')
            .then(response => response.json())
            .then(data => {
                setItems(data.getitems);
            })
            .catch(error => {
                toast.dismiss();
                setTimeout(() => {
                    toast.error('Error fetching data in get Products.', {
                        position: 'bottom-center',
                        type: 'error',
                        autoClose: 700,
                        transition: Slide,
                        hideProgressBar: true,
                        className: 'small-toast',
                    });
                }, 300);
            });

    }, []);

    const downloadCSV = () => {
        setdownloadloading(true);
        const currentDate = new Date().toISOString().split('T')[0];
        fetch('/api/v1/download/price?format=xlsx')
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `items-${currentDate}.xlsx`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                setdownloadloading(false);
            })
            .catch(error => {
                toast.dismiss();
                setTimeout(() => {
                    toast.error('Error downloading file:', {
                        position: 'bottom-center',
                        type: 'error',
                        autoClose: 700,
                        transition: Slide,
                        hideProgressBar: true,
                        className: 'small-toast',
                    });
                }, 300);
            });

        setdownloadloading(false);
    };

    return (
        <div>
            <MetaData
                title="Update Price"
                description="Update product pricing across your catalog. Adjust prices based on sales, market trends, or product performance."
            />


            <div className="container-fluid" style={{ height: 'auto' }}>
                <div className="row">
                    <div className="col-12 col-md-2 p-0">
                        <div style={{ display: 'flex', flexDirection: 'row', position: 'fixed', top: '0px', zIndex: 99999, backgroundColor: '#fff', minWidth: '100%' }}>
                            <Sidebar isActive={isActive} setIsActive={setIsActive} />
                        </div>
                    </div>
                    <div className="col-12 col-md-10 smalldevice-space">
                        <h3 className="mb-4 admin-dashboard-x">Product Price Upload</h3>
                        <div className="">
                            <span className="back-button" onClick={() => navigate(-1)}>
                                <ArrowBackIcon fontSize="small" />
                                <span>Back</span>
                            </span>

                        </div>
                        <Fragment>
                            <div className="row" style={{ marginTop: '5%' }}>
                                <div className="col-12 col-md-6 mb-4 ">
                                    <div className="card update_price h-100" >
                                        <div className="card-body">
                                            <h5 className="card-title ml-3">Import Product Details Info (*File size should be within 5mb)</h5>
                                            <div className="mb-3 d-flex flex-column align-items-center ml-3">
                                                <input type="file" onChange={handleFileChange} accept=".xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" className="form-control mb-2" />
                                                <button className="btn btn-success" onClick={handleUpload} disabled={loading}>
                                                    {loading ? <LoaderButton fullPage={false} size={20} /> : (
                                                        <span>  Upload Price</span>
                                                    )

                                                    }

                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 mb-4">
                                    <div className="card update_price h-100">
                                        <div className="card-body">
                                            <h5 className="card-title ml-3">Export Product Details</h5>
                                            <button className="btn btn-primary ml-3" onClick={downloadCSV} disabled={downloadloading || loading}>
                                                {downloadloading ? <LoaderButton fullPage={false} size={20} /> : (
                                                    <span>   Download Price</span>
                                                )

                                                }


                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    </div>
                    
                </div>                              
            </div>
        </div>
    );
};

export default UpdatePrice;
