import { createSlice } from "@reduxjs/toolkit";

const discountSlices = createSlice({
    name: 'discount',
    initialState: {
        discountLoading: false,
        discountData: null,
        discountError: null,
        discountsError:null,
        discounts:null,
        deleteloading:false,
        discountsDeleteSuccess:null,
        discountsDeleteError:null,
        singleDiscountLoading:false,
        discountSingleSuccess:null,
        discountSingleError:null,
        discountsUpdateLoading:false,
        discountsUpdateSuccess:null,
        discountsUpdateError:null,
        deleteSingleloading:false,
        discountDeleteSuccess:null,
        discountDeleteError:null,

    },
    reducers: {
        newDiscountRequest(state) {
            state.discountLoading = true;
        },
        newDiscountSuccess(state, action) {
            state.discountLoading = false;
            state.discountData = action.payload;
        },
        newDiscountFail(state, action) {
            state.discountLoading = false;
            state.discountError = action.payload;
        },
        clearNewDiscount(state, action) {
            state.discountLoading = false;
            state.discountError = null;
            state.discountData = null;
        },
        discountsGetRequest(state) {
            state.getloading = true;
        },
        discountsGetSuccess(state, action) {
            state.getloading = false;
            state.discounts = action.payload;
        },
        discountsGetFail(state, action) {
            state.getloading = false;
            state.discountsError = action.payload;
        },
        clearGetdiscounts(state, action) {
            state.getloading = false;
            state.discountsError = null;
            state.discounts = null;
        },
        discountsDeleteRequest(state) {
            state.deleteloading = true;
        },
        discountsDeleteSuccess(state, action) {
            state.deleteloading = false;
            state.discountsDeleteSuccess = action.payload;
        },
        discountsDeleteFail(state, action) {
            state.deleteloading = false;
            state.discountsDeleteError = action.payload;
        },
        clearDiscountsDelete(state, action) {
            state.deleteloading = false;
            state.discountsDeleteError = null;
            state.discountsDeleteSuccess = null;
        },
        discountSingleRequest(state) {
            state.singleDiscountLoading = true;
        },
        discountSingleSuccess(state, action) {
            state.singleDiscountLoading = false;
            state.discountSingleSuccess = action.payload.discount;
        },
        discountSingleFail(state, action) {
            state.singleDiscountLoading = false;
            state.discountSingleError = action.payload;
        },
        clearSingleDiscounts(state, action) {
            state.singleDiscountLoading = false;
            state.discountSingleError = null;
            state.discountSingleSuccess = null;
        },
        discountsUpdateRequest(state) {
            state.discountsUpdateLoading = true;
        },
        discountsUpdateSuccess(state, action) {
            state.discountsUpdateLoading = false;
            state.discountsUpdateSuccess = action.payload;
        },
        discountsUpdateFail(state, action) {
            state.discountsUpdateLoading = false;
            state.discountsUpdateError = action.payload;
        },
        clearDiscountsUpdate(state, action) {
            state.discountsUpdateLoading = false;
            state.discountsUpdateError = null;
            state.discountsUpdateSuccess = null;
        },
        discountDeleteRequest(state) {
            state.deleteSingleloading = true;
        },
        discountDeleteSuccess(state, action) {
            state.deleteSingleloading = false;
            state.discountDeleteSuccess = action.payload;
        },
        discountDeleteFail(state, action) {
            state.deleteSingleloading = false;
            state.discountDeleteError = action.payload;
        },
        clearDiscountDelete(state, action) {
            state.deleteSingleloading = false;
            state.discountDeleteError = null;
            state.discountDeleteSuccess = null;
        },
        
    },
});

const { actions, reducer } = discountSlices;

export const {
    newDiscountRequest,
    newDiscountSuccess,
    newDiscountFail,
    clearNewDiscount,
    discountsGetRequest,
    discountsGetSuccess,
    discountsGetFail,
    clearGetdiscounts,
    discountsDeleteRequest,
    discountsDeleteSuccess,
    discountsDeleteFail,
    clearDiscountsDelete,
    discountSingleRequest,
    discountSingleSuccess,
    discountSingleFail,
    clearSingleDiscounts,
    discountsUpdateRequest,
    discountsUpdateSuccess,
    discountsUpdateFail,
    clearDiscountsUpdate,
    discountDeleteRequest,
    discountDeleteSuccess,
    discountDeleteFail,
    clearDiscountDelete,
} = actions;

export default reducer;