import { Fragment, useEffect, useState } from 'react'
import MetaData from '../Layouts/MetaData';
import { MDBDataTable } from 'mdbreact'
import { useDispatch, useSelector } from 'react-redux';
import { userOrders as userOrdersAction } from '../../actions/orderActions';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import Loader from '../Layouts/Loader';
import { Slide, toast } from "react-toastify";
import { clearError, orderDetailClear } from '../../slices/orderSlice';
import { porterClearData, porterClearResponse } from '../../slices/porterSlice';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


export default function UserOrders() {

    const { loading, userOrders, error } = useSelector(state => state.orderState)
    const dispatch = useDispatch();
    const [iserror, setIserror] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    console.log("userOrders", userOrders)
    useEffect(() => {
        dispatch(orderDetailClear());
        dispatch(porterClearData());
        dispatch(porterClearResponse());
    }, [])

    useEffect(() => {
        if (error) {
            toast.dismiss();
            setTimeout(() => {
                toast.error(error, {
                    position: 'bottom-center',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onOpen: () => { dispatch(clearError()) }
                });
            }, 300);
            setIserror(true)
            return;
        }
    }, [error])

    useEffect(() => {
        if (!userOrders && !iserror) {
            dispatch(userOrdersAction());
        }

    }, [userOrders, iserror])


    const setOrders = () => {
        const data = {
            columns: [
                {
                    label: 'S.No',
                    field: 's_no',
                    sort: 'disabled'
                },
                {
                    label: "Order ID",
                    field: 'id',
                    sort: "disabled"
                },
                {
                    label: "TimeStamp",
                    field: 'time',
                    sort: "disabled"
                },
                {
                    label: "Name",
                    field: 'name',
                    sort: "disabled"
                },
                {
                    label: "Amount",
                    field: 'amount',
                    sort: "disabled"
                },
                {
                    label: "Status",
                    field: 'status',
                    sort: "disabled"
                },
                {
                    label: "Actions",
                    field: 'actions',
                    sort: "disabled"
                }
            ],
            rows: []
        }

        // Sort orders by creation date (newest first)
        const sortedOrders = userOrders && [...userOrders].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        sortedOrders && sortedOrders.forEach((userOrder, index) => {
            data.rows.push({
                s_no: index + 1,
                id: userOrder.order_id,
                time: userOrder.createdAt,
                name: userOrder?.shippingInfo?.name,
                amount: `${userOrder.totalPrice} Rs`,
                status: userOrder.orderStatus && userOrder.orderStatus.includes('Delivered') ?
                    (<p style={{ color: 'green', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px', padding: '0px' }}> {userOrder.orderStatus} </p>) :
                    (<p style={{ color: 'red', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px', padding: '0px' }}> {userOrder && userOrder.paymentStatus && userOrder.paymentStatus === 'CHARGED' ? userOrder.orderStatus : 'Cancelled'} </p>),
                actions: <Link to={`/order/${userOrder.order_id}`} className="userorder_btn" >
                    {/* <i className='fa fa-eye'></i> */}
                    View
                </Link>
            })
        })


        return data;
    }


    return (
        <Fragment>
            <MetaData
                title="Your Orders"
                description="Check all your previous and current orders in one place. Track shipments, view details, and manage your purchases with ease."
            />

            <div className="products_heading">Orders</div>
            <div className="">
                <span className="back-button" onClick={() => navigate(-1)}>
                    <ArrowBackIcon fontSize="small" />
                    <span>Back</span>
                </span>

            </div>

            {loading ?
                <div style={{ marginTop: '4rem' }}>
                    <Loader />
                </div>

                : <div className='mdb-table' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Fragment>
                        <MDBDataTable
                            data={setOrders()}
                            bordered
                            hover
                            className="px-3 userproduct-table"
                            noBottomColumns
                        />
                    </Fragment>
                </div>}
        </Fragment>
    )
}

