import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import Container from '@mui/material/Container';
import InputBase from '@mui/material/InputBase';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled, alpha, createTheme, ThemeProvider } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../actions/userActions';
import { userOrdersClear } from '../../slices/orderSlice';
import { clearUser, clearlogout, reset } from '../../slices/authSlice';
import { Slide, toast } from 'react-toastify';
import PersonIcon from '@mui/icons-material/Person';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import HistoryIcon from '@mui/icons-material/History';


const theme = createTheme({
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff', // Replace with your desired color
          color: 'black', // Text color
        },
      },
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff', // Replace with your desired color
          color: 'black', // Text color
        },
      },
    },
  },
});

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  transition: 'all 0.3s ease-in-out',
  minWidth: '90%',
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

const Header = ({ openSide, setOpenSide, onLoginClick, filteredAnnouncements }) => {
  const [searchOpen, setSearchOpen] = useState(false);
  const [bottomNavValue, setBottomNavValue] = useState(0);
  const [loginanchorEl, setloginanchorEl] = useState(null);
  const [categoryAnchorEl, setCategoryAnchorEl] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [lastNonSearchRoute, setLastNonSearchRoute] = useState(null);
  const navigate = useNavigate();
  const { getcategory } = useSelector((state) => state.categoryState);

  const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => setWindowWidth(window.innerWidth);

      window.addEventListener('resize', handleResize);

      // Cleanup event listener on component unmount
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowWidth;
  };
  const windowWidth = useWindowWidth();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { isAuthenticated, user, loggedoutmessage, isloggedout } = useSelector(state => state.authState);
  const { items: cartItems } = useSelector(state => state.cartState);
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(false);
  const { loading, userOrders, error } = useSelector(state => state.orderState)

  const logoutHandler = (e) => {
    if (userOrders) {
      dispatch(userOrdersClear());
    }
    dispatch(logout);
    sessionStorage.clear();
    setRefresh(true);
  }

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    if (isloggedout && !isAuthenticated && !user && refresh) {
      sessionStorage.clear();
      setRefresh(false);
      toast.dismiss();
      setTimeout(() => {
        toast.success(loggedoutmessage, {
          position: 'bottom-center',
          type: 'success',
          autoClose: 100,
          transition: Slide,
          hideProgressBar: true,
          className: 'small-toast',
          onClose: () => {
            dispatch(clearlogout());
            dispatch(clearUser());
          },

        });
        setTimeout(() => {
          window.location.replace('/'); // Fallback to replace current page
        }, 200);

      }, 10);
    }
  }, [isloggedout, dispatch, refresh])

  const changeToggle = () => {
    setOpenSide(!openSide);
  }
  const getInitials = (name) => {
    return name ? name.charAt(0).toUpperCase() : '';
  };


  const handleNavigation = (route) => {
    navigate(route);
  };

  const [monthlyGroceriesAnchorEl, setMonthlyGroceriesAnchorEl] = useState(null);

  const handleMonthlyGroceriesClick = (event) => {
    setMonthlyGroceriesAnchorEl(event.currentTarget);
  };
  const handleMonthlyGroceriesClose = () => {
    setMonthlyGroceriesAnchorEl(null);
  };

  const HandelProfile = () => {
    if (!user || !isAuthenticated) {
      onLoginClick();
    }
    else {
      navigate('/profile');
    }
  }


  useEffect(() => {
    // Track the last route before opening the search
    if (searchOpen && searchQuery === '') {
      const currentPath = window.location.pathname + window.location.search;
      if (!currentPath.includes('/product/search')) {
        setLastNonSearchRoute(currentPath);
      }
    }
  }, [searchOpen, searchQuery]);

  useEffect(() => {
    if (searchOpen) {
      const debounceTimeout = setTimeout(() => {
        if (searchQuery.trim() !== '') {
          navigate(`/product/search?query=${encodeURIComponent(searchQuery)}`);
        } else if (lastNonSearchRoute) {
          navigate(lastNonSearchRoute); // Navigate back to the last valid route
        } else {
          navigate('/'); // Fallback to the home page
        }
      }, 300); // Debounce delay
      return () => clearTimeout(debounceTimeout);
    }
  }, [searchQuery, searchOpen, navigate, lastNonSearchRoute]);

  const handleSearchClose = () => {
    setSearchOpen(false);
    if (lastNonSearchRoute) {
      navigate(lastNonSearchRoute); // Navigate back to the last route when closing search
    } else {
      navigate('/'); // Fallback to the home page
    }
  };

  const handleSearchOpen = () => setSearchOpen(true);

  const handleAvatarClick = (event) => {
    setloginanchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setloginanchorEl(null);
  };

  useEffect(() => {
    handleMenuClose();
  }, [])

  const handleCategoryClick = (event) => setCategoryAnchorEl(event.currentTarget);
  const handleCategoryClose = () => setCategoryAnchorEl(null);

  // const categories = Array.from({ length: 100 }, (_, index) => `Category ${index + 1}`);

  const handleDealsOpen = () => {
    navigate('/deals');
  }

  return (
    <header className={filteredAnnouncements && filteredAnnouncements.length > 0 ? 'main-header-marquee' : "main-header"}>
      <AppBar
        position="sticky"
        sx={{
          top: 0,
          zIndex: 1300,
          backgroundColor: '#fff', // Inlined for specificity
          color: 'black',
          boxShadow: 'none',
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>


            {searchOpen ? (
              <Box sx={{ display: 'flex', alignItems: 'center', minwidth: '100%' }}>
                <IconButton onClick={handleSearchClose} color="inherit">
                  <ArrowBackIcon />
                </IconButton>
                <Search sx={{ display: 'flex', minwidth: '98%' }}>
                  <StyledInputBase placeholder="Search…" inputProps={{ 'aria-label': 'search' }} value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                </Search>
              </Box>
            ) : (
              <>
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  sx={{
                    display: { xs: searchOpen ? 'none' : 'block', md: 'block' },
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    letterSpacing: '.2rem',
                    color: 'inherit',
                    textDecoration: 'none',
                  }}
                >
                  <Link to="/" className="navbar-brand">
                    <img
                      src="/images/logo.png"
                      alt="logo"
                      style={{
                        padding: '3px',
                        margin: '0px',
                        width: '100%',
                        maxWidth: '200px',
                        height: 'auto', // Adjust height to maintain aspect ratio
                      }}
                    />
                  </Link>
                </Typography>

                <Box sx={{ flexGrow: 1 }} />
                <IconButton onClick={handleSearchOpen} color="inherit" sx={{ ml: 'auto' }} className='search-header'>
                  <SearchIcon />
                </IconButton>

                <IconButton
                  onClick={handleCategoryClick}
                  className='navbar-link hover-container'
                  sx={{
                    display: { xs: 'none', md: 'flex' },
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    // mr: 1,
                    backgroundColor: 'transparent',
                    border: 'none',
                    borderRadius: '0px',
                    outline: 'none',
                    boxShadow: 'none',
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                    '&:focus': {
                      outline: 'none',
                    },
                    '&:active': {
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                    },
                    transition: 'none',
                  }}
                >

                  <div >
                    <Typography variant="button" color="inherit" style={{ color: '#02441E', fontWeight: '700px', textTransform: 'none' }} >
                      Order Here
                    </Typography>
                    <ArrowDropDownIcon />
                  </div>
                  <Typography
                    variant="caption"
                    color="inherit"
                    sx={{
                      marginTop: '-4px',
                      fontSize: '10px',
                      color: 'lightcoral', // Start with lightcoral color
                      fontWeight: 'bold',
                      animation: 'smoothZoom 1.5s infinite ease-in-out',
                      transition: 'color 1.5s ease-in-out', // Smooth color transition

                    }}
                  >
                    Fruits & Vegetables
                    <style>
                      {`
      @keyframes smoothZoom {
        0% {
          color: lightcoral;
          fontWeight: 'bold',
          transform: scale(1);
        }
        50% {
          color: red;
          transform: scale(1.2);
        }
        100% {
          color: lightcoral;
          transform: scale(1);
        }
      }
    `}
                    </style>
                  </Typography>

                </IconButton>
                <Menu
                  anchorEl={categoryAnchorEl}
                  open={Boolean(categoryAnchorEl)}
                  onClose={handleCategoryClose}
                  slotProps={{
                    paper: {
                      width: 'auto',
                      minwidth: '100%',
                      padding: '8px',
                      maxheight: 'calc(50vh)', // Max height for the category list
                      overflow: 'hidden', // Hide overflow to prevent scrolling
                    },
                  }}
                  anchorOrigin={{
                    vertical: 'bottom', // Align the menu's bottom edge with the bottom of the button
                    horizontal: 'center', // Center the menu horizontally relative to the button
                  }}
                  transformOrigin={{
                    vertical: 'top', // Align the top edge of the menu with the bottom of the button
                    horizontal: 'center', // Center the menu horizontally relative to the button
                  }}
                >
                  {Array.from({ length: Math.ceil(getcategory?.filter(cat => cat.type === 'Fresh').length / 10) }).map((_, columnIndex) => (
                    <Box key={columnIndex} sx={{
                      display: 'grid',
                      gridTemplateColumns: `repeat(${Math.min(Math.ceil(getcategory?.filter(cat => cat.type === 'Fresh').length / 10), 4)}, 1fr)`, // Adjust columns dynamically based on item count, max 4 columns
                      maxheight: 'calc(50vh)', // Limit the max height of the grid
                      overflowY: 'hidden', // Allow vertical scrolling if categories exceed the height
                      justifyContent: 'center',
                    }}>
                      {/* Create a column with up to 10 "Fresh" categories */}
                      {getcategory?.filter(category => category.type === 'Fresh') // Filter for "Fresh" type
                        .slice(columnIndex * 10, columnIndex * 10 + 10) // Slice for pagination
                        .map((category) => (
                          <MenuItem
                            key={category._id}

                            onClick={() => {
                              navigate(`/categories/${category.category}/type/${category.type}`, {
                                state: { category: category.category, type: category.type },
                              });
                              handleCategoryClose();
                            }}
                            sx={{ py: 1 }}
                          >
                            {category.category}
                          </MenuItem>
                        ))}
                    </Box>
                  ))}
                </Menu>

                {/* <IconButton onClick={handleDealsOpen} color="inherit" sx={{ ml: 'auto' }}>
                  Deals
                </IconButton> */}
                <IconButton
                  onClick={handleDealsOpen}
                  className='navbar-link hover-container'
                  sx={{
                    display: { xs: 'none', md: 'flex' },
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    // mr: 1,
                    backgroundColor: 'transparent',
                    border: 'none',
                    borderRadius: '0px',
                    outline: 'none',
                    boxShadow: 'none',
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                    '&:focus': {
                      outline: 'none',
                    },
                    '&:active': {
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                    },
                    transition: 'none',
                  }}
                >

                  <div >
                    <Typography variant="button" color="inherit" style={{ color: '#02441E', fontWeight: '700px', textTransform: 'none' }} >
                      Offers
                    </Typography>
                    {/* <ArrowDropDownIcon /> */}
                  </div>
                  <Typography
                    variant="caption"
                    color="inherit"
                    sx={{
                      marginTop: '-4px',
                      fontSize: '10px',
                      color: 'lightcoral', // Start with lightcoral color
                      fontWeight: 'bold',
                      animation: 'smoothZoom 1.5s infinite ease-in-out',
                      transition: 'color 1.5s ease-in-out', // Smooth color transition

                    }}
                  >
                    Deal of the Day
                    <style>
                      {`
      @keyframes smoothZoom {
        0% {
          color: lightcoral;
          fontWeight: 'bold',
          transform: scale(1);
        }
        50% {
          color: red;
          transform: scale(1.2);
        }
        100% {
          color: lightcoral;
          transform: scale(1);
        }
      }
    `}
                    </style>
                  </Typography>

                </IconButton>


                {/* {
                  user && (user.role === 'admin') && (
                    <>
                      <IconButton
                        onClick={handleMonthlyGroceriesClick}
                        className='navbar-link'
                        sx={{
                          display: { xs: 'none', md: 'flex' },
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          mr: 1,
                          backgroundColor: 'transparent',
                          border: 'none',
                          outline: 'none',
                          boxShadow: 'none',
                          '&:hover': {
                            backgroundColor: 'transparent',
                          },
                          '&:focus': {
                            outline: 'none',
                          },
                          '&:active': {
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                          },
                          transition: 'none',
                        }}
                      >
                        <div>
                          <Typography variant="button" color="inherit" style={{ color: '#006400', fontWeight: '700px', textTransform: 'none' }}>
                            Monthly Groceries
                          </Typography>
                          <ArrowDropDownIcon />
                        </div>
                        <Typography
                          variant="caption"
                          color="inherit"
                          sx={{
                            marginTop: '-4px',
                            fontSize: '10px',
                            color: '#757575',
                          }}
                        >
                          Essentials & More
                        </Typography>
                      </IconButton>

                      <Menu
                        anchorEl={monthlyGroceriesAnchorEl}
                        open={Boolean(monthlyGroceriesAnchorEl)}
                        onClose={handleMonthlyGroceriesClose}
                        sx={{
                          width: 'auto',

                          padding: '8px',
                          maxheight: 'calc(50vh)',
                          overflow: 'hidden',
                          display: 'flex',
                        }}
                        anchorOrigin={{
                          vertical: 'bottom', 
                          horizontal: 'center', 
                        }}
                        transformOrigin={{
                          vertical: 'top', 
                          horizontal: 'center', 
                        }}
                      >
                        {Array.from({ length: Math.ceil(getcategory?.filter(cat => cat.type === 'Groceries').length / 10) }).map((_, columnIndex) => (
                          <Box key={columnIndex} sx={{
                            display: 'grid',
                            minwidth: '100px',
                            gridTemplateColumns: `repeat(${Math.min(Math.ceil(getcategory?.filter(cat => cat.type === 'Groceries').length / 10), 4)}, 1fr)`, // Adjust columns dynamically based on item count, max 4 columns
                            maxheight: 'calc(50vh)', 
                            overflowY: 'hidden', 
                          }}>
                           
                            {getcategory?.filter(category => category.type === 'Groceries') 
                              .map((category) => (
                                <MenuItem
                                  key={category._id}
                                  onClick={() => {
                                    navigate(`/categories/${category.category}/type/${category.type}`, {
                                      state: { category: category.category, type: category.type },
                                    });
                                    handleMonthlyGroceriesClose();
                                  }}
                                  sx={{ py: 1 }}
                                >
                                  {category.category}
                                </MenuItem>
                              ))}
                          </Box>
                        ))}
                      </Menu>
                    </>
                  )
                } */}

                {isAuthenticated && user ? (
                  <>
                    <IconButton
                      onClick={handleAvatarClick}
                      className='navbar-link hover-container'

                      // className="hover-container"
                      sx={{
                        display: { xs: 'none', md: 'flex' },
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        // mr: 1,
                        backgroundColor: 'transparent',
                        border: 'none',
                        borderRadius: '0px',
                        outline: 'none',
                        boxShadow: 'none',
                        '&:hover': {
                          backgroundColor: 'transparent',
                        },
                        '&:focus': {
                          outline: 'none',
                        },
                        '&:active': {
                          backgroundColor: 'transparent',
                          boxShadow: 'none',
                        },
                        transition: 'none',
                      }}
                    >
                      <div style={{ display: 'flex', flexDirection: 'column', position: 'relative',padding:'3px' }}>
                        {user && user.name ? (
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div className="avatar-initials">{getInitials(user.name)}</div>
                            <div><ArrowDropDownIcon /></div>
                          </div>
                        ) : (
                          <div className="welcome-text ">Welcome  <ArrowDropDownIcon className='navbar-link' /></div>
                        )}

                      </div>
                    </IconButton>
                    <Menu
                      sx={{ mt: 6 }}
                      id="menu-appbar"
                      anchorEl={loginanchorEl}
                      open={Boolean(loginanchorEl)}
                      onClose={handleMenuClose}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >

                      {user && user.role === 'admin' ? (
                        <MenuItem onClick={() => {
                          navigate('/admin/dashboard');
                          handleMenuClose();
                        }}>
                          <DashboardIcon style={{ marginRight: '5px' }} /> Dashboard
                        </MenuItem>

                      ) : user && user.role === 'subadmin' ? (
                        <MenuItem onClick={() => {
                          navigate('/admin/products/updateprice');
                          handleMenuClose();
                        }}>
                          <DashboardIcon style={{ marginRight: '5px' }} /> Dashboard
                        </MenuItem>

                      ) : (<></>)
                      }

                      {/* Profile menu items */}
                      <MenuItem onClick={() => {
                        navigate('/myprofile');
                        handleMenuClose();
                      }}>
                        <PersonIcon style={{ marginRight: '5px' }} /> Profile
                      </MenuItem>

                      <MenuItem onClick={() => {
                        navigate('/orders');
                        handleMenuClose();
                      }}>
                        <HistoryIcon style={{ marginRight: '5px' }} /> Orders
                      </MenuItem>

                      <MenuItem onClick={() => {
                        navigate('/wallet');
                        handleMenuClose();
                      }}>
                        <AccountBalanceWalletIcon style={{ marginRight: '5px' }} /> Wallet
                      </MenuItem>

                      <MenuItem onClick={(e) => {
                        logoutHandler(e);
                        handleMenuClose();
                      }}>
                        <LogoutIcon style={{ marginRight: '5px' }} /> Logout
                      </MenuItem>
                    </Menu>
                  </>
                ) : (
                  <IconButton
                    onClick={(e) => {
                      handleAvatarClick(e);
                      onLoginClick();
                      handleMenuClose();
                    }}
                    className='hover-container login-btn-header navbar-link'
                    // id="login_btn"
                    sx={{
                      display: {
                        xs: 'none', md: 'flex', backgroundColor: 'transparent',
                        border: 'none', outline: 'none', boxShadow: 'none',
                        borderRadius: '0px',
                        '&:hover': { backgroundColor: 'transparent' },
                        '&:focus': { outline: 'none' },
                        '&:active': { backgroundColor: 'transparent', boxShadow: 'none' },
                        transition: 'none',
                      }
                    }}>
                    <div style={{ display: 'flex', flexDirection: 'column', position: 'relative', fontSize: '18px' }}>
                      <Link onClick={(e) => {
                        onLoginClick();
                        handleMenuClose();
                      }} className="navbar-link" id="login_btn" style={{color:'#02441E'}}>
                        LOGIN <i className="fa fa-sign-out" style={{ marginLeft: '3px' }}></i>
                      </Link>
                    </div>
                  </IconButton>
                )}

                <button
                  className="btn btn-success header-button"
                  style={{
                    margin: '12px',
                    maxWidth: '200px', // Restrict maximum width
                    height: 'auto', // Allow height to adjust based on content
                    padding: '8px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'row', // Align items horizontally
                  }}
                >
                  <Link
                    to="/cart"
                    className="navbar-link cart-container"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      textDecoration: 'none',
                      color: 'inherit',
                    }}
                  >
                    {cartItems?.length > 0 ? (
                      <i
                        className="fa fa-shopping-cart cart-icon"
                        style={{
                          marginRight: '7px', // Spacing between icon and text
                        }}

                      ></i>
                    ) : (
                      // <>
                      //   <i
                      //     className="fa fa-shopping-cart cart-icon"
                      //     style={{
                      //       marginRight: '7px', // Spacing between icon and text
                      //     }}
                      //     onClick={() => {
                      //       if (!user || !isAuthenticated) {
                      //         onLoginClick(); 
                      //       } 
                      //     }}
                      //   ></i>
                      //   <span
                      //     className="text-white cart-text"
                      //     style={{
                      //       margin: '2px',
                      //       fontSize: '13px', // Adjust text size for smaller screens
                      //     }}
                      //     onClick={() => {
                      //       if (!user || !isAuthenticated) {
                      //         onLoginClick(); 
                      //       }
                      //     }}
                      //   >
                      //     My Cart
                      //   </span>
                      // </>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          if (!user || !isAuthenticated) {
                            onLoginClick(); // Trigger login if the user is not authenticated
                          }
                        }}
                      >
                        <i
                          className="fa fa-shopping-cart cart-icon"
                          style={{
                            marginRight: '7px', // Spacing between icon and text
                          }}
                        ></i>
                        <span
                          className="text-white cart-text"
                          style={{
                            margin: '2px',
                            fontSize: '13px', // Adjust text size for smaller screens
                          }}
                        >
                          My Cart
                        </span>
                      </div>


                    )}

                    {cartItems?.length > 0 && (
                      <span
                        id="cart_count"
                        className="text-white"
                        style={{
                          margin: '2px',
                          fontSize: '10px', // Adjust text size for smaller screens
                        }}
                      >
                        {cartItems?.length} items
                      </span>
                    )}


                    {cartItems?.length > 0 && (
                      <span
                        className="cart-amount  text-white"
                        style={{
                          margin: '2px',
                          fontSize: '10px', // Adjust text size for smaller screens
                        }}
                      >
                        ₹{cartItems.reduce((total, item) => total + item.price * item.productWeight, 0).toFixed(2)}
                      </span>
                    )}

                  </Link>
                </button>

              </>
            )}
          </Toolbar>
        </Container>
      </AppBar>

      <BottomNavigation
        showLabels
        value={bottomNavValue}
        onChange={(event, newValue) => setBottomNavValue(newValue)}
        className='navbar-link'
        sx={{
          display: { xs: 'flex', md: 'none' },
          position: 'fixed',
          bottom: 0,
          width: '100%',
          zIndex: 1300,
          backgroundColor: '#fff',
          color: 'black',
          border: 'none', // Remove border
          outline: 'none', // Remove outline
          boxShadow: 'none', // Remove any box shadow that may appear on click/focus
          '&:hover': {
            color: '#FED235'
          },
          '&:focus': {
            outline: 'none', // Remove the outline when the button is focused
          },
          '&:active': {
            // Ensure no background color on active (click)
            boxShadow: 'none', // Remove box shadow on active state
          },
          transition: 'none',
        }}
      >
        <BottomNavigationAction sx={{
          '&.Mui-selected': {
            color: '#FED235', // Set label color to yellow when active
          },
          border: 'none', // Remove border
          outline: 'none', // Remove outline
          boxShadow: 'none', // Remove any box shadow that may appear on click/focus
          '&:hover': {
            color: '#FED235'
          },
          '&:focus': {
            outline: 'none', // Remove the outline when the button is focused
          },
          '&:active': {
            // Ensure no background color on active (click)
            boxShadow: 'none', // Remove box shadow on active state
          },
          transition: 'none',
        }} label="Home" icon={<img
          src="/home.png"
          alt="Fresh"
          style={{ width: '24px', height: '24px' }} // Adjust the size as needed
        />} onClick={() => handleNavigation('/')} className='navbar-link' />

        <BottomNavigationAction sx={{
          '&.Mui-selected': {
            color: '#FED235', // Set label color to yellow when active
          },
          border: 'none', // Remove border
          outline: 'none', // Remove outline
          boxShadow: 'none', // Remove any box shadow that may appear on click/focus
          '&:hover': {
            color: '#FED235'
          },
          '&:focus': {
            outline: 'none', // Remove the outline when the button is focused
          },
          '&:active': {
            // Ensure no background color on active (click)
            boxShadow: 'none', // Remove box shadow on active state
          },
          transition: 'none',
        }} label="Fruits & Vegetables"
          onClick={() => handleNavigation('/fresh')} icon={
            <img
              src="/healthy-food.png"
              alt="Fresh"
              style={{ width: '24px', height: '24px' }} // Adjust the size as needed
            />} className='navbar-link' />

<BottomNavigationAction sx={{
          '&.Mui-selected': {
            color: '#FED235', // Set label color to yellow when active
          },
          border: 'none', // Remove border
          outline: 'none', // Remove outline
          boxShadow: 'none', // Remove any box shadow that may appear on click/focus
          '&:hover': {
            color: '#FED235'
          },
          '&:focus': {
            outline: 'none', // Remove the outline when the button is focused
          },
          '&:active': {
            // Ensure no background color on active (click)
            boxShadow: 'none', // Remove box shadow on active state
          },
          transition: 'none',
        }} label="Deal of the Day"
          onClick={() => handleNavigation('/deals')} icon={
            <img
              src="/deal.png"
              alt="Fresh"
              style={{ width: '24px', height: '24px' }} // Adjust the size as needed
            />} className='navbar-link' />


        {/* {user && (user.role === 'admin') && (
            <BottomNavigationAction sx={{
              '&.Mui-selected': {
                color: '#FED235', // Set label color to yellow when active
              },
              border: 'none', // Remove border
              outline: 'none', // Remove outline
              boxShadow: 'none', // Remove any box shadow that may appear on click/focus
              '&:hover': {
                color: '#FED235'
              },
              '&:focus': {
                outline: 'none', // Remove the outline when the button is focused
              },
              '&:active': {
                // Ensure no background color on active (click)
                boxShadow: 'none', // Remove box shadow on active state
              },
              transition: 'none',
            }} label="Monthly Groceries"
              icon={<img
                src="/shopping-basket.png"
                alt="Fresh"
                style={{ width: '24px', height: '24px' }} // Adjust the size as needed
              />}
              onClick={() => handleNavigation('/groceries')} className='navbar-link' />
          )} */}
        <BottomNavigationAction sx={{
          '&.Mui-selected': {
            color: '#FED235', // Set label color to yellow when active
          },
          border: 'none', // Remove border
          outline: 'none', // Remove outline
          boxShadow: 'none', // Remove any box shadow that may appear on click/focus
          '&:hover': {
            color: '#FED235'
          },
          '&:focus': {
            outline: 'none', // Remove the outline when the button is focused
          },
          '&:active': {
            // Ensure no background color on active (click)
            boxShadow: 'none', // Remove box shadow on active state
          },
          transition: 'none',
        }} label="Profile" icon={<img
          src="/user.png"
          alt="Fresh"
          style={{ width: '24px', height: '24px' }} // Adjust the size as needed
        />} onClick={HandelProfile} className='navbar-link' />
      </BottomNavigation>

    </header>
  );
}


export default Header;

