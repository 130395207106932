import React from 'react';
import MetaData from './MetaData';
import { useLocation } from 'react-router-dom';


const RefundPolicy = () => {
  const location = useLocation();
  return (

    <div>
      <MetaData
        title="Refund Policy"
        description="Read our refund policy to learn about the conditions and process for requesting refunds on your purchases. We strive to provide a hassle-free experience."
      />

      <div className="products_heading">Refund Policy</div>
      <div className="refund-policy-container">
  <h3>Refund Policy</h3>
  <p>Effective Date: [Under Trial]</p>

  <h5>Introduction</h5>
  <p>This Refund Policy outlines the conditions under which we accept returns and process refunds for fruits and vegetables purchased through our online platform.</p>

  <h5>Freshness Guarantee</h5>
  <p>We strive to deliver only the freshest fruits and vegetables. If you receive products that are damaged, spoiled, or not as described, you may be eligible for a refund.</p>

  <h5>Eligibility for Refunds</h5>
  <ul>
    <li>You must notify us within 24 hours of receiving your order.</li>
    <li>Provide photographic evidence of the damaged or incorrect items.</li>
  </ul>

  <h5>Non-Refundable Items</h5>
  <p>Due to the perishable nature of our products, we do not accept returns for items that are:</p>
  <ul>
    <li>No longer fresh or have passed their expiration date.</li>
    <li>Incorrectly ordered or unwanted items.</li>
  </ul>

  <h5>Refund Process</h5>
  <p>Once we receive your request and verify the claim, we will process your refund:</p>
  <ul>
    <li>Refunds will be issued to the original payment method.</li>
    <li>Please allow 5-7 business days for the refund to reflect in your account.</li>
  </ul>

  <h5>Order Cancellations</h5>
  <p>Order cancellations are only available in the event of natural disasters, or during specific national holidays such as Diwali, Kanum Pongal, or May 5. After placing an order, cancellations may not be possible outside of these exceptions.</p>

  <h5>Contact Information</h5>
  <p>To initiate a refund or cancellation, please contact our customer service team at jasfruitsandvegetables@gmail.com or call us at +91 91767 20068.</p>

  <h5>Changes to This Policy</h5>
  <p>We reserve the right to modify this refund policy at any time. Changes will be posted on our website, and your continued use constitutes acceptance of the new terms.</p>
</div>

    </div>
  );
};

export default RefundPolicy;
