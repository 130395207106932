import { createSlice } from "@reduxjs/toolkit";

const marginReportSlice = createSlice({
    name: "marginReport",
    initialState: {
        dateloading: false,
        dateerror:null,
        report: null,
        productloading: false,
        producterror: null,
        reportByDate: null,
        categoryloading: false,
        categoryerror: null,
        categoryReport:null,
        dateProductloading:false,
        dateProductReport : null,
        dateProducterror : null,
    
    },
    reducers: {
        marginReportRequest(state) {
            state.dateloading = true;
            state.dateerror = null;
        },
        marginReportSuccess(state, action) {
            state.dateloading = false;
            state.report = action.payload;
        },
        marginReportFail(state, action) {
            state.dateloading = false;
            state.dateerror = action.payload;
        },
        clearDateReport(state) {
            state.dateloading = false;
            state.report = null;
            state.dateerror = null;
        },
        getProductWiseRequest(state) {
            state.productloading = true;
            state.producterror = null;
        },
        getProductWiseSuccess(state, action) {
            state.productloading = false;
            state.reportByDate = action.payload;
        },
        getProductWiseFail(state, action) {
            state.productloading = false;
            state.producterror = action.payload;
        },
        clearProductReport(state) {
            state.productloading = false;
            state.reportByDate = null;
            state.producterror = null;
        },
        getCategoryWiseRequest(state) {
            state.categoryloading = true;
            state.categoryerror = null;
        },
        getCategoryWiseSuccess(state, action) {
            state.categoryloading = false;
            state.categoryReport = action.payload;
        },
        getCategoryWiseFail(state, action) {
            state.categoryloading = false;
            state.categoryerror = action.payload;
        },
        clearCategoryReport(state) {
            state.categoryloading = false;
            state.categoryReport = null;
            state.categoryerror = null;
        },
        getDateWiseRequest(state) {
            state.dateProductloading = true;
            state.dateProducterror = null;
        },
        getDateWiseSuccess(state, action) {
            state.dateProductloading = false;
            state.dateProductReport = action.payload;
        },
        getDateWiseFail(state, action) {
            state.dateProductloading = false;
            state.dateProducterror = action.payload;
        },
        clearDateProductReport(state) {
            state.dateProductloading = false;
            state.dateProductReport = null;
            state.dateProducterror = null;
        },
    },
});

export const {
    marginReportRequest,
    marginReportSuccess,
    marginReportFail,
    clearDateReport,
    getProductWiseRequest,
    getProductWiseSuccess,
    getProductWiseFail,
    getCategoryWiseRequest,
    getCategoryWiseSuccess,
    getCategoryWiseFail,
    clearCategoryReport,
    getDateWiseRequest,
    getDateWiseSuccess,
    getDateWiseFail,
    clearDateProductReport,
    clearProductReport,
} = marginReportSlice.actions;

export default marginReportSlice.reducer;