import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MetaData from '../Layouts/MetaData';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { deleteAddress, getUserAddresses, setDefaultAddress } from '../../actions/addressAction';
import { clearDefaultAddress, clearDeleteAddress } from '../../slices/AddressSlice';
import { Slide, toast } from 'react-toastify';
import Loader from '../Layouts/Loader';
import LoaderButton from '../Layouts/LoaderButton';
import Sidebar from "./Sidebar";
import { getUser, loadUser } from '../../actions/userActions';
import CryptoJS from 'crypto-js';
import { clearWalletTransactions } from '../../slices/transactionSlice';
import { getWalletTransactions } from '../../actions/transactionActions';

const UserWallets = ({ isActive, setIsActive }) => {
    // const { user, loading } = useSelector(state => state.authState);
    const { user, loading } = useSelector(state => state.userState);
    const { deleteSuccess, deleteloading, deleteerror, getdata, defaultloading, defaultSuccess, defaulterror } = useSelector(state => state.addressState);
    const [confirmDelete, setConfirmDelete] = useState(null); // track which address to delete
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const [walletBalance, setWalletBalance] = useState(0);
    const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;
    const currentDate = new Date(); // Get the current date
    const formattedCurrentDate = currentDate.toISOString().split('T')[0]; // Format current date

    // Calculate one month ago
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(currentDate.getMonth() - 1); // Subtract one month
    const formattedOneMonthAgo = oneMonthAgo.toISOString().split('T')[0]; // Format one month ago date

    // State variables
    const [inputStartDate, setInputStartDate] = useState('');
    const [inputEndDate, setInputEndDate] = useState('');
    const [startDate, setStartDate] = useState(formattedOneMonthAgo); // Initialize to one month ago
    const [endDate, setEndDate] = useState(formattedCurrentDate); // Initialize to today

    const { walletTransactions: transactions, walletLoading, walletError } = useSelector(state => state.transactionState);
    const [filters, setFilters] = useState({
        transactionStatus: "all",
        transactionType: "all",
        orderType: "all",
    });
    useEffect(() => {
        if (walletError) {
            toast.dismiss();
            setTimeout(() => {
                toast.error(walletError, {
                    position: 'bottom-center',
                    type: 'error',
                    autoClose: 700,
                    transition: Slide,
                    hideProgressBar: true,
                    className: 'small-toast',
                    onOpen: () => { dispatch(clearWalletTransactions()) }
                });
                // setweightvalue(false);
            }, 300);


        }

    }, [walletError])

    const decryptData = (walletBalance, encryptionKey) => {
        const bytes = CryptoJS.AES.decrypt(walletBalance, encryptionKey);
        return bytes.toString(CryptoJS.enc.Utf8);
    };

    // Simulated API data (replace with real API calls)
    useEffect(() => {
        if (user?.walletBalance) {
            const walletbalance = decryptData(user?.walletBalance, encryptionKey);
            // setWalletBalance(walletbalance);
            setWalletBalance(parseFloat(walletbalance));
        }
        if (!user?.walletBalance) {
            console.warn("User walletBalance is undefined or null");
            return;
        }

    }, [user]);

    // Filtered Transactions
    const filteredTransactions = transactions && transactions.filter((transaction) => {
        const { transactionStatus, transactionType, orderType } = filters;
        return (
            (transactionStatus === "all" || transaction.transactionStatus === transactionStatus) &&
            (transactionType === "all" || transaction.type.toLowerCase() === transactionType) &&
            (orderType === "all" || transaction.orderType === orderType)
        );
    }).sort((a, b) => new Date(b.date) - new Date(a.date));

    useEffect(() => {
        if (user?._id) {
            if (!transactions && !walletError) {
                dispatch(getWalletTransactions({ userId: user?._id, fromDate: startDate, toDate: endDate }));
            }
        }


    }, [transactions, startDate, endDate, walletError, user]);

    useEffect(() => {
        if (user?._id) {
            if (startDate || endDate) {
                dispatch(getWalletTransactions({ userId: user?._id, fromDate: startDate, toDate: endDate }));
            }

        }

    }, [startDate, endDate]);

    // useEffect(() => {
    //     dispatch(clearWalletTransactions());
    // }, [])


    // Helper to get the appropriate amount symbol and color class
    const getAmountClass = (transaction) => {
        if (transaction.transactionStatus === 'Pending') {
            return 'pending';
        } else if (transaction.transactionStatus === 'Success') {
            return transaction.type === 'Debit' ? 'debit-success' : 'credit-success';
        } else if (transaction.transactionStatus === 'Failed') {
            return 'failed';
        }
        return '';
    };

    // Helper to return the amount symbol
    const getAmountSymbol = (transaction) => {
        if (transaction.transactionStatus === 'Pending') {
            return ''; // No symbol for pending
        }
        return transaction.type === 'Debit' ? '-' : '+'; // Debit with - and Credit with +
    };

    // Helper to get the appropriate status color class
    const getStatusClass = (transaction) => {
        if (transaction.transactionStatus === 'Pending') {
            return 'status-pending'; // Orange for pending
        } else if (transaction.transactionStatus === 'Success') {
            return 'status-success'; // Green for success
        } else if (transaction.transactionStatus === 'Failed') {
            return 'status-failed'; // Red for failed
        }
        return '';
    };

    useEffect(() => {
        if (user && user._id !== id) {
            dispatch(getUser(id));
        }
    }, [dispatch, id]);

    useEffect(() => {
        if (!user) {
            dispatch(getUser(id));
        }
    }, [])

    return (
        <div className="row loader-parent">
            <div className="col-12 col-md-2">
                <div style={{ display: 'flex', flexDirection: 'row', position: 'fixed', top: '0px', zIndex: 99999, backgroundColor: '#fff', minWidth: '100%' }}>
                    <Sidebar isActive={isActive} setIsActive={setIsActive} />
                </div>
            </div>

            <div className='col-12 col-md-10 smalldevice-space loader-parent'>
                <MetaData title="Address" />

                {/* <h1 className="mb-4 admin-dashboard-x">Wallet Details</h1> */}
                <div className="">
                    <span className="back-button" onClick={() => navigate(-1)}>
                        <ArrowBackIcon fontSize="small" />
                        <span>Back</span>
                    </span>

                </div>
                {/* <div className="customer-details">
                    <h2>User: {user?.name ? user.name : 'N/A'}</h2>
                    <div className="customer-detail-item">
                        <strong>Name:</strong> {user?.name ? user.name : 'N/A'}
                    </div>
                    <div className="customer-detail-item">
                        <strong>Mobile/Email:</strong> {user?.email ? user.email : user?.mobile}
                    </div>
                    <div className="customer-detail-item">
                        <strong>Gender:</strong> {user?.gender || 'N/A'}
                    </div>
                </div>
                <hr /> */}

                {/* <h4 style={{marginTop:'20px'}}>WalletDetails : {user?.name ? user.name : user._id}</h4> */}
                <h4 style={{ marginTop: '20px' }}>
                    WalletDetails :
                    <Link to={`/admin/addressList/${user?._id}`} style={{ textDecoration: 'underLine', color: 'blue', marginLeft: '5px' }}>
                        {user?.name ? user.name : user?._id}
                    </Link>
                </h4>

                <div className="wallet-container">
                    {/* Wallet Balance */}
                    <div className="wallet-header" style={{ display: 'flex', flexDirection: 'row' }}>
                        <div>
                            <h2>Wallet Balance : </h2>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '3px' }}>
                            <div>
                                <p style={{ color: 'green' }}>₹ {walletBalance ? walletBalance.toFixed(2): 0}</p>
                            </div>
                        </div>

                    </div>
                    <div className="paymentlist-date-filter">
                        <div className='start-date'>
                            <label htmlFor="startDate">From:</label>
                            <input
                                type="date"
                                value={inputStartDate === '' ? startDate : inputStartDate}
                                onChange={(e) => { setStartDate(e.target.value); setInputStartDate(e.target.value) }}
                                className="paymentlist-form-control"
                            />
                        </div>
                        <div className="end-date">
                            <label htmlFor="endDate">To:</label>
                            <input
                                type="date"
                                value={inputEndDate === '' ? endDate : inputEndDate}
                                onChange={(e) => { setEndDate(e.target.value); setInputEndDate(e.target.value) }}
                                className="paymentlist-form-control"
                            />
                        </div>
                    </div>

                    {/* Filters */}
                    <div className="filter-section">
                        <h3>Filters</h3>
                        <div className="filter-group">
                            {/* Filter by Transaction Status */}
                            <label>
                                Transaction Status:
                                <select
                                    value={filters.transactionStatus}
                                    onChange={(e) =>
                                        setFilters({ ...filters, transactionStatus: e.target.value })
                                    }
                                >
                                    <option value="all">All</option>
                                    <option value="Pending">Pending</option>
                                    <option value="Success">Success</option>
                                    <option value="Failed">Failed</option>
                                </select>
                            </label>

                            {/* Filter by Transaction Type */}
                            <label>
                                Transaction Type:
                                <select
                                    value={filters.transactionType}
                                    onChange={(e) =>
                                        setFilters({ ...filters, transactionType: e.target.value })
                                    }
                                >
                                    <option value="all">All</option>
                                    <option value="credit">Credit</option>
                                    <option value="debit">Debit</option>
                                </select>
                            </label>

                            {/* Filter by Order Type */}
                            <label>
                                Order Type:
                                <select
                                    value={filters.orderType}
                                    onChange={(e) =>
                                        setFilters({ ...filters, orderType: e.target.value })
                                    }
                                >
                                    <option value="all">All</option>
                                    <option value="order">Order</option>
                                    <option value="refund">Refund</option>
                                    {/* <option value="topup">Top-Up</option> */}
                                </select>
                            </label>
                        </div>
                    </div>

                    {/* Transaction History */}
                    <div className="transactions-section">
                        <h3>Transaction History</h3>
                        {filteredTransactions?.length > 0 ? (
                            <ul>
                                {filteredTransactions.map((transaction) => (
                                    <li key={transaction.id}>
                                        <div className="transaction-info">
                                            <span>{transaction.type}</span>
                                            {/* Amount with conditional styling */}
                                            <span className={`amount ${getAmountClass(transaction)}`}>
                                                {getAmountSymbol(transaction)}
                                                ₹{Math.abs(transaction.amount).toFixed(2)}
                                            </span>
                                        </div>
                                        <div className="transaction-meta">
                                            {/* Status with dynamic color */}
                                            {/* <span>OrderID: {transaction.order_id}</span> */}
                                            <span>OrderID:
                                                <Link to={`/admin/orderdetail/${transaction.order_id}`} style={{ textDecoration: 'underLine', color: 'blue',marginLeft:'3px' }}>
                                                     {transaction.order_id}
                                                </Link>
                                            </span>
                                            <span>{transaction.orderType}</span>
                                            <span className={`transaction-status ${getStatusClass(transaction)}`}>
                                                {transaction.transactionStatus}
                                            </span>
                                            {/* <span>{transaction.method}</span> */}

                                            <span>{new Date(transaction.date).toLocaleDateString()}</span>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p className="no-transactions">No transactions found</p>
                        )}
                    </div>


                </div>

            </div>
        </div>
    );
};

const modalStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    zIndex: 1000
};

export default UserWallets;